import React from 'react';
import Lottie from 'lottie-react-web';

const Loading: React.FC = React.memo(
    (): React.ReactElement => (
        <Lottie
            width="75px"
            height="75px"
            options={{
                animationData: require('./Loading.json'),
                loop: true,
            }}
        />
    )
);

export default Loading;
