import axios from 'axios';

export default {
    fetchIndustryStats() {
        return axios.get('/data/industry_stats.json').then(({ data }) => data);
    },
    fetchIndustryTop10() {
        return axios.get('/data/top10_transactions_by_industry.json').then(({ data }) => data);
    },
    fetchStatesStats() {
        return axios.get('/data/state_stats.json').then(({ data }) => data);
    },
    fetchStateSpendings() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/states2`).then(({ data }) => data);
    },
};
