import React, { useEffect } from 'react';
import ReactMapGL, { FlyToInterpolator, Layer, Popup } from 'react-map-gl';
import ZoomMap from '../MapContainer/MapZoom';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../reducers';
import { clearGeoData, setGeoData } from '../../reducers/GeoData';
import { MapboxGeoJSONFeature } from 'mapbox-gl';
import InfoPopupSmall from '../InfoPopup/InfoPopupSmall';
import {
    getGeoResultForHover,
    getMapHoverLayers,
    getMapLayers,
    getStateOutlineLayer,
} from '../../utils/map';

interface Props {
    mappedData: { [key: string]: any[] };
    onMapTypeChange: (newMapType: string) => void;
    onGeoDataChange?: () => void;
    onMapLayerChange: (layer: any) => void;
}

const defaultViewPort: any = {
    width: '100%',
    height: '100%',
    latitude: 41.5868,
    longitude: -93.625,
    zoom: 3,
    minZoom: 2,
    center: [-106.6504, 35.0844],
};

const bounds = {
    maxLat: 65.765498,
    minLat: 19.487583,
    maxLng: -60.868054,
    minLng: -159.635246,
};

let id: number | null = null;
let source: string | null = null;
let selectedId: number | null = null;
let selectedSource: string | null = null;

const ReactMapboxGL = React.forwardRef(
    ({ mappedData, onMapTypeChange, onMapLayerChange, onGeoDataChange }: Props, ref: any) => {
        const [viewport, setViewport] = React.useState<any>(defaultViewPort);
        const [layers, setLayers] = React.useState<any[]>([]);
        const [hoverLayers, setHoverLayers] = React.useState<any[]>([]);
        const [stateOutlineLayer, setStateOutlineLayer] = React.useState<any[]>([]);
        const [popupData, setPopupData] = React.useState<any>();
        const { statesData }: any = useSelector(({ mapData }: Reducers) => mapData);
        const { selectionData }: any = useSelector((state: Reducers) => state.geoData);
        const geoData = useSelector((state: Reducers) => state.geoData);
        const { currentMapType, currentLayer }: any = useSelector(
            ({ mapData }: Reducers) => mapData
        );
        const { mapRef, viewportRef } = ref;
        const dispatch = useDispatch();
        useEffect(() => {
            addMapLayers(currentLayer);
            addMapHoverLayers(currentLayer);
            addStateOutlineLayer();
            viewportRef.current = { closePopup, setPopupData };
        }, []);
        useEffect(() => {
            if (selectionData) {
                const name = geoData.zip
                    ? geoData.zip
                    : geoData.county[1]
                        ? geoData.county[1]
                        : geoData.state[1];
                let intervalLoops = 0;
                const interval = setInterval(() => {
                    intervalLoops += 1;
                    if (intervalLoops > 50) {
                        clearInterval(interval);
                    }
                    if (mapRef.current) {
                        const map = mapRef.current.getMap();
                        if (map) {
                            const getFeature = () => {
                                const features = map
                                    .queryRenderedFeatures()
                                    .filter(
                                        (feature: MapboxGeoJSONFeature) =>
                                            feature.sourceLayer === 'tile'
                                    );
                                return features.find((el: any) => {
                                    let featureName = el.properties.name;
                                    const state = el.properties.state_code;
                                    if (geoData.zip) {
                                        featureName = el.properties.zip;
                                    }
                                    return (
                                        featureName === name &&
                                        state === geoData.state[0] &&
                                        el.layer.id.includes('hover')
                                    );
                                });
                            };
                            const hoverState = (feature: any) => {
                                if (selectedId) {
                                    map.setFeatureState(
                                        {
                                            source: selectedSource,
                                            sourceLayer: 'tile',
                                            id: selectedId,
                                        },
                                        { hover: false }
                                    );
                                }
                                selectedId = feature.id;
                                map.setFeatureState(
                                    {
                                        source: feature.layer.id,
                                        sourceLayer: 'tile',
                                        id: selectedId,
                                    },
                                    { hover: true }
                                );
                                selectedSource = feature.layer.id;
                            };
                            clearInterval(interval);
                            let featureIntervalLoops = 0;
                            const featureInterval = setInterval(() => {
                                featureIntervalLoops += 1;
                                if (featureIntervalLoops > 50) {
                                    clearInterval(featureInterval);
                                }
                                const feature = getFeature();
                                if (feature) {
                                    hoverState(feature);
                                    setPopupData({
                                        name,
                                        geoResult: {
                                            coordinates: geoData.coordinates,
                                        },
                                        ...feature.properties,
                                    });
                                    clearInterval(featureInterval);
                                }
                            }, 100);
                        }
                    }
                }, 100);
            }
        }, [selectionData]);

        useEffect(() => {
            viewportRef.current.viewportUpdateFromParent = (
                zoom: number,
                center: { lat: number; lng: number }
            ) => {
                const newViewport = JSON.parse(JSON.stringify(viewport));
                if (!popupData) {
                    newViewport.zoom = zoom;
                }
                newViewport.transitionDuration = 500;
                newViewport.transitionInterpolator = new FlyToInterpolator();
                newViewport.latitude = center.lat;
                newViewport.longitude = center.lng;
                setViewport(newViewport);
            };
        }, [viewport, popupData]);

        const addMapLayers = React.useCallback(
            (newLayerIndex) => {
                setLayers(getMapLayers(newLayerIndex, mappedData, currentMapType));
            },
            [currentMapType]
        );

        const addMapHoverLayers = React.useCallback(
            (newLayerIndex) => {
                setHoverLayers(getMapHoverLayers(newLayerIndex, mappedData, currentMapType));
            },
            [currentMapType]
        );

        const addStateOutlineLayer = React.useCallback(() => {
            setStateOutlineLayer(getStateOutlineLayer(currentMapType));
        }, [currentMapType]);

        const mapZoomIn = () => {
            const newViewport = JSON.parse(JSON.stringify(viewport));
            if (viewport.zoom < 15) {
                newViewport.zoom = viewport.zoom + 1;
                newViewport.transitionDuration = 200;
                newViewport.transitionInterpolator = new FlyToInterpolator();
                setViewport(newViewport);
            }
        };

        const mapZoomOut = () => {
            const newViewport = JSON.parse(JSON.stringify(viewport));
            if (viewport.zoom > 2) {
                newViewport.zoom = viewport.zoom - 1;
                newViewport.transitionDuration = 200;
                newViewport.transitionInterpolator = new FlyToInterpolator();
                setViewport(newViewport);
            }
        };

        const refresh = () => {
            const newViewport = JSON.parse(JSON.stringify(defaultViewPort));
            newViewport.transitionDuration = 200;
            newViewport.transitionInterpolator = new FlyToInterpolator();
            setViewport(newViewport);
            setPopupData(null);
            dispatch(clearGeoData());
            onMapTypeChange('states');
            onMapLayerChange({ index: 0 });
            const map = mapRef.current.getMap();
            if (map && selectedSource) {
                map.setFeatureState(
                    { source: selectedSource, sourceLayer: 'tile', id: selectedId },
                    { hover: false }
                );
            }
        };

        const closePopup = () => {
            const map = mapRef.current.getMap();
            if (map) {
                setPopupData(null);
                if (source && source !== selectedSource) {
                    map.setFeatureState({ source, sourceLayer: 'tile', id: id }, { hover: false });
                }
            }
        };

        const onHover = React.useCallback(
            (event: any) => {
                const map = mapRef.current.getMap();
                if (event.target.nodeName !== 'BUTTON') {
                    const features = map
                        .queryRenderedFeatures(event.point)
                        .filter((feature: MapboxGeoJSONFeature) => feature.sourceLayer === 'tile');
                    if (features.length) {
                        const geoResult: any = getGeoResultForHover(
                            features,
                            popupData,
                            statesData
                        );
                        if (geoResult?.state) {
                            setPopupData({
                                ...features[0].properties,
                                geoResult: geoResult,
                                name: geoResult.zip
                                    ? geoResult.zip
                                    : geoResult.county
                                        ? `${geoResult.longNames.county}, ${geoResult.state}`
                                        : geoResult.longNames.state,
                            });
                            if (id !== selectedId) {
                                map.setFeatureState(
                                    { source: features[0].layer.id, sourceLayer: 'tile', id: id },
                                    { hover: false }
                                );
                            }

                            id = features[0].id;
                            map.setFeatureState(
                                { source: features[0].layer.id, sourceLayer: 'tile', id: id },
                                { hover: true }
                            );
                            source = features[0].layer.id;
                        }
                    } else {
                        setPopupData(null);
                        if (source && id !== selectedId) {
                            map.setFeatureState(
                                { source, sourceLayer: 'tile', id: id },
                                { hover: false }
                            );
                        }
                    }
                }
            },
            [currentMapType, popupData]
        );

        const onClick = (event: any) => {
            if (event.target.nodeName !== 'BUTTON' && event.target.nodeName !== 'SPAN') {
                const map = mapRef.current.getMap();
                const features = map
                    .queryRenderedFeatures(event.point)
                    .filter((feature: MapboxGeoJSONFeature) => feature.sourceLayer === 'tile');
                if (popupData) dispatch(setGeoData(popupData.geoResult));
                if (map && features.length) {
                    map.setFeatureState(
                        { source: selectedSource, sourceLayer: 'tile', id: selectedId },
                        { hover: false }
                    );

                    selectedId = features[0].id;
                    map.setFeatureState(
                        { source: features[0].layer.id, sourceLayer: 'tile', id: selectedId },
                        { hover: true }
                    );
                    selectedSource = features[0].layer.id;
                }
            }
        };

        return (
            <ReactMapGL
                onLoad={() => {
                    if (onGeoDataChange) {
                        onGeoDataChange();
                    }
                }}
                onHover={onHover}
                onClick={onClick}
                ref={mapRef}
                mapStyle="mapbox://styles/pogoaccounts/ckhqpc6s203zl19nzdw6g09k3"
                mapboxApiAccessToken="pk.eyJ1IjoicG9nb2FjY291bnRzIiwiYSI6ImNraHFnOGprNTA2Ymgyem5kMTNobXo0eGMifQ.s6uzbIc3BRUz6VXlZmv82w"
                onViewportChange={(viewport: any) => {
                    if (viewport.longitude > 100) {
                        viewport.longitude = bounds.minLng;
                    } else if (viewport.longitude > bounds.maxLng) {
                        viewport.longitude = bounds.maxLng;
                    } else if (viewport.longitude < bounds.minLng) {
                        viewport.longitude = bounds.minLng;
                    }
                    if (viewport.latitude > bounds.maxLat) {
                        viewport.latitude = bounds.maxLat;
                    }
                    if (viewport.latitude < bounds.minLat) {
                        viewport.latitude = bounds.minLat;
                    }
                    setViewport(viewport);
                }}
                {...viewport}
            >
                {layers.length && layers.map((layer, index) => <Layer key={index} {...layer} />)}
                {hoverLayers.length &&
                hoverLayers.map((layer, index) => <Layer key={index} {...layer} />)}
                {stateOutlineLayer.length &&
                stateOutlineLayer.map((layer, index) => <Layer key={index} {...layer} />)}
                {popupData && (
                    <Popup
                        captureClick={true}
                        latitude={popupData.geoResult.coordinates.lat}
                        longitude={popupData.geoResult.coordinates.lng}
                    >
                        <InfoPopupSmall
                            closePopup={() => setPopupData(null)}
                            data={popupData}
                            currentLayer={currentLayer}
                        />
                    </Popup>
                )}
                <ZoomMap refresh={refresh} mapZoomIn={mapZoomIn} mapZoomOut={mapZoomOut} />
            </ReactMapGL>
        );
    }
);
export default ReactMapboxGL;
