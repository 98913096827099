import './LandingPage.scss';
import { HttpResponse, State, PC } from '../../types';
import React, { useRef } from 'react';
import { Overlay } from 'react-bootstrap';
import mapAPI from '../../api/map';
import InfoPopup from '../../components/InfoPopup';
import SidebarList from '../../components/SidebarList';
import MapLegendColor from '../../components/MapLegendColor';
import { OverlayInjectedProps } from 'react-bootstrap/esm/Overlay';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';
import { InfoBubblesWrapper, SpendingBubble, SubscribeBubble } from '../../components/InfoBubbles';
import {
    addStateColorBasedOnSpending,
    createTooltipWithCorrectPosition,
    getBubbleDataAndCircleType,
    removeElementsByClassName,
} from './landingPageService';
import SpendingsHeader from '../../components/Headers/SpendingsHeader';
import MapSearch from '../../components/MapContainer/MapSearch';
import { useHistory } from 'react-router-dom';
import Footer from '../../components/Footer';

const omit = require('lodash/omit');
const USAMap = require('react-usa-map').default;

const LandingPage: PC = React.memo(
    (): React.ReactElement => {
        const history = useHistory();

        const onSearchResultSelect = () => {
            history.push('/map-view');
        };

        const [target, setTarget] = React.useState<any>();
        const [tooltip, setTooltip] = React.useState<State | null>(null);
        const [activeTab, setActiveTab] = React.useState<string>('total_spending');
        const [filterType, setFilterType] = React.useState<string | null>(null);
        const [statesData, setStatesData] = React.useState<HttpResponse>();
        const [zoom, setZoom] = React.useState<number>(1);
        const myStateRef = React.useRef({
            filterType: null as string | null,
            statesData: undefined as HttpResponse | undefined,
            activeTab: 'total_spending',
            zoom: 1,
        });
        const spendingBubbleRef: any = React.useRef({});
        let zoomTimeout: any;
        let timer: any;

        const _setFilterType = (filterType: string | null) => {
            myStateRef.current.filterType = filterType;
            setFilterType(filterType);
        };

        const _setStatesData = (statesData: HttpResponse) => {
            myStateRef.current.statesData = statesData;
            setStatesData(statesData);
        };

        const _setActiveTab = (activeTab: string) => {
            myStateRef.current.activeTab = activeTab;
            setActiveTab(activeTab);
        };

        const _setZoom = (zoom: number) => {
            myStateRef.current.zoom = zoom;
            setZoom(zoom);
        };

        const removeTargetStroke = () => {
            const outlines: any = document.getElementsByClassName('outlines');
            if (outlines && outlines[0]) {
                Object.keys(outlines[0].children).forEach((key: any) => {
                    let path: any = outlines[0].children[key];
                    if (path.nodeName === 'g' && path.children[0]) {
                        path = path.children[0];
                        path.style.stroke = 'black';
                        path.style.strokeWidth = '1px';
                    } else {
                        path.style.stroke = 'black';
                        path.style.strokeWidth = '0.3px';
                    }
                });
            }
        };

        const pageClickHandler = () => {
            setTooltip(null);
            removeTargetStroke();
        };

        const onStateHover = (event: any) => {
            //Remove previous target stroke
            removeTargetStroke();

            if (event.path && event.path[0].nodeName === 'g') {
                const path = event.path[0].children[0];
                path.style.stroke = 'white';
                path.style.strokeWidth = '2px';
            } else {
                event.target.style.stroke = 'white';
                event.target.style.strokeWidth = '2px';
            }
        };

        const onStateClick = (event: any) => {
            event.stopPropagation();
            setTarget(event.target);
            if (event.target.dataset.name) {
                setTooltip(statesData?.result[event.target.dataset.name]);
            } else {
                setTooltip(statesData?.result['DC']);
            }
        };

        const onDescriptionTooltipClick = (event: any) => {
            event.stopPropagation();
        };

        React.useEffect(() => {
            window.addEventListener('click', pageClickHandler);
            window.addEventListener('resize', debounce.bind(null, 200), false);
            mapAPI.fetchStates().then((data: HttpResponse) => {
                _setStatesData(data);
            });
            setTimeout(() => {
                const statesMap: any = document.getElementsByClassName('us-state-map');
                if (statesMap && statesMap[0]) {
                    statesMap[0].childNodes[0].innerHTML = '';
                }
            }, 1000);
            return () => {
                const outlines: any = document.getElementsByClassName('outlines');
                if (outlines && outlines.length) {
                    Object.keys(outlines[0].children).forEach((key: any) => {
                        const path: any = outlines[0].children[key];
                        path.removeEventListener('mouseenter', onStateHover);
                        path.removeEventListener('click', onStateClick);
                    });
                    window.removeEventListener('click', pageClickHandler);
                }
            };
        }, []);

        React.useEffect(() => {
            if (statesData) {
                updateMap();
            }
        }, [filterType, activeTab, zoom]);

        React.useEffect(() => {
            if (statesData) {
                updateMap({ animation: 'fade-in' });
            }
        }, [zoom]);

        React.useEffect(() => {
            if (statesData) {
                updateMap();
                const outlines: any = document.getElementsByClassName('outlines');
                Object.keys(outlines[0].children).forEach((key: any) => {
                    const path: any = outlines[0].children[key];
                    path.addEventListener('mouseenter', onStateHover);
                    path.addEventListener('click', onStateClick);
                });
            }
        }, [statesData]);

        const debounce = (time: number) => {
            time = time || 100; // 100 by default if no param
            if (timer) clearTimeout(timer);
            timer = setTimeout(updateMap, time);
        };

        const updateMap = (options?: any) => {
            const _filterType: string | null = myStateRef.current.filterType;
            const _statesData: HttpResponse | undefined = myStateRef.current.statesData;
            const _activeTab: string = myStateRef.current.activeTab;
            const _zoom: number = myStateRef.current.zoom;
            removeElementsByClassName(['custom-tooltip', 'DC1', 'DC2']);
            const outlines: any = document.getElementsByClassName('outlines');
            const mapContainer: any = document.getElementsByClassName('usa-container')[0];
            const mainContainer: any = document.getElementsByClassName('react-transform-element')[0];
            let originalSVGHeight: any = document
                .getElementsByClassName('us-state-map')[0]
                ?.getAttribute('height');
            if (!originalSVGHeight) {
                originalSVGHeight = 0;
            }
            if (outlines && outlines.length) {
                Object.keys(outlines[0].children).forEach((key: any, index: number) => {
                    const path: any = outlines[0].children[key];
                    let stateCode = path.dataset.name;
                    if (!stateCode) {
                        stateCode = path.classList[0];
                    }
                    const pathData: State = _statesData?.result[stateCode];
                    //Add state color
                    addStateColorBasedOnSpending(pathData, path, _activeTab);
                    if (_filterType && pathData[_filterType as keyof State]) {
                        const { bubbleData, circle } = getBubbleDataAndCircleType(
                            pathData,
                            _filterType
                        );
                        let scale = _zoom > 1 ? (1 / _zoom) * 1.3 : 1;
                        const statesCloseToEachOther = [7, 8, 18, 19, 38, 50];
                        if (statesCloseToEachOther.includes(index) && scale < 0.3) {
                            scale = 0.3;
                        } else if (scale < 0.5) {
                            scale = 0.5;
                        }
                        createTooltipWithCorrectPosition(
                            path,
                            mapContainer,
                            mainContainer,
                            originalSVGHeight,
                            index,
                            circle,
                            scale,
                            bubbleData,
                            options
                        );
                    }
                });
            }
        };

        const getPopupStyle = React.useCallback(() => {
            const stateName = target.dataset.name;
            let marginBottom = -target.getBoundingClientRect().height;
            let style;

            if (stateName === 'DC') {
                marginBottom *= -1;
                style = { marginBottom, marginLeft: 2 };
            } else if (stateName === 'MD') {
                marginBottom = 0;
                style = { marginBottom };
            } else if (stateName === 'FL') {
                marginBottom /= 2;
                style = {
                    marginBottom,
                    marginLeft: target.getBoundingClientRect().height * 0.4,
                };
            } else if (stateName === 'HI') {
                marginBottom /= 2;
                style = {
                    marginBottom,
                    marginLeft: target.getBoundingClientRect().height * 0.55,
                };
            } else {
                marginBottom /= 2;
                style = { marginBottom };
            }

            return style;
        }, [target]);

        const onZoomChange = (newZoom: any) => {
            if (zoomTimeout) {
                clearTimeout(zoomTimeout);
            }
            zoomTimeout = setTimeout(() => _setZoom(newZoom.scale), 500);
        };

        const refresh = (resetTransform: Function) => {
            _setActiveTab('total_spending');
            _setFilterType(null);
            onZoomChange(1);
            setTimeout(() => resetTransform(), 200);
            spendingBubbleRef.current.handleCheckElement();
        };

        return (
            <>
                <div className="bg-circles bg-overwflow">
                    <div className="scroll-content-lp">
                        <div className="scroll-content-inner">
                            <div className="container">
                                <div className="row">
                                    <div className="col-8 offset-2">
                                        <h1 className="heading-title">
                                            Find out where COVID-19 funds were spent.
                                        </h1>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-md-2">
                                        <SidebarList
                                            activeTab={activeTab}
                                            setActiveTab={_setActiveTab}
                                            stateData={tooltip}
                                        />
                                    </div>

                                    <div className="col-md-8">
                                        <div className="search-wrapper">
                                            <MapSearch selectionCallback={onSearchResultSelect} />
                                            <div className="ribbon">
                                                For additional filters, go to table view
                                            </div>
                                        </div>
                                        <TransformWrapper onZoomChange={onZoomChange}>
                                            {({ zoomIn, zoomOut, resetTransform, ...rest }: any) => (
                                                <div style={{position: 'relative'}}>
                                                    <div className="control">
                                                        <button
                                                            onClick={() => {
                                                                refresh(resetTransform);
                                                            }}
                                                            className="btn-refresh"
                                                        >
                                                            <span className="icon iconRefresh"></span>
                                                        </button>
                                                        <div className="zoom">
                                                            <button
                                                                onClick={zoomIn}
                                                                className="zoom-in"
                                                            ></button>
                                                            <button
                                                                onClick={zoomOut}
                                                                className="zoom-out"
                                                            ></button>
                                                        </div>
                                                    </div>
                                                    <TransformComponent>
                                                        <div className="usa-container">
                                                            <USAMap defaultFill="#05c3f0" />
                                                        </div>
                                                    </TransformComponent>
                                                </div>
                                            )}
                                        </TransformWrapper>

                                        <Overlay
                                            placement="top"
                                            target={target}
                                            show={tooltip !== null}
                                        >
                                            {(props: OverlayInjectedProps) => (
                                                <div
                                                    onClick={onDescriptionTooltipClick}
                                                    {...omit(props, [
                                                        'placement',
                                                        'arrowProps',
                                                        'show',
                                                        'popper',
                                                    ])}
                                                    style={{
                                                        ...props.style,
                                                        zIndex: 2,
                                                    }}
                                                >
                                                    {tooltip && (
                                                        <InfoPopup
                                                            style={getPopupStyle()}
                                                            data={tooltip}
                                                            closePopup={pageClickHandler}
                                                            activeTab={activeTab}
                                                            filterType={filterType}
                                                        />
                                                    )}
                                                    {/*{target.dataset.name}*/}
                                                </div>
                                            )}
                                        </Overlay>

                                        <MapLegendColor activeTab={activeTab} />
                                    </div>
                                </div>
                            </div>
                            <InfoBubblesWrapper>
                                <SpendingBubble
                                    setFilterType={_setFilterType}
                                    ref={spendingBubbleRef}
                                />
                                <SubscribeBubble />
                            </InfoBubblesWrapper>
                        </div>
                    </div>
                    <Footer />
                </div>
            </>
        );
    }
);

LandingPage.header = SpendingsHeader;

export default LandingPage;
