import React from 'react';
import Select from 'react-select';
import { OptionType } from '../../../types';
import { Reducers } from '../../../reducers';
import { SearchType } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownIndicator } from '../../DropdownConfigs';
import styles from './SelectMoreFiltersStyles.module.scss';
import selectStyles from '../../../constants/selectStyles';
import useClickOutside from '../../../hooks/useClickOutside';
import { SpendingsFilters } from '../../../reducers/Spendings';
import { getSelectOption, numbersOnlyMask } from '../../../utils';
import { setFilters as saveFilters } from '../../../reducers/Spendings';
import { fetchAgencies, fetchPrograms, fetchIndustries } from '../../../reducers/Filters';
import { default as NumberFormat } from 'react-number-format';

interface FiltersType {
    agency: OptionType;
    program: OptionType;
    accIssues: OptionType;
    gjf_nsv: OptionType;
    gjf_ns: OptionType;
}

const AccountabilityIssues = [
    {
        label: 'Exact Only',
        value: 'Exact'
    },
    {
        label: 'Exact and Potential',
        value: 'Potential'
    }
]

const SelectMoreFiltersStyles: React.FC = React.memo(
    (): React.ReactElement => {
        const dispatch = useDispatch();
        const ref = React.useRef<HTMLDivElement>(null as any);
        const [isOpened, setOpened] = useClickOutside(ref);
        const [filters, setFilters] = React.useState<FiltersType | any>({} as FiltersType);
        const { agencies, programs } = useSelector(({ filters }: Reducers) => filters);
        const { searchType, agency, program, accIssues, spending } = useSelector(
            ({ spendings }: Reducers) => spendings.filters
        );

        const setAccIssueType = (accIssues: any) => {
            let gjf_ns = { label: 'gjf_ns', value: '' };
            let gjf_nsv = { label: 'gjf_nsv', value: '' };

            if (accIssues.label === 'Exact Only') {
                gjf_nsv.value = 'True';
                gjf_ns.value = ''
            } else if (accIssues.label === 'Exact and Potential') {
                gjf_ns.value = 'True';
                gjf_nsv.value = ''
            }

            setFilters({ ...filters, accIssues, gjf_ns, gjf_nsv })
        }

        React.useEffect(() => {
            dispatch(fetchAgencies({ type: searchType.toLowerCase() }));
            dispatch(fetchPrograms({}));
            dispatch(fetchIndustries({}));
        }, [dispatch]);

        React.useEffect(() => {
            dispatch(fetchAgencies({ type: searchType.toLowerCase() }));
        }, [searchType]);

        React.useEffect(() => {
            if (!isOpened) {
                dispatch(
                    saveFilters({
                        agency: filters.agency ? filters.agency.value : '',
                        program: filters.program ? filters.program.value : '',
                        accIssues: filters.accIssues ? filters.accIssues.value : '',
                        gjf_nsv: filters.gjf_nsv?.value || '',
                        gjf_ns: filters.gjf_ns?.value || '',
                    } as SpendingsFilters)
                );
            }
        }, [filters, isOpened, dispatch, saveFilters]);

        React.useEffect(() => {
            if (agencies.length && programs.length) {
                if(!agency && !program && !accIssues){
                    setFilters({} as FiltersType)
                } else {
                    setFilters({
                        ...filters,
                        agency: getSelectOption(agencies, agency),
                        program: getSelectOption(programs, program),
                        accIssues: getSelectOption(AccountabilityIssues, accIssues),
                    });
                }
            }
        }, [agency, program, accIssues, agencies, programs]);

        return (
            <>
                <div className={styles.moreFilters} ref={ref}>
                    <button
                        onClick={() => setOpened(!isOpened)}
                        className={styles.moreFiltersButton}
                    >
                        <span className="icon iconFilter"></span>
                        MORE FILTERS
                    </button>

                    {isOpened && (
                        <div className={styles.moreFiltersDropdown}>
                            <div className={styles.moreFiltersDropdownItem}>
                                <label>AWARDING AGENCY</label>

                                <Select
                                    options={agencies}
                                    value={filters.agency}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    placeholder="Awarding Agency"
                                    styles={selectStyles.simpleSearchStyles}
                                    onChange={(agency: any) => setFilters({ ...filters, agency })}
                                />
                            </div>

                            {searchType === SearchType.Assistance && (
                                <div className={styles.moreFiltersDropdownItem}>
                                    <label>PROGRAMS</label>

                                    <Select
                                        options={programs}
                                        isSearchable={true}
                                        placeholder="Programs"
                                        value={filters.program}
                                        className="basic-single"
                                        classNamePrefix="select"
                                        components={{ DropdownIndicator }}
                                        styles={selectStyles.simpleSearchStyles}
                                        onChange={(program: any) =>
                                            setFilters({ ...filters, program })
                                        }
                                    />
                                </div>
                            )}

                            <div className={styles.moreFiltersDropdownItem}>
                                <label>Accountability Flags</label>

                                <Select
                                    options={AccountabilityIssues}
                                    isSearchable={false}
                                    placeholder="Accountability Flags"
                                    value={filters.accIssues}
                                    className="basic-single"
                                    classNamePrefix="select"
                                    components={{ DropdownIndicator }}
                                    styles={selectStyles.simpleStyles}
                                    onChange={setAccIssueType}
                                />
                            </div>

                            <div className={styles.moreFiltersDropdownItem}>
                                <label>TOTAL COVID SPENDING</label>

                                <div className="row">
                                    <div className="col-6" style={{ background: 'transparent' }}>
                                        <NumberFormat
                                            value={spending[0]}
                                            placeholder="From"
                                            prefix={'$'}
                                            thousandSeparator={true}
                                            className={styles.inputNumber}
                                            onKeyPress={numbersOnlyMask}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                dispatch(
                                                    saveFilters({
                                                        spending: [e.target.value, spending[1]],
                                                    } as SpendingsFilters)
                                                )
                                            }
                                        />
                                        {/*
                                        <input
                                            type="text"
                                            placeholder="From"
                                            value={spending[0]}
                                            className={styles.input}
                                            onKeyPress={numbersOnlyMask}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                dispatch(
                                                    saveFilters({
                                                        spending: [e.target.value, spending[1]],
                                                    } as SpendingsFilters)
                                                )
                                            }
                                        />
                                        */}
                                    </div>

                                    <div className="col-6" style={{ background: 'transparent' }}>
                                        <NumberFormat
                                            placeholder="To"
                                            prefix={'$'}
                                            value={spending[1]}
                                            thousandSeparator={true}
                                            className={styles.inputNumber}
                                            onKeyPress={numbersOnlyMask}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                dispatch(
                                                    saveFilters({
                                                        spending: [spending[0], e.target.value],
                                                    } as SpendingsFilters)
                                                )
                                            }
                                        />
                                        {/*
                                        <input
                                            type="text"
                                            placeholder="To"
                                            value={spending[1]}
                                            className={styles.input}
                                            onKeyPress={numbersOnlyMask}
                                            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                                                dispatch(
                                                    saveFilters({
                                                        spending: [spending[0], e.target.value],
                                                    } as SpendingsFilters)
                                                )
                                            }
                                        />
                                        */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
            </>
        );
    }
);

export default SelectMoreFiltersStyles;
