import React from 'react';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Reducers } from '../../reducers';
import { useLocation } from 'react-router-dom';
import styles from './SocialButtons.module.scss';
import CopyToClipboard from 'react-copy-to-clipboard';
import shareUrlSelector from '../../selectors/shareUrlSelector';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';

const SocialButtons: React.FC = React.memo(
    (): React.ReactElement => {
        const [linkCopied, setLinkCopied] = React.useState<boolean>(false);
        const [mobileShareOpen, setMobileShareOpen] = React.useState<boolean>(false);
        const [shareOpen, setShareOpen] = React.useState<boolean>(false);

        const shareUrl = shareUrlSelector({
            location: useLocation(),
            state: useSelector((state: Reducers) => state),
        });

        const onLinkCopyLinkClick = React.useCallback(() => {
            setLinkCopied(true);

            setTimeout(() => setLinkCopied(false), 5000);
        }, [setLinkCopied]);

        return (
            <>
                <div
                    className={cx(styles.socialButtons, {
                        [styles.socialButtonsRevealed]: mobileShareOpen,
                        [styles.socialButtonsToggle]: shareOpen,
                    })}
                >
                    <div className={styles.linkShareMobile} onClick={() => setMobileShareOpen(true)}>
                        <strong>
                            <span className="icon iconShareNew"></span>
                        </strong>
                    </div>

                    {!linkCopied && (
                        <CopyToClipboard text={shareUrl} onCopy={onLinkCopyLinkClick}>
                            <button>
                                <strong>
                                    <span className="icon iconUrl"></span>
                                </strong>
                            </button>
                        </CopyToClipboard>
                    )}

                    {linkCopied && (
                        <div className={styles.linkShareSuccess}>
                            <span>Link Copied</span>
                        </div>
                    )}

                    <TwitterShareButton url={shareUrl}>
                        <strong>
                            <span className="icon iconTwitter"></span>
                        </strong>
                    </TwitterShareButton>

                    <FacebookShareButton url={shareUrl}>
                        <strong>
                            <span className="icon iconFacebook"></span>
                        </strong>
                    </FacebookShareButton>

                    <EmailShareButton url={shareUrl}>
                        <strong>
                            <span className="icon iconMail"></span>
                        </strong>
                    </EmailShareButton>
                </div>

                <div
                    className={cx(styles.shareDesk, {
                        [styles.socialDeskToggle]: shareOpen,
                    })}
                >
                    <button
                        className={styles.shareOpen}
                        onClick={() => {
                            setShareOpen(!shareOpen);
                        }}
                    >
                        <strong>
                            <span
                                className={cx('icon', {
                                    [styles.iconClose]: shareOpen,
                                    [styles.iconShareNew]: !shareOpen,
                                })}
                            ></span>
                        </strong>
                    </button>
                    <div className={styles.socialDropDown}>
                        <ul>
                            <li>
                                <EmailShareButton url={shareUrl}>
                                    <span className="icon iconMail"></span>
                                    Send Email
                                </EmailShareButton>
                            </li>
                            <li>
                                <FacebookShareButton url={shareUrl}>
                                    <span className="icon iconFacebook"></span>
                                    Share on Facebook
                                </FacebookShareButton>
                            </li>
                            <li>
                                <TwitterShareButton url={shareUrl}>
                                    <span className="icon iconTwitter"></span>
                                    Share on Twitter
                                </TwitterShareButton>
                            </li>
                            <li>
                                <CopyToClipboard text={shareUrl} onCopy={onLinkCopyLinkClick}>
                                    <strong>
                                        <span className="icon iconUrl"></span>
                                        {!linkCopied ? 'Copy link' : 'Link Copied'}
                                    </strong>
                                </CopyToClipboard>
                            </li>
                        </ul>
                    </div>
                </div>
            </>
        );
    }
);

export default SocialButtons;
