import { combineReducers, Reducer } from 'redux';
import filters, { FiltersReducerState } from './Filters';
import geoData, { GeoDataReducerState } from './GeoData';
import mapData, { MapDataReducerState } from './MapData';
import industry, { IndustryReducerState } from './Industry';
import loadings, { LoadingsReducerState } from './loadings';
import spendings, { SpendingsReducerState } from './Spendings';

export interface Reducers {
    filters: FiltersReducerState;
    geoData: GeoDataReducerState;
    mapData: MapDataReducerState;
    loadings: LoadingsReducerState;
    spendings: SpendingsReducerState;
    industry: IndustryReducerState;
}

const reducers: Reducer<Reducers> = combineReducers({
    filters,
    geoData,
    mapData,
    loadings,
    spendings,
    industry,
});

export default reducers;
