import React, { Dispatch } from 'react';
import styles from './SidebarList.module.scss';
import cx from 'classnames';
import { State } from '../../types';
import { formatNumber } from '../../utils/formatNumber';
import { deviceDetect } from '../../utils/deviceDetect';
import ColorBar from './ColorBar';

interface Props {
    stateData: State | null;
    activeTab: string;
    setActiveTab: Dispatch<string>;
}

const defaultData = {
    total_spending: 3459923098904,
    loan_spending: 1342386723467,
    direct_payment_spending: 1561278782292,
    grants_spending: 429765983569,
    contract_spending: 110805831606,
    other_spending: 15685777968,
}

const SidebarList: React.FC<Props> = React.memo(
    ({ stateData, activeTab, setActiveTab }: Props): React.ReactElement => {
        const changeTab = (event: any, tab: string) => {
            event.stopPropagation();
            setActiveTab(tab);
        }
        const { isMobile } = deviceDetect();

        return <div className={styles.sidebarList}>
            <ul>

                    <li onClick={($event) => changeTab($event, "total_spending")}
                        className={activeTab === "total_spending" ? styles.active : ''}>
                        <div className={styles.contentWrapper}>
                            <ColorBar colorIndex="1" />
                            <strong>${formatNumber(stateData ? stateData.total_spending : defaultData.total_spending)}</strong>
                            Overall Spending
                        </div>
                    </li>
                    <li onClick={($event) => changeTab($event, "loan_spending")}
                        className={activeTab === "loan_spending" ? styles.active : ''}>
                        <div className={styles.contentWrapper}>
                            <ColorBar colorIndex="2" />
                            <strong>${formatNumber(stateData ? stateData.loan_spending : defaultData.loan_spending)}</strong>
                            Loans
                        </div>
                    </li>
                    <li onClick={($event) => changeTab($event, "direct_payment_spending")}
                        className={activeTab === "direct_payment_spending" ? styles.active : ''}>
                        <div className={styles.contentWrapper}>
                            <ColorBar colorIndex="3" />
                            <strong>${formatNumber(stateData ? stateData.direct_payment_spending : defaultData.direct_payment_spending)}</strong>
                            Direct Payments
                        </div>
                    </li>
                    <li onClick={($event) => changeTab($event, "grants_spending")}
                        className={activeTab === "grants_spending" ? styles.active : ''}>
                        <div className={styles.contentWrapper}>
                            <ColorBar colorIndex="4" />
                            <strong>${formatNumber(stateData ? stateData.grants_spending : defaultData.grants_spending)}</strong>
                            Grants
                        </div>
                    </li>
                    <li onClick={($event) => changeTab($event, "contract_spending")}
                        className={activeTab === "contract_spending" ? styles.active : ''}>
                        <div className={styles.contentWrapper}>
                            <ColorBar colorIndex="5" />
                            <strong>${formatNumber(stateData ? stateData.contract_spending : defaultData.contract_spending)}</strong>
                            Contract Spending
                        </div>
                    </li>
                    <li onClick={($event) => changeTab($event, "other_spending")}
                        className={activeTab === "other_spending" ? styles.active : ''}>
                        <div className={styles.contentWrapper}>
                            <ColorBar colorIndex="6" />
                            <strong>${formatNumber(stateData ? stateData.other_spending : defaultData.other_spending)}</strong>
                            Other
                        </div>
                    </li>
            </ul>
        </div>
    }
);

export default SidebarList;
