import React from 'react';
import cx from 'classnames';
import { PC } from '../../../types';
import styles from './IndustryDetailed.module.scss';
import Footer from '../../../components/Footer';
import IndustryHeader from '../../../components/Headers/IndustryHeader';
import { Reducers } from '../../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';
import { fetchIndustryDetail, fetchStatesSpendings } from '../../../reducers/Industry';
import Parser from 'html-react-parser';
import {
	formatNumber,
	parseGroupedFloat,
	parseAndFormatAmt,
	numberWithCommas,
} from '../../../utils/formatNumber';
import * as t from '../../../reducers/Industry/types';
import { industries, industriesId } from '../../../constants/industries';

import { Link } from 'react-router-dom';
import StatesSlider from '../../../components/StatesSlider';
import { clearGeoData } from "../../../reducers/GeoData";

const combineStats = (stats: t.IndustryStats[]) => {
	const sumValues = {
		industry: '',
		assistance_total: 0,
		median_assistance: 0,
		direct_payment: 0,
		grants: 0,
		loans: 0,
		other: 0,
		assist_count: 0,
		assist_agency_count: 0,
		contract: 0,
		contract_count: 0,
		contract_agency_count: 0,
		median_contract: 0,
	};

	let parsed = 0;
	stats.map((stat: t.IndustryStats) => {
		sumValues.assistance_total += parseGroupedFloat(stat.assistance_total);
		//parsed = parseGroupedFloat(stat.median_assistance);
		//numValues.median_assistance += !isNaN(parsed) ? parsed : 0;
		parsed = parseGroupedFloat(stat.direct_payment);
		sumValues.direct_payment += !isNaN(parsed) ? parsed : 0;
		parsed = parseGroupedFloat(stat.grants);
		sumValues.grants += !isNaN(parsed) ? parsed : 0;
		parsed = parseGroupedFloat(stat.loans);
		sumValues.loans += !isNaN(parsed) ? parsed : 0;
		parsed = parseGroupedFloat(stat.other);
		sumValues.other += !isNaN(parsed) ? parsed : 0;
		parsed = parseGroupedFloat(stat.assist_count);
		sumValues.assist_count +=
			typeof stat.assist_count === 'number' ? stat.assist_count : !isNaN(parsed) ? parsed : 0;
		parsed = parseGroupedFloat(stat.assist_agency_count);
		sumValues.assist_agency_count +=
			typeof stat.assist_agency_count === 'number'
				? stat.assist_agency_count
				: !isNaN(parsed)
					? parsed
					: 0;
		parsed = parseGroupedFloat(stat.contract);
		sumValues.contract += !isNaN(parsed) ? parsed : 0;
		parsed = parseGroupedFloat(stat.contract_count);
		sumValues.contract_count +=
			typeof stat.contract_count === 'number'
				? stat.contract_count
				: !isNaN(parsed)
					? parsed
					: 0;
		parsed = parseGroupedFloat(stat.contract_agency_count);
		sumValues.contract_agency_count +=
			typeof stat.contract_agency_count === 'number'
				? stat.contract_agency_count
				: !isNaN(parsed)
					? parsed
					: 0;
		//parsed = parseGroupedFloat(stat.median_contract);
		//numValues.median_contract += !isNaN(parsed) ? parsed : 0;
	});

	return sumValues;
};

const IndustryDetailed: PC = React.memo(({ match }: any) => {
	const history = useHistory(),
		dispatch = useDispatch();
	const { stats, top10 } = useSelector(({ industry }: Reducers) => industry);

	const industryFromUrl = match.params.industry;
	const industryObj = industries.find((ind) => {
		return ind.slug === industryFromUrl;
	});

	const localStats = industryFromUrl !== industriesId ? stats[0] : combineStats(stats);

	if (!industryObj) {
		return <Redirect to="/industry-overviews" />;
	}

	let totalSpent;
	if (localStats) {
		const parsedAssist = parseGroupedFloat(localStats.assistance_total);
		const parsedContract = parseGroupedFloat(localStats.contract);
		totalSpent =
			(!isNaN(parsedAssist) ? parsedAssist : 0) +
			(!isNaN(parsedContract) ? parsedContract : 0);
	}
	const totalAwards = localStats?.contract_count + localStats?.assist_count;

	React.useEffect(() => {
		dispatch(clearGeoData())
	}, [])

	React.useEffect(() => {
		dispatch(fetchIndustryDetail(industryObj?.name));
		dispatch(fetchStatesSpendings());
	}, [dispatch]);

	const backgroundImageInfo = {
		backgroundImage: `url('/img/top-blur-circle.svg'), url('/img/bottom-blur-circle.svg'), url('/img/bottom-blur-circle.svg'), url(/img/industry-detailed/${industryObj?.slug}.jpg`,
		backgroundPosition: `right 50% bottom 35%, right 110% top 25%, right 0 bottom -200px, left 50% top 0`,
		backgroundSize: `793px, 793px, 793px, 100% auto`,
	};

	return (
		<>
			<div className="bg-industry-detailed" style={backgroundImageInfo}>
				<div className={cx('container', styles.industryDetailedPage)}>
					<button className={styles.goBack} onClick={() => history.goBack()}>
						<span className="icon iconChevronLeft"></span>Back
                    </button>
					<div className="row no-gutters align-items-stretch">
						<div className="col-12">
							<div className="row justify-center">
								<div className="col-md-8">
									<div className={styles.info}>
										<h1 className={styles.titleIndustry}>
											{industryObj?.niceName}
										</h1>
									</div>
									<ul className={styles.totalInfo}>
										<li className={styles.totalSpending}>
											Total <br />
											Spending
										</li>
										<li className={styles.totalSpent}>
											<div>
												<img src="/img/total-spent.svg" alt="" />
												<strong>${formatNumber(totalSpent || 0)}</strong>
												<span>Total Spent</span>
											</div>
										</li>
										<li className={styles.totalAwards}>
											<div>
												<img src="/img/number-awards.svg" alt="" />
												<strong>{formatNumber(totalAwards || 0)}</strong>
												<span>Number of transactions</span>
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid">
					{industryObj && <StatesSlider industryId={industryObj.id} />}
				</div>
				<div className={cx('container', styles.industryDetailedPage)}>
					<div className="row no-gutters align-items-stretch">
						<div className="col-12">
							<div className="row">
								{industryObj?.singleColumn ? (
									<div className={cx('col-md-12', styles.descriptionFull)}>
										<p>
											{Parser(industryObj?.shortDescription)}
											<br />
											{Parser(industryObj?.longDescription)}
										</p>
									</div>
								) : (
									<>
										<div className={cx('col-md-6', styles.descriptionLeft)}>
											<p>{Parser(industryObj?.shortDescription)}</p>
										</div>
										<div className={cx('col-md-6', styles.descriptionRight)}>
											<p>{Parser(industryObj?.longDescription)}</p>
										</div>
									</>
								)}
							</div>

							<section className={styles.detailedLists}>
								<h4 className={styles.title}>Assistance Spending</h4>

								<ul className={styles.listCellsTop}>
									<li>
										<div>
											<strong>
												{parseAndFormatAmt(
													localStats?.assistance_total,
													'$'
												)}
											</strong>
											<span>Amount Spent</span>
										</div>
									</li>
									<li>
										<div>
											<strong>
												{numberWithCommas(localStats?.assist_count)}
											</strong>
											<span># of Transactions</span>
										</div>
									</li>
									{/*<li>
										<div>
											<strong>
												{parseAndFormatAmt(
													localStats?.median_assistance,
													'$'
												)}
											</strong>
											<span>Median Transaction Amount</span>
										</div>
									</li>*/}
									<li>
										<div>
											<strong>{localStats?.assist_agency_count}</strong>
											<span># of Agencies</span>
										</div>
									</li>
								</ul>
							</section>

							<section className={styles.detailedLists}>
								<h5 className={styles.subtitle}>Award Type</h5>
								<ul className={styles.listCellsTop}>
									<li>
										<div>
											<strong>
												{parseAndFormatAmt(localStats?.loans, '$')}
											</strong>
											<span>Loans</span>
										</div>
									</li>
									<li>
										<div>
											<strong>
												{parseAndFormatAmt(localStats?.direct_payment, '$')}
											</strong>
											<span>Direct Assistance</span>
										</div>
									</li>
									<li>
										<div>
											<strong>
												{parseAndFormatAmt(localStats?.grants, '$')}
											</strong>
											<span>Grants</span>
										</div>
									</li>
									<li>
										<div>
											<strong>
												{parseAndFormatAmt(localStats?.other, '$')}
											</strong>
											<span>Other Assistance</span>
										</div>
									</li>
								</ul>
								<section className={styles.detailedLists}>
									<h5 className={styles.subtitle}>
										10 Largest Assistance Transactions
									</h5>
									{top10
										.filter(
											(item: t.IndustryTop10) =>
												item.award_type === 'assistance'
										).length > 0 ?
										<ul className={styles.listCells}>
											{top10
												.filter(
													(item: t.IndustryTop10) =>
														item.award_type === 'assistance'
												)
												.map((award: t.IndustryTop10, index: number) => (
													<li key={index}>
														<div>
															<strong>
																${formatNumber(award.amount_spent)}
															</strong>
															<span>{award.recipient}</span>
														</div>
													</li>
												))}
										</ul>
										:
										<div className={styles.noRecords}>
											The 10 largest assistance transactions can not be identified for this industry
											sector because many transactions had matching amounts of $10 million, which was
											the maximum amount allowed for Paycheck Protection Program loans.
										</div>
									}
									{/*
									<div className="text-center">
										<Link
											to={`/table-view?searchType=Assistance&industry=${industryObj.id}&page=1&perPage=20&sort%5B0%5D=amount&sort%5B1%5D=-1`}
										>
											<button className="btn btn-link">SHOW ASSISTANCE TRANSACTIONS</button>
										</Link>
									</div>
									*/}
								</section>
							</section>
							<section className={styles.detailedLists}>
								<h4 className={styles.title}>Contract Spending</h4>

								<ul className={styles.listCellsTop}>
									<li>
										<div>
											<strong>
												{parseAndFormatAmt(localStats?.contract, '$')}
											</strong>
											<span>Amount Spent</span>
										</div>
									</li>
									<li>
										<div>
											<strong>
												{numberWithCommas(localStats?.contract_count)}
											</strong>
											<span># of Transactions</span>
										</div>
									</li>
									{/*<li>
										<div>
											<strong>
												{parseAndFormatAmt(
													localStats?.median_contract,
													'$'
												)}
											</strong>
											<span>Median Transaction Amount</span>
										</div>
									</li>*/}
									<li>
										<div>
											<strong>{localStats?.contract_agency_count}</strong>
											<span># of Agencies</span>
										</div>
									</li>
								</ul>
							</section>
							<section className={styles.detailedLists}>
								<h5 className={styles.subtitle}>
									10 Largest Contract Transactions
								</h5>
								<ul className={styles.listCells}>
									{top10
										.filter(
											(item: t.IndustryTop10) =>
												item.award_type === 'contract'
										)
										.map((award: t.IndustryTop10, index: number) => (
											<li key={index}>
												<div>
													<strong>
														${formatNumber(award.amount_spent)}
													</strong>
													<span>{award.recipient}</span>
												</div>
											</li>
										))}
								</ul>
								<div className="text-center">
									<Link
										to={`/table-view?searchType=Contracts&industry=${industryObj.id}&page=1&perPage=20&sort%5B0%5D=amount&sort%5B1%5D=-1`}
									>
										<button className="btn btn-link">SHOW CONTRACT TRANSACTIONS</button>
									</Link>
								</div>
							</section>
						</div>
					</div>
				</div>

				<Footer />
			</div>
		</>
	);
});

IndustryDetailed.header = IndustryHeader;

export default IndustryDetailed;
