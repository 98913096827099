import React from 'react';
import cx from 'classnames';
import { PC } from '../../types';
import styles from './DataMethodology.module.scss';
import Footer from '../../components/Footer';
import Scrollspy from 'react-scrollspy-ez';

const DataMethodology: PC = React.memo(() => {
    return (
        <>
            <div className="bg-solid bg-overwflow">
                <div className="scroll-content">
                    <div className={cx('container', styles.methodology)}>
                        <div className="row">
                            <div className="col-md-5">
                                <h1 className={styles.methodologyTitle}>Data Methodology</h1>
                                <div className={styles.anchorMenu}>
                                    <Scrollspy
                                        ids={['init-menu', 'contracts', 'assistance', 'additional-data-sources']}
                                        activeItemClassName={styles.anchorMenuActive}
                                        offset={0}
                                        itemContainerClassName={styles.level1}
                                    />
                                    <Scrollspy
                                        ids={[
                                            'init-submenu',
                                            'data-sources',
                                            'none',
                                            'federal-reserve',
                                            'paycheck-protection-loan-program',
                                            'health-and-human-services-programs',
                                            'accelerated-and-advanced-payments',
                                            'economic-impact-payments',
                                            'accountability-flags',
                                            'industry-sector-assignments-for-assistance-programs',
                                            'mapping-statistics',
                                        ]}
                                        activeItemClassName={styles.anchorMenuActive}
                                        offset={-200}
                                        itemContainerClassName={styles.level2}
                                    />
                                </div>
                            </div>
                            <div className={cx('col-md-6', 'offset-md-1', styles.gradientBottom)}>
                                <h3 id="init-menu" className={cx('p-0', 'm-0')}>
                                    <span></span>
                                </h3>
                                <h2 className={styles.methodologyHeadingFirst}>
                                    Primary Data Source for Awards
                                </h2>
                                <h3 id="init-submenu" className={cx('p-0', 'm-0')}>
                                    <span></span>
                                </h3>
                                <p>
                                    The largest single source for the transactions presented in this COVID-19 spending tracker is USASpending.gov. The USASpending.gov website is the government’s primary tool to disclose contracts, grants, loans, direct assistance, and other financial awards to recipients. The website is updated regularly with spending data directly from federal agencies. USASpending.gov reports federal awards in two different data formats – Contracts and Assistance (which includes grants, loans, direct assistance, and other non-contract awards).
                                </p>
                                <h2 className={styles.methodologyHeadingL1} id="contracts">
                                    Contracts
                                </h2>
                                <p>
                                    This tracker includes all contract transactions that used the National Interest Action (NIA) Code for the coronavirus pandemic (P20C). The NIA codes are used to track multi-agency spending in response to various national events, most often hurricanes. The P20C code for the pandemic became available for agencies March 31, 2020.
                                </p>
                                <p>
                                    Award descriptions for those contract transactions without the pandemic NIA code were reviewed. Any awards clearly in response to the pandemic are also included. This includes changes (often with no or little associated cost) to existing contracts that originally had nothing to do with the pandemic, but that require alteration, or even cancellation, due to pandemic related delays, supply chain disruptions, and/or travel restrictions.
                                </p>
                                <h2 className={styles.methodologyHeadingL1} id="assistance">
                                    Assistance
                                </h2>
                                <p>
                                    This tracker includes all transactions listed for 39 assistance programs specifically created to respond to the coronavirus crisis, with some exceptions. These programs are:
                                </p>
                                <div className={cx('row', styles.methodologyNegativeRow)}>
                                    <div className="col-xl-5">
                                        <ul className={styles.customUl}>
                                            <li>Agricultural Worker Pandemic Relief and Protection Program</li>
                                            <li>American Rescue Plan Act of 2021 Loan Payment</li>
                                            <li>Aviation Manufacturing Jobs Protection (AMJP) Program</li>
                                            <li>Community Development Financial Institutions Rapid Response Program (CDFI RRP)</li>
                                            <li>Community Health Workers for COVID Response and Resilient Communities</li>
                                            <li>Community Navigator Pilot Program</li>
                                            <li>Coronavirus Capital Project Fund</li>
                                            <li>Coronavirus Economic Relief for Transportation Services Act</li>
                                            <li>Coronavirus Emergency Supplemental Funding Program</li>
                                            <li>Coronavirus Food Assistance Program (CFAP)</li>
                                            <li>Coronavirus Food Assistance Program 2 (CFAP2)</li>
                                            <li>Coronavirus Relief - Pandemic Relief for Aviation Workers</li>
                                            <li>Coronavirus Relief Fund</li>
                                            <li>Coronavirus State and Local Fiscal Recovery Funds</li>
                                            <li>COVID-19 Telehealth Program</li>
                                            <li>COVID-19 Testing for Rural Health Clinics*</li>
                                            <li>COVID-19 Testing for the Uninsured*</li>
                                            <li>Economic Injury Disaster Loan Emergency Advance</li>
                                            <li>Education Stabilization Fund</li>
                                            <li>Emergency Broadband Benefit Program (EBBP)</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-5 offset-xl-1">
                                        <ul className={styles.customUl}>
                                            <li>Emergency Connectivity Fund Program</li>
                                            <li>Emergency Grants to Address Mental and Substance Use Disorders During COVID-19</li>
                                            <li>Emergency Rental Assistance Program</li>
                                            <li>Homeowner Assistance Fund </li>
                                            <li>OED Resource Partners Training Portal (RPTP)</li>
                                            <li>Pandemic Assistance Block Grant</li>
                                            <li>Pandemic Assistance for Timber Harvesters and Haulers (PATHH) Program</li>
                                            <li>Pandemic EBT Administrative Costs</li>
                                            <li>Pandemic EBT Food Benefits </li>
                                            <li>Pandemic Livestock Indemnity Program</li>
                                            <li>Pandemic Market Volatility Assistance Program (PMVAP)</li>
                                            <li>Paycheck Protection Loan Program (PPP)**</li>
                                            <li>Provider Relief Fund*</li>
                                            <li>Public Health Informatics & Technology Workforce Development Program (PHIT Workforce Development)</li>
                                            <li>Restaurant Revitalization Fund</li>
                                            <li>Shuttered Venue Operators Grant Program</li>
                                            <li>Spot Market Hog Pandemic Program</li>
                                            <li>Tribal Broadband Connectivity Program</li>
                                            <li>Veteran Rapid Retraining Assistance Program</li>
                                        </ul>
                                    </div>
                                </div>
                                <p className={styles.fontSm}>
                                    *The spending data provided on USASpending.gov for the Provider Relief Fund, COVID-19 Testing for the Uninsured, and COVID-19 Testing for Rural Health Clinics consists only of transactions to a single recipient that is helping the agency distribute the program funds to actual recipients. For each of these programs, alternate data sources were used. See the <strong>Other Data Sources</strong> below for details on the replacement data used.
                                </p>
                                <p className={styles.fontSm}>
                                    **The Paycheck Protection Loan Program transactions are now being reported through USASpending.gov and this tracker uses that data as the primary source on spending for that program. However, the Small Business Administration releases additional data fields not included in the USAspending reporting. This tracker pulls in that additional data for those awards that can be matched across the data sources. See the <strong>Other Data Sources</strong> below for details.
                                </p>
                                <p>
                                    Award descriptions for those assistance transactions outside of these 39 programs were reviewed. Any awards clearly in response to the pandemic were also collected. This includes changes (often with no or little associated cost) to existing assistance awards that originally had nothing to do with the pandemic, but that require alteration, or even cancellation, due to pandemic related delays, supply chain disruptions, and/or travel restrictions.
                                </p>
                                <p>
                                    In August 2020, USASpending.gov launched new data features to allow better tracking the use of funds authorized under the six main legislative COVID-19 relief efforts.  The new Disaster Emergency Fund Code field indicates if the funds derive from a COVID relief law. There are also two new data fields detailing the amount of COVID authorized funds that have been obligated or outlaid. While these fields are helpful, POGO has noted inconsistencies in their application. As such, use of these new fields in identifying assistance spending for this tracker is limited at this time.
                                </p>
                                <p>
                                    Currently, the Disaster Emergency Fund Code is being used to identify COVID related transactions for two programs:
                                </p>
                                <ul className={styles.customUl}>
                                    <li>Airport Improvement Grants</li>
                                    <li>National School Lunch Programs</li>
                                </ul>
                                <p>
                                    The descriptions of transactions within these programs do not provide any details about the purpose or cause for the awards. In these cases, the COVID related Disaster Emergency Fund Code allows the COVID awards to be separated from the ongoing program work unrelated to the pandemic. The tracker still uses the main federal obligation and face value loan amounts, rather than the new amounts on COVID fund obligations and outlays.
                                </p>
                                <h2
                                    className={styles.methodologyHeadingL1}
                                    id="additional-data-sources"
                                >
                                    Additional Data Sources
                                </h2>
                                <h3 id="none">
                                    <span></span>
                                </h3>
                                <p>
                                    In each case, the available spending data for these programs was merged into detailed data structure used for assistance awards on USASpending.gov.  However, none of datasets described below had the same level of extensive detail as is regularly provided through USASpending.gov. In fact, many of the additional data sources failed to provide key details on the transaction such as dates of transactions, location details (street address, county, congressional district) of recipients, parent company information, and award identification numbers. Without these and other details there are serious difficulties tracking this spending and evaluating it by location or over time. POGO hopes that the level of detail for these datasets will improve to more closely match the data structure used by USASpending.gov to allow improved use.
                                </p>
                                <h3 className={styles.methodologyHeadingL2} id="federal-reserve">
                                    Federal Reserve
                                </h3>
                                <p>
                                    The Federal Reserve Bank of the United States was granted authority to oversee multiple programs and facilities to support financial markets, encourage lending, and provide liquidity and credit to key sectors. The Federal Reserve has{' '}
                                    <a
                                        href="https://www.federalreserve.gov/publications/reports-to-congress-in-response-to-covid-19.htm"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                    posted transaction specific disclosures</a>{' '}
                                    for seven of the COVID-19 specific facilities were operating. Those facilities included:
                                </p>
                                <div className={cx('row', styles.methodologyNegativeRow)}>
                                    <div className="col-xl-5">
                                        <ul className={styles.customUl}>
                                            <li>Term Asset-Backed Securities Loan Facility</li>
                                            <li>Secondary Market Corporate Credit Facility</li>
                                            <li>Municipal Liquidity Facility</li>
                                            <li>Paycheck Protection Program Liquidity Facility</li>
                                        </ul>
                                    </div>
                                    <div className="col-xl-5 offset-xl-1">
                                        <ul className={styles.customUl}>
                                            <li>Main Street Expanded Loan Facility</li>
                                            <li>Main Street New Loan Facility</li>
                                            <li>Main Street Priority Loan Facility</li>
                                        </ul>
                                    </div>
                                </div>
                                <p className={styles.fontSm}>
                                    The Federal Reserve discontinued most of the loan and credit programs toward the end of 2020 and new transactions are not expected without Congressional action to renew the programs.
                                </p>
                                <p className={styles.fontSm}>
                                    It should be noted that for the Paycheck Protection Program Liquidity Facility, POGO has added new transactions to account for apparent repayment of loans. The program provides liquidity loans to lenders that have made loans out to businesses under the Paycheck Protection Program. The loans under this program must be repaid to the Federal Reserve when the PPP loans overseen by the lender are either repaid or forgiven. The disclosed data for the program is full list of active loans under the facility that the Federal Reserve updates by adding new loans and removing loans that have apparently be repaid. To fully track the flow of money, this tracker retains all liquidity loans made under this facility and inserts new transactions indicating a repayment of the face value of the loan for all those that the Federal Reserve removes from the list or indicates as having a zero-balance outstanding.
                                </p>
                                <h3
                                    className={styles.methodologyHeadingL2}
                                    id="paycheck-protection-loan-program"
                                >
                                    Paycheck Protection Loan Program
                                </h3>
                                <p>
                                    After initially withholding much of the data around the Paycheck Protection Program (PPP) loans, the Small Business Administration has begun disclosing a broader set of data elements associated with these awards. The primary source of data used for these loans is from USASpending.gov. However, the Small Business Administration releases additional data elements not included in the USASpending.gov. We use the Federal Award Identification Number to link awards listed in both datasets and attach the additional SBA disclosures on industry sector, company ownership, and lender data.
                                </p>
                                <p className={styles.fontSm}>
                                    Not all of the PPP loans listed in USASpending.gov have corresponding disclosures in the SBA data. It appears that loans declined or quickly returned by recipients have been removed from the SBA dataset, though USASpending.gov documents the initial obligation of funds and then a corresponding de-obligation of the same amount. For these loans, the additional PPP data fields are reported as “Not Disclosed” in the tracker.
                                </p>
                                <h3
                                    className={styles.methodologyHeadingL2}
                                    id="health-and-human-services-programs"
                                >
                                    Health and Human Services Programs
                                </h3>
                                <p>
                                    Several major programs providing direct assistance to hospitals and health care providers are reported into the USASpending.gov system as obligations to a single entity that is assisting in processing payments to actual recipients. Those three programs are:
                                </p>
                                <ul className={styles.customUl}>
                                    <li>Provider Relief Fund</li>
                                    <li>COVID-19 Testing for the Uninsured</li>
                                    <li>COVID-19 Testing for Rural Health Centers</li>
                                </ul>
                                <p className={styles.fontSm}>
                                    The Department of Health and Human Services provides{' '}
                                    <a
                                        href="https://taggs.hhs.gov/Coronavirus/Overview"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >some data on the amounts paid to recipients
                                    </a>{' '}
                                    under each of these programs that is updated weekly. The tracker uses the limited data on recipient payments instead of the USASpending.gov data to allow users to explore who actually received funds rather than just the company processing payments. However, the disclosed HHS data lacks dates of payments; location details such as street address, county, zip code or congressional district; which limit the ability to track these awards over time or to locations.
                                </p>
                                <h3
                                    className={styles.methodologyHeadingL2}
                                    id="accelerated-and-advanced-payments"
                                >
                                    Accelerated and Advanced Payments
                                </h3>
                                <p>
                                    The Centers for Medicare & Medicaid Services (CMS) initially expanded its Accelerated and Advance Payment Program to a broader group of providers and suppliers to increase cash flow to health care to those healthcare providers impacted by the pandemic. As of April 26, 2020 CMS suspended the expanded use of Accelerated and Advanced Payments, which was deemed less necessary because of the increased availability of assistance funds through programs like the Provider Relief Fund. The CMS has posted{' '}
                                    <a
                                        href="https://www.cms.gov/files/zip/accelerated-payment-provider-details-state.zip"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >spreadsheets with payment amounts and provider names in each state
                                    </a>.{' '}
                                    The tracker uses this limited data and treats these payments as loans against costs for services expected to be claimed later.  The lack of detailed information on these awards – dates, location details (street address, city, county, zip code, congressional district) limit our ability to track these payments over or to assign them specific geographic areas beyond states.
                                </p>
                                <h3
                                    className={styles.methodologyHeadingL2}
                                    id="economic-impact-payments"
                                >
                                    Economic Impact Payments
                                </h3>
                                <p>
                                    Under the Coronavirus Aid, Relief, and Economic Security (CARES) Act congress authorized the disbursement of more than $290 billion in direct relief in the form of stimulus checks mailed to qualifying households. The Coronavirus Response and Relief Act authorized an additional $164 billion for such direct payments. And the American Rescue Plan Act authorized an additional $411 billion. The Internal Revenue Service has periodically issued data on the number of payments and total amount distributed in each state. The state amounts listed for{' '}
                                    <a
                                        href="https://www.irs.gov/statistics/soi-tax-stats-coronavirus-aid-relief-and-economic-security-act-cares-act-statistics"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >each of the three rounds of payments
                                    </a>.{' '}
                                    have been used in this tracker. POGO hopes to get more detailed breakdowns that would explain how much is going to counties, congressional districts, and zip codes but that information has not yet been disclosed by the agency.
                                </p>
                                <h3
                                    className={styles.methodologyHeadingL2}
                                    id="accountability-flags"
                                >
                                    Accountability Flags
                                </h3>
                                <p>
                                    POGO worked with{' '}
                                    <a
                                        href="https://www.goodjobsfirst.org/violation-tracker"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >Good Jobs First
                                    </a>.{' '}
                                    to bring corporate accountability data from their{' '}
                                    <a
                                        href="https://covidstimuluswatch.org/"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >COVID Stimulus Watch
                                    </a>{' '}
                                    site into the tracker. The COVID Stimulus Watch project links a set of over 84,000 COVID spending transactions to the recipients’ history of subsidies and violations. Users can see if a company that received a loan, contract, or other form of federal aid had previously paid any fines or penalties for violating government regulations related to employment and contracting practices, environmental, health, and safety violations, as well as consumer protection, financial misconduct, and unfair competition. This feature makes it far easier to scrutinize COVID-19 aid recipients and understand how much federal spending went to companies with a history of misconduct.
                                </p>
                                <p>
                                    The data matching process used to link POGO’s spending transaction records with COVID Stimulus Watch entries produces two types of “Accountability Flags”: “Exact” matches, displayed as red flags, are produced when the transaction amount, recipient name, and state fields in both databases match. These are transactions where POGO has found a direct match in the COVID Stimulus Watch database and can provide a direct link to Good Jobs First’s company profile. “Potential” matches, displayed as yellow flags, are produced when the recipient name and state fields in both databases match, but where the transaction amount does not. These are transactions that may fall outside the scope of the COVID Stimulus Watch project, but which may nevertheless involve the same recipient company. In these cases, we provide a link to the{' '}
                                    <a
                                        href="https://data.covidstimuluswatch.org/prog.php"
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >COVID Stimulus Watch search page
                                    </a>.{' '}
                                </p>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    );
});

export default DataMethodology;
