import React from 'react';
import styles from './StatCircle.module.scss';

export interface CircleProps {
    circleCount: number;
}

const renderCircles = (count: number) => {
    const circles = [];
    for (let i = 1; i <= count; i++) {
        circles.push(<div key={i} className={styles.circle}></div>);
    }
    return circles;
};

const StatCircle: React.FC<CircleProps> = React.memo(
    ({ circleCount: circles }: CircleProps): React.ReactElement => {
        return <React.Fragment>{renderCircles(circles)}</React.Fragment>;
    }
);

export default StatCircle;
