import React from 'react';
import cx from 'classnames';
import { SortDirection, SortType } from '../../types';
import useClickOutside from '../../hooks/useClickOutside';

interface Props {
    sort: SortType;
    label: string;
    field: string;
    onChange: (sort: SortType) => void;
}

const Sortable: React.FC<Props> = React.memo(({ label, sort, field, onChange }: Props) => {
    const ref = React.useRef<HTMLDivElement>(null as any);
    const [isOpened, setOpened, toggleOpened] = useClickOutside(ref);

    const currentField = sort[0] === field;

    const onSortChange = React.useCallback(
        (direction: SortDirection) => {
            onChange([field, direction]);

            setOpened(false);
        },
        [field, setOpened]
    );

    let sortAscending = 'A - Z',
        sortDescending = 'Z - A';

    switch (field) {
        case 'amount':
            sortAscending = 'Low to High';
            sortDescending = 'High to Low';
            break;
        case 'gjf_nsv':
            sortAscending = 'Exact Matches on Top';
            sortDescending = 'All Matches on Top';
            break;
        case 'zip':
            sortAscending = 'Ascending';
            sortDescending = 'Descending';
            break;
        case 'date':
            sortAscending = 'Oldest to newest';
            sortDescending = 'Newest to oldest';
            break;
        default:
            break;
    }

    const isAsc = (currentField && sort[1] === SortDirection.ASC) || currentField && sort[0] === 'gjf_nsv';
    const isDesc = (sort[1] === SortDirection.DESC && currentField && field !== 'gjf_nsv') ||
        (sort[1] === SortDirection.DESC && sort[0] === 'gjf_ns' && field === 'gjf_nsv');

    return (
        <div className="order">
            <a onClick={toggleOpened}>
                {label}

                <span
                    className={cx('icon', {
                        iconChevronUp: isAsc,
                        iconChevronDown: isDesc,
                        iconUpDown: !isAsc && !isDesc,
                    })}
                />
            </a>

            {isOpened && (
                <div className="order-list" ref={ref}>
                    <ul>
                        <li
                            className={cx({
                                'is-active': isAsc,
                            })}
                        >
                            <a onClick={() => onSortChange(SortDirection.ASC)}>
                                {label}: {sortAscending}
                            </a>
                        </li>

                        <li
                            className={cx({
                                'is-active': isDesc
                            })}
                        >
                            <a onClick={() => onSortChange(SortDirection.DESC)}>
                                {label}: {sortDescending}
                            </a>
                        </li>
                    </ul>
                </div>
            )}
        </div>
    );
});

export default Sortable;
