import { Reducers } from '../';
import api from '../../api/filters';
import { SearchType } from '../../constants';
import { ResultItemsPayload } from '../../types';
import { OptionType, RequestState } from '../../types';
import { loadingSelector } from '../../selectors/loadingSelector';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

const cloneDeep = require('lodash/cloneDeep');

export interface IndustriesOptions {
    [SearchType.Contracts]: OptionType[];
    [SearchType.Assistance]: OptionType[];
}

export interface FiltersReducerState {
    agencies: OptionType[];
    programs: OptionType[];
    industries: IndustriesOptions;
}

interface ResultAgencyType {
    id: number;
    name: string;
}

interface ResultProgramType {
    id: number;
    name: string;
}

interface ResultInstitutionType {
    id: number;
    name: string;
}

interface ResultIndustriesPayload {
    [SearchType.Contracts]: ResultItemsPayload<ResultInstitutionType>;
    [SearchType.Assistance]: ResultItemsPayload<ResultInstitutionType>;
}

const initialState: FiltersReducerState = {
    agencies: [],
    programs: [],
    industries: {
        [SearchType.Contracts]: [],
        [SearchType.Assistance]: [],
    },
};

export const fetchAgencies = createAsyncThunk(
    'filters/fetchAgencies',
    async ({ type }: any) => {
        return await api.fetchAgencies(type).catch((e) => {
            throw e.response.data.error || e.message;
        });
    },
    {
        condition: (props: any | undefined = {}, { getState }) => {
            const loading = loadingSelector(getState() as Reducers, ['filters/fetchAgencies'])[
                'filters/fetchAgencies'
            ];

            return loading.state === RequestState.Nothing;
        },
    }
);

export const fetchPrograms = createAsyncThunk(
    'filters/fetchPrograms',
    async () => {
        return await api.fetchPrograms().catch((e) => {
            throw e.response.data.error || e.message;
        });
    },
    {
        condition: (props: any | undefined = {}, { getState }) => {
            const loading = loadingSelector(getState() as Reducers, ['filters/fetchPrograms'])[
                'filters/fetchPrograms'
            ];

            return loading.state === RequestState.Nothing;
        },
    }
);

export const fetchIndustries = createAsyncThunk(
    'filters/fetchIndustries',
    async () => {
        return Promise.all([
            api.fetchIndustries(SearchType.Contracts).catch((e) => {
                throw e.response.data.error || e.message;
            }),
            api.fetchIndustries(SearchType.Assistance).catch((e) => {
                throw e.response.data.error || e.message;
            }),
        ]).then(
            ([contracts, assistance]: [
                ResultItemsPayload<ResultProgramType>,
                ResultItemsPayload<ResultProgramType>
            ]) => ({
                [SearchType.Contracts]: contracts,
                [SearchType.Assistance]: assistance,
            })
        );
    },
    {
        condition: (props: any | undefined = {}, { getState }) => {
            const loading = loadingSelector(getState() as Reducers, ['filters/fetchIndustries'])[
                'filters/fetchIndustries'
            ];

            return loading.state === RequestState.Nothing;
        },
    }
);

const filtersReducer = createSlice({
    name: 'filters',
    initialState: cloneDeep(initialState),
    reducers: {},
    extraReducers: {
        [fetchAgencies.fulfilled as any]: (
            state: FiltersReducerState,
            action: PayloadAction<ResultItemsPayload<ResultAgencyType>>
        ) => {
            const { status, result } = action.payload;

            if (status === 'SUCCESS' && Array.isArray(result)) {
                state.agencies = result
                    .map(({ id, name }: ResultAgencyType) => ({
                        value: id,
                        label: name,
                    }))
                    .sort((a: OptionType, b: OptionType) =>
                        a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                    );
            }
        },

        [fetchPrograms.fulfilled as any]: (
            state: FiltersReducerState,
            action: PayloadAction<ResultItemsPayload<ResultProgramType>>
        ) => {
            const { status, result } = action.payload;

            if (status === 'SUCCESS' && Array.isArray(result)) {
                state.programs = result.map(({ id, name }: ResultProgramType) => ({
                    value: id,
                    label: name,
                }));
            }
        },

        [fetchIndustries.fulfilled as any]: (
            state: FiltersReducerState,
            { payload }: PayloadAction<ResultIndustriesPayload>
        ) => {
            const contracts = payload[SearchType.Contracts];
            const assistance = payload[SearchType.Assistance];

            if (contracts.status === 'SUCCESS' && Array.isArray(contracts.result)) {
                state.industries[SearchType.Contracts] = contracts.result
                    .map(({ id, name }: ResultProgramType) => ({
                        value: id,
                        label: name,
                    }))
                    .sort((a: OptionType, b: OptionType) =>
                        a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                    );
            }

            if (assistance.status === 'SUCCESS' && Array.isArray(assistance.result)) {
                state.industries[SearchType.Assistance] = assistance.result
                    .map(({ id, name }: ResultProgramType) => ({
                        value: id,
                        label: name,
                    }))
                    .sort((a: OptionType, b: OptionType) =>
                        a.label < b.label ? -1 : a.label > b.label ? 1 : 0
                    );
            }
        },
    },
});

export default filtersReducer.reducer;
