import React from 'react';
import { uniqueIcon } from '../../../utils/uniqueIcon';

export const Logo = uniqueIcon(() => (
    <svg
        width="130"
        height="30"
        viewBox="0 0 130 30"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M115 5.88428C112.273 5.88428 110.13 6.7424 108.377 8.45865C106.688 10.1749 105.844 12.3202 105.844 14.8946C105.844 17.5915 106.688 19.7981 108.312 21.3918C110 23.0467 112.208 23.8436 114.935 23.8436C117.662 23.8436 119.87 23.0467 121.559 21.3305C123.182 19.6756 124.026 17.4689 124.026 14.8333C124.026 12.075 123.052 9.80714 121.104 8.09088C119.546 6.61981 117.468 5.88428 115 5.88428Z"
            fill="white"
        ></path>
        <path
            d="M123.442 24.0274C121.169 26.0502 118.377 27.0922 115 27.0922C111.623 27.0922 108.766 26.0502 106.494 24.0274C103.896 21.6983 102.597 18.6335 102.597 14.8946C102.597 11.2169 103.896 8.15217 106.494 5.82297C108.766 3.73898 111.623 2.69696 114.935 2.69696C118.247 2.69696 121.104 3.73898 123.377 5.82297C125.974 8.21346 127.273 11.2169 127.273 14.8946C127.338 18.6335 126.039 21.6983 123.442 24.0274ZM100 29.7279H130V0H100V29.7279Z"
            fill="white"
        ></path>
        <path
            d="M48.3765 5.88428C45.6493 5.88428 43.5064 6.7424 41.7532 8.45865C40.0649 10.1749 39.2207 12.3202 39.2207 14.8946C39.2207 17.5915 40.0649 19.7981 41.6882 21.3918C43.3765 23.0467 45.5844 23.8436 48.3116 23.8436C51.0389 23.8436 53.2467 23.0467 54.935 21.3305C56.5584 19.6756 57.4025 17.4689 57.4025 14.8333C57.4025 12.075 56.4285 9.80714 54.4805 8.09088C52.8571 6.61981 50.7792 5.88428 48.3765 5.88428Z"
            fill="white"
        ></path>
        <path
            d="M56.7531 24.0274C54.4804 26.0502 51.6882 27.0922 48.3115 27.0922C44.9349 27.0922 42.0778 26.0502 39.805 24.0274C37.2076 21.6983 35.9089 18.6335 35.9089 14.8946C35.9089 11.2169 37.2076 8.15217 39.805 5.82297C42.0778 3.73898 44.9349 2.69696 48.2466 2.69696C51.5583 2.69696 54.4154 3.73898 56.6882 5.82297C59.2856 8.21346 60.5843 11.2169 60.5843 14.8946C60.6492 18.6335 59.3505 21.6983 56.7531 24.0274ZM33.3115 29.7279H63.3115V0H33.3115V29.7279Z"
            fill="white"
        ></path>
        <path
            d="M93.9612 14.5881C93.9612 18.3271 92.8573 21.3305 90.5846 23.6597C88.3767 25.9889 85.3897 27.0922 81.6235 27.0922C78.2469 27.0922 75.3898 26.0502 73.117 24.0274C70.5196 21.6983 69.2209 18.6335 69.2209 14.8946C69.2209 11.2169 70.5196 8.15217 73.117 5.82297C75.3898 3.73898 78.3118 2.69696 81.8183 2.69696C86.0391 2.69696 89.5456 4.59711 92.2729 8.39735L89.2858 10.2362C87.2729 7.35534 84.6755 5.94556 81.5586 5.94556C79.0911 5.94556 77.0131 6.6811 75.3898 8.15217C73.4417 9.86842 72.5326 12.1363 72.5326 14.8946C72.5326 17.5915 73.3768 19.7368 75.0002 21.3918C76.6235 23.0468 78.8313 23.9049 81.5586 23.9049C85.8443 23.9049 88.6365 21.7596 89.8703 17.4689H80.9092V14.2816H93.8962V14.5881H93.9612ZM66.6235 29.7279H96.6235V0H66.6235V29.7279Z"
            fill="white"
        ></path>
        <path
            d="M18.182 5.9458H13.9612H7.01318V13.8528H14.0262H18.5067C21.5586 13.8528 23.0522 12.5656 23.0522 10.0525C22.9872 7.29428 21.3638 5.9458 18.182 5.9458Z"
            fill="white"
        ></path>
        <path
            d="M24.026 15.3236C22.5325 16.5495 21.1688 17.1012 18.3766 17.1012H13.961H7.01298V27.0922H3.76624V2.75825H14.026H19.1559C21.5584 2.75825 22.7273 3.30992 24.1558 4.6584C25.5844 6.00685 26.2987 7.7844 26.2987 9.99101C26.2338 12.3202 25.5195 14.0977 24.026 15.3236ZM0 29.7279H30V0H0V29.7279Z"
            fill="white"
        ></path>
    </svg>
));
