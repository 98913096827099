import React from 'react';
import styles from './MapLegendColor.module.scss';
import cx from 'classnames';
import { tabsData } from "../../constants";
import { formatNumber } from "../../utils/formatNumber";

interface Props {
    activeTab: string
}

const MapLegendColor: React.FC<Props> = React.memo(
    ({ activeTab }): React.ReactElement => (
        <div className={styles.mapLegendColor}>
            {
                tabsData[activeTab].legend.map((level: number, index: number) =>
                    <span key={index} className={cx(styles.col, 'col-'+index)}>
                        <strong>
                            <span>$</span>{formatNumber(level * Math.pow(10, 9))} {index === tabsData[activeTab].length - 1 &&
                                    <span>&gt;</span>}</strong></span>)
            }
        </div>
    )
);

export default MapLegendColor;
