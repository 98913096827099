import { Reducers } from '../reducers';
import { NetworkStates } from '../types';
import { createSelector } from '@reduxjs/toolkit';
import { DefaultNetworkState } from '../constants';

export const loadingSelector = createSelector(
    (state: Reducers) => state.loadings,
    (state: Reducers, loadings: string[]) => loadings,
    (loadings: NetworkStates, keys: string[]): NetworkStates => {
        return keys.reduce(
            (carry: NetworkStates, key: string) => ({
                ...carry,
                [key]: loadings[key] || DefaultNetworkState,
            }),
            {}
        );
    }
);
