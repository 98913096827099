import { PaginationType } from '../types';
import { defaultPagination } from '../constants';

interface EndpointPagination {
    total_pages: number;
    current_page: number;
    record_count: number;
    records_per_page: number;
    record_count_display: string;
}

export const transformPagination = (pagination: EndpointPagination): PaginationType => {
    return {
        page: pagination.current_page || defaultPagination.page,
        pageCount: pagination.total_pages || defaultPagination.pageCount,
        perPage: pagination.records_per_page || defaultPagination.perPage,
        recordCount: pagination.record_count || defaultPagination.recordCount,
    };
};
