import { Reducers } from '../reducers';
import { SortDirection } from '../types';
import { SearchType } from '../constants';
import { createSelector } from '@reduxjs/toolkit';
import { GeoDataReducerState } from '../reducers/GeoData';

interface ParamsType {
    [key: string]: string;
}

export interface GetEndpointUrlSelectorProps {
    state: Reducers;
    overide?: ParamsType;
}

const getEndpointUrlSelector = createSelector(
    (props: GetEndpointUrlSelectorProps) => props.state,
    (props: GetEndpointUrlSelectorProps) => props.overide || {},
    (state: Reducers, overide: ParamsType): string => {
        const {
            geoData,
            spendings: { filters, pagination, sort },
        } = state;

        const params: ParamsType = Object.entries(filters).reduce(
            (carry: ParamsType, [key, value]: [string, string]): ParamsType => {
                if (['searchType', 'spending'].indexOf(key) === -1 && value) {
                    carry[key] = value;
                }

                return carry;
            },
            {} as ParamsType
        );

        if (filters.spending[0]) {
            params['above-amount'] = filters.spending[0].replace(/\D/g, '');
        }

        if (filters.spending[1]) {
            params['below-amount'] = filters.spending[1].replace(/\D/g, '');
        }

        if (pagination.page) {
            params.page = pagination.page as any;
        }

        if (pagination.perPage) {
            params.limit = pagination.perPage as any;
        }

        if (sort[0] && sort[1]) {
            params.sort = sort[0];
            params.sort_order = sort[1] === SortDirection.DESC ? 'desc' : 'asc';
        }

        ['zip', 'county', 'state'].forEach((key: keyof GeoDataReducerState | string) => {
            const value = geoData[key as keyof GeoDataReducerState];

            if (value && (!Array.isArray(value) || value[0])) {
                params[key] = (Array.isArray(value) ? value[0] : value) as string;
            }
        });

        Object.keys(overide).forEach((key: string) => {
            params[key] = overide[key];
        });

        if (filters.searchType !== SearchType.Assistance) {
            delete params['type'];
            delete params['program'];
        }

        const endpoint = filters.searchType === SearchType.Contracts ? 'contracts' : 'assistance';
        const queryString = new URLSearchParams(params as any).toString();

        return `${process.env.REACT_APP_API_ENDPOINT}/${endpoint}?${queryString}`;
    }
);

export default getEndpointUrlSelector;
