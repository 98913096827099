import React from 'react';
import styles from './DisplayMode.module.scss';
import Button from '../Button';
import { Link, useLocation } from 'react-router-dom';

const DisplayMode: React.FC = React.memo(
    (): React.ReactElement => {
        const location = useLocation();

        return (
            <div className={styles.displayMode}>
                <div className="btn-group btn-group-line">
                    <Link to="/" title="OVERVIEW">
                        <Button cls={location.pathname === '/' ? 'btn-active' : ''}>
                            <span className="icon iconLayer"></span>
                            <span className="text">OVERVIEW</span>
                        </Button>
                    </Link>
                    <Link to="/map-view" title="MAP">
                        <Button cls={location.pathname === '/map-view' ? 'btn-active' : ''}>
                            <span className="icon iconMap"></span>
                            <span className="text">MAP</span>
                        </Button>
                    </Link>

                    <Link to="/table-view" title="TABLE">
                        <Button cls={location.pathname === '/table-view' ? 'btn-active' : ''}>
                            <span className="icon iconAllIndustries"></span>
                            <span className="text">TABLE</span>
                        </Button>
                    </Link>

                    <Link to="/industry-overviews" title="INDUSTRY OVERVIEWS">
                        <Button
                            cls={location.pathname === '/industry-overviews' ? 'btn-active' : ''}
                        >
                            <span className="icon iconBriefcase"></span>
                            <span className="text">INDUSTRY OVERVIEWS</span>
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }
);

export default DisplayMode;
