import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../reducers';

const defaultConfig = {
    series: [
        {
            name: 'Unemployment rate',
            data: [14.4, 7.9, 10.3, 9.4],
        },
    ],
    options: {
        chart: {
            type: 'bar',
            toolbar: {
                show: false,
            },
            colors: ['#2B59C3', '#23C2B5'],
        },
        grid: {
            borderColor: '#3a3c3e',
            strokeDashArray: 1,
            yaxis: {
                lines: {
                    show: true,
                },
            },
            xaxis: {
                lines: {
                    show: false,
                },
            },
            padding: {
                left: 10,
                right: 10,
                top: 0,
                bottom: 0,
            },
        },
        plotOptions: {
            bar: {
                horizontal: true,
                endingShape: 'rounded',
                barHeight: '34%',
                dataLabels: {
                    position: 'top',
                },
                distributed: true,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            offsetX: 22,
            offsetY: 2,
            style: {
                colors: ['#A4A9BF'],
                fontSize: '10px',
                fontFamily: '"Inter", sans-serif',
                fontWeight: 'normal',
            },
            formatter: function (val: number) {
                return `${val}%`;
            },
        },
        tooltip: {
            enabled: false,
        },
        xaxis: {
            categories: ['January 2021', 'April 2020', 'July 2020', 'October 2020', 'January 2021'],
            max: 20,
            axisTicks: {
                show: false,
            },
            axisBorder: {
                show: false,
            },
            labels: {
                show: false,
            },
        },
        yaxis: {
            labels: {
                offsetY: 2,
                style: {
                    colors: '#A4A9BF',
                },
            },
        },
    },
};

const BarChart: React.FC = React.memo(
    (): React.ReactElement => {
        const [chartConfig, setChartConfig] = React.useState(defaultConfig);
        const minChartHeight = 83;
        const additionalBarHeight = 37;
        const [chartHeight, setChartHeight] = React.useState(
            minChartHeight + (defaultConfig.series[0].data.length - 1) * additionalBarHeight
        );
        const { selectionData }: any = useSelector((state: Reducers) => state.geoData);

        useEffect(() => {
            if (selectionData) {
                const newChartConfig = JSON.parse(JSON.stringify(defaultConfig));
                const jan20 = selectionData.unemployment_jan_20
                    ? selectionData.unemployment_jan_20.toFixed(1)
                    : 0;
                const jul = selectionData.unemployment_jul_20
                    ? selectionData.unemployment_jul_20.toFixed(1)
                    : 0;
                const apr = selectionData.unemployment_apr_20
                    ? selectionData.unemployment_apr_20.toFixed(1)
                    : 0;
                const oct = selectionData.unemployment_oct_20
                    ? selectionData.unemployment_oct_20.toFixed(1)
                    : 0;
                const jan = selectionData.unemployment_jan_21
                    ? selectionData.unemployment_jan_21.toFixed(1)
                    : 0;
                const oct21 = selectionData.unemployment_oct_21
                    ? selectionData.unemployment_oct_21.toFixed(1)
                    : 0;
                const jan22 = selectionData.unemployment_jan_22
                    ? selectionData.unemployment_jan_22.toFixed(1)
                    : 0;
                const apr22 = selectionData.unemployment_apr_22
                    ? selectionData.unemployment_apr_22.toFixed(1)
                    : 0;
                newChartConfig.series[0].data = [jan20, apr, jul, oct, jan, oct21, jan22, apr22];
                setChartHeight(
                    minChartHeight +
                        (newChartConfig.series[0].data.length - 1) * additionalBarHeight
                );
                setChartConfig(newChartConfig);
            } else {
                setChartHeight(
                    minChartHeight + (defaultConfig.series[0].data.length - 1) * additionalBarHeight
                );
                setChartConfig(defaultConfig);
            }
        }, [selectionData]);

        return (
            <>
                <Chart
                    options={chartConfig.options}
                    series={chartConfig.series}
                    type="bar"
                    width="100%"
                    height={chartHeight}
                />
            </>
        );
    }
);

export default BarChart;
