import { Reducers } from '../';
import api from '../../api/map';
import { RequestState, ResultItemsPayload, State } from '../../types';
import { loadingSelector } from '../../selectors/loadingSelector';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

const cloneDeep = require('lodash/cloneDeep');

export interface MapDataReducerState {
    statesData: State[] | undefined;
    countiesData: State[] | undefined;
    zipData: State[] | undefined;
    currentMapType?: string;
    currentLayer?: number;
}

const initialState: MapDataReducerState = {
    statesData: undefined,
    countiesData: undefined,
    zipData: undefined,
};

export const fetchStatesData = createAsyncThunk('mapData/fetchStatesData', async () => {
    return await api.fetchStates().catch((e) => {
        throw e.response.data.error || e.message;
    });
});

export const fetchCountiesData = createAsyncThunk('mapData/fetchCountiesData', async () => {
    return await api.fetchCounties().catch((e) => {
        throw e.response.data.error || e.message;
    });
});

export const fetchZipData = createAsyncThunk('mapData/fetchZipData', async (state: string) => {
    return await api.fetchZip(state).catch((e) => {
        throw e.response.data.error || e.message;
    });
});

const mapDataReducer = createSlice({
    name: 'mapData',
    initialState: cloneDeep(initialState),
    reducers: {
        setMapType(state, { payload }: PayloadAction<string | undefined>) {
            state.currentMapType = payload;
        },

        setLayer(state, { payload }: PayloadAction<number | undefined>) {
            state.currentLayer = payload;
        },
    },
    extraReducers: {
        [fetchStatesData.fulfilled as any]: (
            state: MapDataReducerState,
            action: PayloadAction<ResultItemsPayload<State>>
        ) => {
            const { status, result } = action.payload;
            if (status === 'SUCCESS') {
                state.statesData = result;
            }
        },
        [fetchCountiesData.fulfilled as any]: (
            state: MapDataReducerState,
            action: PayloadAction<ResultItemsPayload<State>>
        ) => {
            const { status, result } = action.payload;

            if (status === 'SUCCESS') {
                state.countiesData = result;
            }
        },
        [fetchZipData.fulfilled as any]: (
            state: MapDataReducerState,
            action: PayloadAction<ResultItemsPayload<State>>
        ) => {
            const { status, result } = action.payload;

            if (status === 'SUCCESS') {
                state.zipData = result;
            }
        },
        [fetchZipData.pending as any]: (state: MapDataReducerState) => {
            state.zipData = undefined;
        },
    },
});

export const { setMapType, setLayer } = mapDataReducer.actions;

export default mapDataReducer.reducer;
