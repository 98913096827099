import React from 'react';
import cx from 'classnames';
import { PC } from '../../types';
import styles from './IndustryOverviews.module.scss';
import Footer from '../../components/Footer';
import IndustryHeader from '../../components/Headers/IndustryHeader';
import { industries } from '../../constants/industries';
import { Link } from 'react-router-dom';

const IndustryOverviews: PC = React.memo(() => {
    return (
        <>
            <div className="bg-industry-overviews bg-overwflow">
                <div className="scroll-content">
                    <div className={cx('container', styles.industryOverviewsPage)}>
                        <div className="row no-gutters align-items-stretch">
                            <div className="col-12">
                                <div className={styles.info}>
                                    <div className="row justify-center">
                                        <div className="col-md-9">
                                            <h1 className={cx(styles.title, 'mt-0')}>
                                                Industry Overviews
                                            </h1>

                                            <div className={styles.description}>
                                                <p>
                                                    These are broad industry sectors as taken from
                                                    the North American Industry Classification
                                                    System. Awards have been assigned to
                                                    identifiable sectors based on data provided
                                                    about the recipients or based on the purpose of
                                                    federal programs under which the funds have been
                                                    spent. If an industry sector could not be
                                                    identified or reliably assigned, then those
                                                    transactions have been categorized as
                                                    Unclassified Establishments.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <ul className={styles.listing}>
                                    {industries.map((industry, index) => (
                                        <li className={styles.item} key={index}>
                                            <Link to={`/industry/${industry.slug}`}>
                                                <button>
                                                    <div
                                                        className={styles.itemImgContainer}
                                                        style={{
                                                            backgroundImage: `url(/img/industry-overviews/${industry.slug}.jpg)`,
                                                        }}
                                                    >
                                                        {/*
                                                        <img
                                                            src={industry.icon}
                                                            alt={industry.niceName}
                                                        />
                                                        */}
                                                    </div>
                                                    <div className={styles.itemInfo}>
                                                        <span>{industry.niceName}</span>
                                                        <div className={styles.itemExplore}>
                                                            Explore data
                                                        </div>
                                                    </div>
                                                </button>
                                            </Link>
                                        </li>
                                    ))}
                                </ul>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    );
});

IndustryOverviews.header = IndustryHeader;

export default IndustryOverviews;
