import React from 'react';
import styles from './RightSidebar.module.scss';

export type ToggleSidebar = () => void;

export interface SidebarProps {
    open: boolean;
    toggleSidebar: ToggleSidebar;
    children: React.ReactNode;
}

const RightSidebar: React.FC<SidebarProps> = React.memo(
    ({ open, children, toggleSidebar }: SidebarProps): React.ReactElement => {
        return (
            <>
                <nav className={`${styles.sidebarWrapper} ${open ? styles.sidebarOpen : ''}`}>
                    {children}
                </nav>
                <div
                    className={`${styles.sidebarOverlay} ${
                        open ? styles.sidebarOverlayActive : ''
                    }`}
                    onClick={toggleSidebar}
                ></div>
            </>
        );
    }
);

export default RightSidebar;
