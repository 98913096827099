import React, { useEffect } from 'react';
import cx from 'classnames';
import { RequestState } from '../../types';
import styles from './SidebarTabs.module.scss';
import SidebarBlockOverview from './SidebabBlockOverview';
import { GeoDataReducerState, setSelectionData } from '../../reducers/GeoData';
import { BarChart, LineChart, MppChart, ScatterCharts } from '../SidebarCharts';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../reducers';
import { hasGeoDataSelector } from '../../selectors/geoDataSelector';
import Lottie from 'lottie-react-web';
import { loadingSelector } from '../../selectors/loadingSelector';

interface Props {
    geoData?: GeoDataReducerState;
}

const SidebarTabs: React.FC<Props> = React.memo(
    ({ geoData }: Props): React.ReactElement => {
        const { statesData, countiesData, zipData }: any = useSelector(
            ({ mapData }: Reducers) => mapData
        );
        const hasGeoData = useSelector((state: Reducers) => hasGeoDataSelector(state));
        const { selectionData }: any = useSelector((state: Reducers) => state.geoData);
        const loadingZip = useSelector((state: Reducers) =>
            loadingSelector(state, ['mapData/fetchZipData'])
        )['mapData/fetchZipData'];
        const loadingCounties = useSelector((state: Reducers) =>
            loadingSelector(state, ['mapData/fetchCountiesData'])
        )['mapData/fetchCountiesData'];
        const loadingStates = useSelector((state: Reducers) =>
            loadingSelector(state, ['mapData/fetchStatesData'])
        )['mapData/fetchStatesData'];
        const dispatch = useDispatch();

        useEffect(() => {
            if (hasGeoData && geoData && statesData && countiesData) {
                const state = geoData.state[0];
                if (geoData.zip) {
                    if (zipData && zipData[state]) {
                        dispatch(setSelectionData(zipData[state][geoData.zip]));
                    }
                } else if (geoData.county[0]) {
                    dispatch(setSelectionData(countiesData[state][geoData.county[0]]));
                } else {
                    dispatch(setSelectionData(statesData[state]));
                }
            } else {
                dispatch(setSelectionData(undefined));
            }
        }, [geoData, statesData, countiesData, zipData]);

        return (
            <div className={styles.sidebarTabs}>
                <div className={styles.sidebarBlockInfo}>
                    <div className="row">
                        <div className={cx(styles.col, 'col-sm-4')}>
                            <span>STATE:</span>
                            <strong
                                className={
                                    geoData?.state[1] &&
                                    (geoData?.county[0] || geoData.zip
                                        ? styles.textWhite
                                        : styles.textBlue)
                                }
                            >
                                {geoData?.state[1] ? geoData?.state[1] : 'None selected'}
                            </strong>
                        </div>
                        <div className={cx(styles.col, 'col-sm-4')}>
                            <span>COUNTY:</span>
                            <strong className={geoData?.county[0] && styles.textBlue}>
                                {geoData?.county[1]
                                    ? geoData?.county[1].replace(' County', '')
                                    : 'None selected'}
                            </strong>
                        </div>
                        <div className={cx(styles.col, 'col-sm-4')}>
                            <span>ZIP:</span>
                            <strong className={geoData?.zip && styles.textBlue}>
                                {geoData?.zip ? geoData?.zip : 'None selected'}
                            </strong>
                        </div>
                        {(loadingZip.state === RequestState.Pending ||
                            loadingCounties.state === RequestState.Pending ||
                            loadingStates.state === RequestState.Pending) && (
                            <div className={styles.loading}>
                                <Lottie
                                    width="20px"
                                    height="20px"
                                    options={{
                                        animationData: require('../../components/Loading/Loading.json'),
                                        loop: true,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>

                <SidebarBlockOverview />
                {!geoData?.territory && (
                    <div className={styles.sidebarBlock}>
                        <div className={cx('mr-2', styles.sidebarBlockCharts)}>
                            <ScatterCharts geoData={geoData} />
                            <div className={styles.chartTitle}>
                                <div className="horizontal-chart-title">Spending per capita, $</div>
                                <div>
                                    <span style={{ opacity: 0.7 }}>
                                        {' '}
                                        Note: Every dot represents a{' '}
                                    </span>{' '}
                                    <span style={{ opacity: 1 }}>
                                        {geoData?.zip
                                            ? 'zip code'
                                            : geoData?.county[0]
                                            ? 'county'
                                            : 'state'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                )}
                {(!selectionData ||
                    selectionData['mp_white'] ||
                    selectionData['mp_black'] ||
                    selectionData['mp_other'] ||
                    selectionData['mp_asian'] ||
                    selectionData['mp_hispanic']) && (
                    <div className={styles.sidebarBlock}>
                        <h3 className={styles.sidebarBlockTitle}>DEMOGRAPHICS</h3>
                        <div className="ml-2 mr-2">
                            <MppChart />
                        </div>
                    </div>
                )}

                {(!selectionData ||
                    selectionData.unemployment_jan_20 ||
                    selectionData.unemployment_apr_20 ||
                    selectionData.unemployment_jul_20 ||
                    selectionData.unemployment_oct_20 ||
                    selectionData.unemployment_jan_21 ||
                    selectionData.unemployment_apr_22) && (
                    <div className={styles.sidebarBlock}>
                        <h3 className={cx('mb-0', styles.sidebarBlockTitle)}>Unemployment rate</h3>
                        <div className="ml-2 mr-2">
                            <LineChart />
                        </div>
                    </div>
                )}

                {/*
                <div className={styles.trasactionsItem}>
                    <table className={styles.tableList}>
                        <thead>
                            <tr>
                                <th className={styles.value}>$18,880.00</th>
                                <th></th>
                                <th className={styles.type}>Loan</th>
                                <th>07.25.2020</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.title}>Small Business Administration</td>
                                <td>
                                    <span className="icon iconArrowLeft"></span>
                                </td>
                                <td>Paycheck Protection Loan Program</td>
                                <td>75 Main Street, New York, NY 10025</td>
                            </tr>
                        </tbody>
                    </table>
                </div>

                <div className={styles.trasactionsItem}>
                    <table className={styles.tableList}>
                        <thead>
                            <tr>
                                <th className={styles.value}>$18,880.00</th>
                                <th></th>
                                <th className={styles.type}>Loan</th>
                                <th>07.25.2020</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className={styles.title}>Small Business Administration</td>
                                <td>
                                    <span className="icon iconArrowLeft"></span>
                                </td>
                                <td>Paycheck Protection Loan Program</td>
                                <td>75 Main Street, New York, NY 10025</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className={styles.sidebarPagination}>
                    <ReactPaginate
                        breakLabel={'...'}
                        pageRangeDisplayed={5}
                        marginPagesDisplayed={1}
                        nextLabel={<span className="icon iconChevronRight"></span>}
                        nextClassName={cx('next', styles.next)}
                        pageCount={15}
                        previousLabel={<span className="icon iconChevronLeft"></span>}
                        breakClassName={cx('break-me', styles.breakMe)}
                        activeClassName={cx('active', styles.active)}
                        previousClassName={cx('previous', styles.previous)}
                        containerClassName={cx('pagination', styles.paginationLeft)}
                    />
                </div>
                */}
            </div>
        );
    }
);

export default SidebarTabs;
