import React from 'react';
import cx from 'classnames';
import styles from './MobileLandingPage.module.scss';
import SidebarList from '../../components/SidebarList';
import { InfoBubblesWrapper, SubscribeBubble } from '../../components/InfoBubbles';

const MobileLandingPage: React.FC = React.memo(
    (): React.ReactElement => {
        const [activeTab, setActiveTab] = React.useState<string>('total_spending');

        return (
            <div className="bg-solid overflow-hidden">
                <div className="container">
                    <div className={cx('row', styles.mapSpacer)}>
                        <div className="col-12">
                            <h1 className={styles.headingTitle}>
                                Find out where COVID-19 funds were spent.
                            </h1>

                            <h2 className={styles.headingSubtitle}>
                                Please visit the <span>desktop site</span> to explore the data
                            </h2>

                            <img className={styles.staticMap} src="/img/map.png" alt="" />

                            <div className={styles.backgroundEffect1} />

                            <div className={styles.backgroundEffect1} />
                        </div>
                    </div>

                    <div className={cx('row', styles.mapBg)}>
                        <div className="col-md-2">
                            <SidebarList
                                stateData={null}
                                activeTab={activeTab}
                                setActiveTab={setActiveTab}
                            />
                        </div>

                        <InfoBubblesWrapper>
                            <SubscribeBubble />
                        </InfoBubblesWrapper>
                    </div>
                </div>
            </div>
        );
    }
);

export default MobileLandingPage;
