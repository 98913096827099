import * as t from './types';
import api from '../../api/industry';
import mapApi from '../../api/map';
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { HttpResponse } from '../../types';
const cloneDeep = require('lodash/cloneDeep');

export * from './types';

const initialState: t.IndustryReducerState = {
    stats: [],
    top10: [],
    statesNumbers: [],
    statesData: [],
};

export const fetchIndustryDetail = createAsyncThunk(
    'industry/fetchIndustryDetail',
    async (industry: string | undefined) => {
        return Promise.all([
            api.fetchIndustryStats().catch((e) => {
                throw e.response.data.error || e.message;
            }),
            api.fetchIndustryTop10().catch((e) => {
                throw e.response.data.error || e.message;
            }),
            api.fetchStatesStats().catch((e) => {
                throw e.response.data.error || e.message;
            }),
        ]).then(([stats, top10, numbers]: [t.IndustryStats, t.IndustryTop10, t.StateStats]) => ({
            stats: stats,
            top10: top10,
            statesNumbers: numbers,
        }));
    }
);

export const fetchStatesSpendings = createAsyncThunk('industry/fetchStatesSpendings', async () => {
    return await api.fetchStateSpendings().catch((e) => {
        throw e.response.data.error || e.message;
    });
});

const industryDataReducer = createSlice({
    name: 'industry',
    initialState: cloneDeep(initialState),
    reducers: {},
    extraReducers: {
        [fetchIndustryDetail.pending as any]: (state) => {
            state.stats = [];
            state.top10 = [];
            state.statesNumbers = [];
        },
        [fetchIndustryDetail.fulfilled as any]: (state, action) => {
            if (action.meta.arg && action.payload.stats) {
                const statsFiltered = action.payload.stats.filter((industry: t.IndustryStats) => {
                    if (action.meta.arg === 'Undisclosed') {
                        return (
                            industry.industry === 'NULL' || industry.industry === 'Unknown Total'
                        );
                    }
                    return industry.industry === action.meta.arg;
                });
                const top10Filtered = action.payload.top10.filter(
                    (industry: t.IndustryTop10) => industry.industry === action.meta.arg
                );
                const statesNumbersFiltered = action.payload.statesNumbers.filter(
                    (s: t.StateStats) => s.industry === action.meta.arg
                );
                state.stats = statsFiltered;
                state.top10 = top10Filtered;
                state.statesNumbers = statesNumbersFiltered;
            } else {
                state.stats = action.payload.stats;
                state.top10 = action.payload.top10;
                state.statesNumbers = action.payload.statesNumbers;
            }
        },
        [fetchStatesSpendings.fulfilled as any]: (state, action) => {
            const { status, result } = action.payload;

            if (status === 'SUCCESS') {
                state.statesData = result;
            }
        },
    },
});

export default industryDataReducer.reducer;
