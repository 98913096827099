import qs from 'qs';
import React from 'react';
import cx from 'classnames';
import Button from '../../Button';
import { Reducers } from '../../../reducers';
import styles from './FilterTop.module.scss';
import { useLocation } from 'react-router-dom';
import { SearchType } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { clearGeoData } from '../../../reducers/GeoData';
import SelectIndustryStyles from '../SelectIndustryStyles';
import SelectMoreFiltersStyles from '../SelectMoreFiltersStyles';
import MapSearch from '../../MapContainer/MapSearch';
import SelectedGeoData from '../../SelectedGeoData';
import { AwardType, OptionType } from '../../../types';
import {
    clearData,
    clearDataExceptSearchType,
    fetchData,
    setDataFromUrl,
    setFilters,
    SpendingsFilters,
} from '../../../reducers/Spendings';
import AwardsDropdown from '../../Form/AwardsDropdown';
import Popover from '../../Popover';
import RecipientSearch from '../../Form/RecipientSearch';
import { hasGeoDataSelector } from '../../../selectors/geoDataSelector';

const get = require('lodash/get');

const FilterTop: React.FC = React.memo(
    (): React.ReactElement => {
        const dispatch = useDispatch();
        const location = useLocation();
        const { searchType, ...filters } = useSelector(
            ({ spendings }: Reducers) => spendings.filters
        );

        const hasGeoData = useSelector((state: Reducers) => hasGeoDataSelector(state));

        React.useEffect(() => {
            if (location.search.length > 1) {
                dispatch(setDataFromUrl(qs.parse(location.search.substr(1)) as any));

                dispatch(fetchData({}));
            }
        }, []);

        const onChange = React.useCallback(
            (e: React.ChangeEvent<HTMLInputElement>) => {
                dispatch(
                    setFilters({
                        searchType:
                            searchType === SearchType.Contracts
                                ? SearchType.Assistance
                                : SearchType.Contracts,
                    } as SpendingsFilters)
                );
                dispatch(clearDataExceptSearchType());
                dispatch(clearGeoData());
            },
            [dispatch, searchType]
        );

        const onClear = React.useCallback(() => {
            dispatch(clearData());

            dispatch(clearGeoData());
        }, [dispatch]);

        const onApply = React.useCallback(() => {
            dispatch(fetchData({}));
        }, [dispatch]);

        const totalAdvancedFilters = ['agency', 'program', 'accIssues', 'spending.0', 'spending.1'].reduce(
            (carry: number, key: string) => carry + (get(filters, key) ? 1 : 0),
            0
        );

        const awardTypes: AwardType<any>[] = [
            ['Loans', '1'],
            ['Grants', '2'],
            ['Direct Payments', '3'],
            ['Other', '4'],
        ];

        const availableTypes: OptionType[] = awardTypes.map(([label, value]: any) => ({
            label,
            value,
        }));

        const disableFilters = searchType === SearchType.Assistance && !hasGeoData && filters.recipient.length < 3
        return (
            <div
                className={cx(styles.filterTop, {
                    [styles.filterTopColumn]: hasGeoData,
                    ['filterTopColumn']: hasGeoData,
                })}
            >
                <div className={styles.filterTopSearch}>
                    <div
                        className={cx(styles.switchContainer, {
                            [styles.isContracts]: searchType === SearchType.Contracts,
                        })}
                    >
                        <label>
                            <span>ASSISTANCE</span>

                            <input
                                type="checkbox"
                                onChange={onChange}
                                className={styles.switch}
                                checked={searchType === SearchType.Contracts}
                            />

                            <span>CONTRACTS</span>
                        </label>
                    </div>

                    <MapSearch />

                    <div className={styles.recipientSearch}>
                        <RecipientSearch />
                    </div>

                    <SelectedGeoData />
                </div>

                <div className={styles.filterTopFilter}>
                    <div style={{display: "flex"}} className={disableFilters ? styles.disabled : ""}>
                        {searchType === SearchType.Assistance && (
                            <div className={styles.typeOfLoan}>
                                <Popover
                                    badgeValue={filters.type ? filters.type.split(',').length : 0}
                                    label="TYPE OF AWARD"
                                    smallPopover
                                >
                                    {() => (
                                        <AwardsDropdown
                                            allOnEmpty={false}
                                            showSelectAll={false}
                                            value={filters.type ? filters.type.split(',') : []}
                                            options={availableTypes}
                                            onChange={(selectedAwards: any) => {
                                                if (!selectedAwards.length) {
                                                    selectedAwards = '';
                                                } else if (selectedAwards.length > 1) {
                                                    selectedAwards = selectedAwards.join(',');
                                                } else {
                                                    selectedAwards = selectedAwards[0];
                                                }
                                                dispatch(
                                                    setFilters({
                                                        type: selectedAwards,
                                                    } as SpendingsFilters)
                                                );
                                            }}
                                        />
                                    )}
                                </Popover>
                            </div>
                        )}

                        <div
                            className={cx(styles.industry, {
                                [styles.industryNoBorder]: searchType === SearchType.Contracts,
                            })}
                        >
                            <SelectIndustryStyles />
                        </div>

                        <div className={styles.moreFilter}>
                            <SelectMoreFiltersStyles />

                            {totalAdvancedFilters !== 0 && (
                                <span className={cx('badge', 'badge-red', styles.badges)}>
                                    {totalAdvancedFilters}
                                </span>
                            )}
                        </div>

                    </div>

                    <button className={styles.clearAllFilters} onClick={onClear}>
                        <span className="icon iconDelete"></span>Clear all
                    </button>

                    <Button cls="btn-primary" onClick={onApply}>
                        Apply
                    </Button>
                </div>
            </div>
        );
    }
);

export default FilterTop;
