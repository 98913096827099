import Chart from 'react-apexcharts';
import React, { useEffect } from 'react';
import ChartSwitch from '../ChartSwitch';
import {GeoDataReducerState, setGeoData} from '../../../reducers/GeoData';
import { defaultScatterChartConfig } from '../../../constants';
import {useDispatch, useSelector} from 'react-redux';
import { Reducers } from '../../../reducers';
import {GeoDataType} from "../../../types";

interface Props {
    geoData?: GeoDataReducerState;
}

const nonUS = [
    'American Samoa',
    'Federated States of Micronesia',
    'Guam',
    'Marshall Islands',
    'Northern Mariana Islands',
    'Palau',
    'Puerto Rico',
    'Virgin Islands',
    'Outside U.S. or Undisclosed',
];

const ScatterChart: React.FC<Props> = React.memo(
    ({ geoData }: Props): React.ReactElement => {
        const [chartConfigs, setChartConfigs] = React.useState<any[]>();
        const [chartConfigIndex, setChartConfigIndex] = React.useState<number>(0);
        const { statesData, countiesData, zipData}: any = useSelector(
            ({ mapData }: Reducers) => mapData
        );
        const dispatch = useDispatch();
        const prepareChartConfig = React.useCallback(
            (unemploymentDataset: any[], minoritiesDataset: any[], itemIndex?: number) => {
                const unemploymentChartConfig = JSON.parse(
                    JSON.stringify(defaultScatterChartConfig)
                );
                const minoritiesChartConfig = JSON.parse(JSON.stringify(defaultScatterChartConfig));
                let statesDataForChart = Object.keys(statesData).map((key) => {
                    return { key, data: statesData[key] };
                });
                let countiesDataForChart: any = {};
                if (countiesData) {
                    Object.keys(countiesData).forEach((key1) => {
                        countiesDataForChart[key1] = Object.keys(countiesData[key1]).map((key2) => {
                            return { key: key2, data: countiesData[key1][key2] };
                        });
                    });
                }
                let zipDataForChart: any = {};
                if (zipData) {
                    Object.keys(zipData).forEach((key1) => {
                        zipDataForChart[key1] = Object.keys(zipData[key1]).map((key2) => {
                            return { key: key2, data: zipData[key1][key2] };
                        });
                    });
                }
                if (itemIndex !== undefined && itemIndex >= 0) {
                    statesDataForChart.push(statesDataForChart[itemIndex]);
                    statesDataForChart.splice(itemIndex, 1);
                    statesDataForChart = [{} as any, {} as any, ...statesDataForChart];
                    const key = geoData?.state[0];
                    if (geoData?.county[0] && countiesData) {
                        countiesDataForChart[key as string].push(
                            countiesDataForChart[key as string][itemIndex]
                        );
                        countiesDataForChart[key as string] = countiesDataForChart[
                            key as string
                        ].filter((el: any, index: number) => index !== itemIndex);
                        countiesDataForChart[key as string] = [
                            {} as any,
                            {} as any,
                            ...countiesDataForChart[key as string],
                        ];
                    }
                    if (geoData?.zip && zipData) {
                        zipDataForChart[key as string].push(
                            zipDataForChart[key as string][itemIndex]
                        );
                        zipDataForChart[key as string] = zipDataForChart[key as string].filter(
                            (el: any, index: number) => index !== itemIndex
                        );
                        zipDataForChart[key as string] = [
                            {} as any,
                            {} as any,
                            ...zipDataForChart[key as string],
                        ];
                    }
                } else {
                    if (statesData) {
                        statesDataForChart = [{} as any, {} as any, ...statesDataForChart];
                    }
                    if (countiesData) {
                        countiesDataForChart = [];
                    }
                    if (countiesData) {
                        zipDataForChart = [];
                    }
                }
                if (!geoData?.zip && !geoData?.county[0]) {
                    const indexesWithoutNonUs: number[] = [];
                    statesDataForChart.forEach((state, index) => {
                        if (!state.data || !nonUS.includes(state.data.name)) {
                            indexesWithoutNonUs.push(index);
                        }
                    });
                    statesDataForChart = statesDataForChart.filter((el, index) => {
                        return indexesWithoutNonUs.includes(index);
                    });
                    minoritiesDataset[0].data = minoritiesDataset[0].data.filter(
                        (el: any, index: number) => {
                            return indexesWithoutNonUs.includes(index);
                        }
                    );
                    unemploymentDataset[0].data = unemploymentDataset[0].data.filter(
                        (el: any, index: number) => {
                            return indexesWithoutNonUs.includes(index);
                        }
                    );
                }
                unemploymentChartConfig.options.popupInfo = {
                    label: 'STATE',
                    statesData: statesDataForChart,
                    geoData,
                };
                minoritiesChartConfig.options.popupInfo = {
                    label: 'STATE',
                    statesData: statesDataForChart,
                    geoData,
                };
                unemploymentChartConfig.options.selectItem = (newGeoData:GeoDataType)=>{
                    dispatch(setGeoData(newGeoData));
                }
                minoritiesChartConfig.options.selectItem = (newGeoData:GeoDataType)=>{
                    dispatch(setGeoData(newGeoData));
                }
                if (geoData?.zip) {
                    unemploymentChartConfig.options.popupInfo = {
                        label: 'ZIP CODE',
                        zipData: zipDataForChart,
                        geoData,
                    };
                    minoritiesChartConfig.options.popupInfo = {
                        label: 'ZIP CODE',
                        zipData: zipDataForChart,
                        geoData,
                    };
                } else if (geoData?.county[0]) {
                    unemploymentChartConfig.options.popupInfo = {
                        label: 'COUNTY',
                        countiesData: countiesDataForChart,
                        geoData,
                    };
                    minoritiesChartConfig.options.popupInfo = {
                        label: 'COUNTY',
                        countiesData: countiesDataForChart,
                        geoData,
                    };
                }
                const minPerCapita = Math.min(
                    ...minoritiesDataset[0].data
                        .filter((el: any, index: number) => index > 1)
                        .map((d: number[]) => d[0])
                );
                const maxPerCapita = Math.max(
                    ...minoritiesDataset[0].data
                        .filter((el: any, index: number) => index > 1)
                        .map((d: number[]) => d[0])
                );
                let minMinority = Math.min(
                    ...minoritiesDataset[0].data
                        .filter((el: any, index: number) => index > 1)
                        .map((d: number[]) => d[1])
                );
                let maxMinority = Math.max(
                    ...minoritiesDataset[0].data
                        .filter((el: any, index: number) => index > 1)
                        .map((d: number[]) => d[1])
                );
                const minUnemployment = Math.min(
                    ...unemploymentDataset[0].data
                        .filter((el: any, index: number) => index > 1)
                        .map((d: number[]) => d[1])
                );
                const maxUnemployment = Math.max(
                    ...unemploymentDataset[0].data
                        .filter((el: any, index: number) => index > 1)
                        .map((d: number[]) => d[1])
                );
                unemploymentDataset[0].data[0] = [minPerCapita, minUnemployment];
                unemploymentDataset[0].data[1] = [maxPerCapita, maxUnemployment];
                unemploymentChartConfig.series = unemploymentDataset;
                unemploymentChartConfig.options.yaxis.max = maxUnemployment + 4;
                unemploymentChartConfig.options.yaxis.min = minUnemployment - 4 > 0 ? minUnemployment - 4 : 0
                unemploymentChartConfig.options.yaxis.labels.formatter = (value: number) =>
                    value
                        .toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        .concat('%');
                unemploymentChartConfig.options.yaxis.title.text = 'Unemployment Rate (April 2022)';
                minoritiesDataset[0].data[0] = [minPerCapita, minMinority];
                minoritiesDataset[0].data[1] = [maxPerCapita, minMinority];
                minoritiesChartConfig.series = minoritiesDataset;

                maxMinority += maxMinority < 96 ? 4 : 0

                minoritiesChartConfig.options.yaxis.max = maxMinority;
                minoritiesChartConfig.options.yaxis.min = minMinority - 4 > 0 ? minMinority - 4 : 0
                minoritiesChartConfig.options.yaxis.labels.formatter = (value: number) =>
                    value
                        .toFixed(0)
                        .toString()
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',')
                        .concat('%');

                minoritiesChartConfig.options.yaxis.title.text = 'Minority Population Percentage';
                minoritiesChartConfig.options.yaxis.title.style.color = '#00D1FF';
                minoritiesChartConfig.options.markers.colors = '#00D1FF';
                if (itemIndex !== undefined && itemIndex >= 0) {
                    unemploymentChartConfig.options.markers.discrete.push({
                        seriesIndex: 0,
                        dataPointIndex: unemploymentDataset[0].data.length - 1,
                        fillColor: '#00d1ff',
                        strokeColor: 'transparent',
                        size: 3,
                    });
                    minoritiesChartConfig.options.markers.discrete.push({
                        seriesIndex: 0,
                        dataPointIndex: minoritiesDataset[0].data.length - 1,
                        fillColor: '#ff6e30',
                        strokeColor: 'transparent',
                        size: 3,
                    });
                }
                unemploymentChartConfig.options.chartType = 'unemployment';
                minoritiesChartConfig.options.chartType = 'minorities';
                setChartConfigs([unemploymentChartConfig, minoritiesChartConfig]);
            },
            [geoData, statesData, countiesData, zipData]
        );
        const prepareGraphData = (
            data: any,
            totalKey: string,
            populationKey: string,
            unemploymentKey: string,
            minorityKey: string,
            itemIndex?: number
        ) => {
            const unemploymentDataSet: any[] = Object.keys(data).map((state: string) => {
                const stateData = data[state];
                return [
                    Math.round(stateData[totalKey] / stateData[populationKey]),
                    Math.round(stateData[unemploymentKey]),
                    stateData.name ? stateData.name : state
                ];
            });
            const minPerCapita = Math.min(...unemploymentDataSet.map((i) => i[0]));
            const maxPerCapita = Math.max(...unemploymentDataSet.map((i) => i[0]));
            const minUnemployment = Math.min(...unemploymentDataSet.map((i) => i[1]));

            const minorityDataSet: any[] = Object.keys(data).map((state: string) => {
                const stateData = data[state];
                return [
                    Math.round(stateData[totalKey] / stateData[populationKey]),
                    Math.round(stateData[minorityKey]),
                    stateData.name ? stateData.name : state
                ];
            });
            const minMinority = Math.min(...minorityDataSet.map((i) => i[1]));

            if (itemIndex !== undefined && itemIndex >= 0) {
                //Move the selected item to the end of the array so that it appears on top in the chart
                const highlightedElementUnemployment = unemploymentDataSet[itemIndex];
                const highlightedElementMinority = minorityDataSet[itemIndex];
                unemploymentDataSet.splice(itemIndex, 1);
                minorityDataSet.splice(itemIndex, 1);
                unemploymentDataSet.push(highlightedElementUnemployment);
                minorityDataSet.push(highlightedElementMinority);
            }

            const newUnemploymentDataset = [
                {
                    data: [
                        // Hidden points to define chart's custom boundaries and create offsets at the start and
                        // the end of the scatter chart otherwise the first and the last points define the boundaries
                        [minPerCapita, minUnemployment],
                        [maxPerCapita, minUnemployment],
                        ...unemploymentDataSet,
                    ],
                },
            ];
            const newMinorityDataset = [
                {
                    data: [
                        // Hidden points to define chart's custom boundaries and create offsets at the start and
                        // the end of the scatter chart otherwise the first and the last points define the boundaries
                        [minPerCapita, minMinority],
                        [maxPerCapita, minMinority],
                        ...minorityDataSet,
                    ],
                },
            ];
            prepareChartConfig(newUnemploymentDataset, newMinorityDataset, itemIndex);
        };

        useEffect(() => {
            if (statesData) {
                prepareGraphData(
                    statesData,
                    'total_spending',
                    'population',
                    'unemployment_apr_22',
                    'mp_total'
                );
            }
        }, [statesData]);

        useEffect(() => {
            const state = geoData?.state[0] as string;
            if (geoData?.zip) {
                if (zipData) {
                    if (zipData[state]) {
                        prepareGraphData(
                            zipData[state],
                            'total_spending',
                            'population',
                            'unemployment_apr_22',
                            'mp_total',
                            Object.keys(zipData[state]).findIndex((key) => key === geoData.zip)
                        );
                    }
                }
            } else if (geoData?.county[0] && countiesData) {
                prepareGraphData(
                    countiesData[state],
                    'total_spending',
                    'population',
                    'unemployment_apr_22',
                    'mp_total',
                    Object.keys(countiesData[state]).findIndex((key) => key === geoData.county[0])
                );
            } else if (statesData) {
                prepareGraphData(
                    statesData,
                    'total_spending',
                    'population',
                    'unemployment_apr_22',
                    'mp_total',
                    state ? Object.keys(statesData).findIndex((key) => key === state) : undefined
                );
            }
        }, [geoData, zipData]);


        const setActiveChart = (index: number) => {
            setChartConfigIndex(index);
        };
        return (
            <>
                <ChartSwitch active={chartConfigIndex} chartSwitch={setActiveChart} />
                {
                    chartConfigs ? <Chart
                        options={chartConfigs[chartConfigIndex].options}
                        series={chartConfigs[chartConfigIndex].series}
                        type="line"
                        width="100%"
                    /> :
                        <Chart
                            options={defaultScatterChartConfig.options}
                            series={defaultScatterChartConfig.series}
                            type="line"
                            width="100%"
                        />
                }
            </>
        );
    }
);

export default ScatterChart;
