import React from 'react';
import { PC } from '../../types';
import DefaultHeader from '../Headers';
import styles from './Layout.module.scss';

interface Props {
    Component: PC;
    pageProps?: any;
}

const Layout: React.FC<Props> = React.memo(
    ({ Component, pageProps }: Props): React.ReactElement => {
        const Header = (Component as any).header || DefaultHeader;

        return (
            <div className={styles.container}>
                <Header />

                <Component {...pageProps} />
            </div>
        );
    }
);

export default Layout;
