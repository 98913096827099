import qs from 'qs';
import H from 'history';
import { Reducers } from '../reducers';
import { createSelector } from 'reselect';
import { GeoDataReducerState } from '../reducers/GeoData';
import { SpendingsReducerState } from '../reducers/Spendings';
import { MapDataReducerState } from '../reducers/MapData';

interface Props {
    state?: Reducers;
    location: H.Location;
}

const shareUrlSelector = createSelector(
    ({ location }: Props) => location,
    ({ state }: Props) => state,
    (location: H.Location, state: Reducers | undefined): string => {
        if (location.pathname.indexOf('/table-view') === 0 && state) {
            return spendingsShareUrlSelector(state);
        }

        if (location.pathname.indexOf('/map-view') === 0 && state) {
            return mapShareUrlSelector(state);
        }

        return window.location.href;
    }
);

const spendingsShareUrlSelector = createSelector(
    (state: Reducers) => state.spendings,
    (state: Reducers) => state.geoData,
    (spendings: SpendingsReducerState, geoData: GeoDataReducerState): string => {
        const params: any = {
            ...geoData,
            ...spendings.filters,
            page: spendings.pagination.page,
            perPage: spendings.pagination.perPage,
            sort: spendings.sort,
            columns: spendings.visibleColumns,
        };
        Object.keys(params).forEach((key: string) => {
            if (Array.isArray(params[key])) {
                if (params[key].filter((value: string | number) => value).length === 0) {
                    delete params[key];
                }
            } else if (!params[key]) {
                delete params[key];
            }
        });
        return `${window.location.origin}${window.location.pathname}?${qs.stringify(params)}`;
    }
);

const mapShareUrlSelector = createSelector(
    (state: Reducers) => state.mapData,
    (state: Reducers) => state.geoData,
    (mapData: MapDataReducerState, geoData: GeoDataReducerState): string => {
        const params: any = {
            ...geoData,
        };

        delete params.selectionData;

        Object.keys(params).forEach((key: string) => {
            if (Array.isArray(params[key])) {
                if (params[key].filter((value: string | number) => value).length === 0) {
                    delete params[key];
                }
            } else if (params[key] === null && params[key] === undefined) {
                delete params[key];
            }
        });

        params.currentMapType = mapData.currentMapType;
        params.currentLayer = mapData.currentLayer;
        if (params.county && !params.county[1]?.includes('County')) {
            params.county = JSON.parse(JSON.stringify(params.county));
            params.county[1] = params.county[1].concat(' County');
        }
        return `${window.location.origin}${window.location.pathname}?${qs.stringify(params)}`;
    }
);

export default shareUrlSelector;
