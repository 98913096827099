export const industries = [
    {
        id: 2,
        name: 'Accommodation and Food Services',
        niceName: 'Accommodation and Food Services',
        shortDescription:
            'The Accommodation and Food Services sector comprises establishments providing customers with lodging and/or preparing meals, snacks, and beverages for immediate consumption. The sector includes both accommodation and food services establishments because the two activities are often combined at the same establishment.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'accommodation-and-food-services',
    },
    {
        id: 3,
        name: 'Administrative and Support and Waste Management and Remediation Services',
        niceName: 'Administrative and Support and Waste Management and Remediation Services',
        shortDescription:
            'The Administrative and Support and Waste Management and Remediation Services sector comprises establishments performing routine support activities for the day-to-day operations of other organizations. These essential activities are often undertaken in-house by establishments in many sectors of the economy. The establishments in this sector specialize in one or more of these support activities and provide these services to clients in a variety of industries and, in some cases, to households. Activities performed include: office administration, hiring and placing of personnel, document preparation and similar clerical services, solicitation, collection, security and surveillance services, cleaning, and waste disposal services.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'administrative-and-support-and-waste-management-and-remediation-services',
    },
    {
        id: 4,
        name: 'Agriculture, Forestry, Fishing and Hunting(not covered in economic census)',
        niceName: 'Agriculture, Forestry, Fishing and Hunting',
        shortDescription:
            'The Agriculture, Forestry, Fishing and Hunting sector comprises establishments primarily engaged in growing crops, raising animals, harvesting timber, and harvesting fish and other animals from a farm, ranch, or their natural habitats. The establishments in this sector are often described as farms, ranches, dairies, greenhouses, nurseries, orchards, or hatcheries.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'agriculture-forestry-fishing-and-hunting',
    },
    {
        id: 5,
        name: 'Arts, Entertainment, and Recreation',
        niceName: 'Arts, Entertainment, and Recreation',
        shortDescription:
            'The Arts, Entertainment, and Recreation sector includes a wide range of establishments that operate facilities or provide services to meet varied cultural, entertainment, and recreational interests of their patrons. This sector comprises (1) establishments that are involved in producing, promoting, or participating in live performances, events, or exhibits intended for public viewing; (2) establishments that preserve and exhibit objects and sites of historical, cultural, or educational interest; and (3) establishments that operate facilities or provide services that enable patrons to participate in recreational activities or pursue amusement, hobby, and leisure-time interests.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'arts-entertainment-and-recreation',
    },
    {
        id: 6,
        name: 'Construction',
        niceName: 'Construction',
        shortDescription:
            'The Construction sector comprises establishments primarily engaged in the construction of buildings or engineering projects (e.g., highways and utility systems). Establishments primarily engaged in the preparation of sites for new construction and establishments primarily engaged in subdividing land for sale as building sites also are included in this sector.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'construction',
    },
    {
        id: 7,
        name: 'Educational Services',
        niceName: 'Educational Services',
        shortDescription:
            'The Educational Services sector comprises establishments that provide instruction and training in a wide variety of subjects. This instruction and training is provided by specialized establishments, such as schools, colleges, universities, and training centers. These establishments may be privately owned and operated for profit or not for profit, or they may be publicly owned and operated. They may also offer food and/or accommodation services to their students.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'educational-services',
    },
    {
        id: 8,
        name: 'Finance and Insurance',
        niceName: 'Finance and Insurance',
        shortDescription:
            'The Finance and Insurance sector comprises establishments primarily engaged in financial transactions (transactions involving the creation, liquidation, or change in ownership of financial assets) and/or in facilitating financial transactions. There are three principal types of activities for the sector. Raising funds by taking deposits and/or issuing securities and, in the process, incurring liabilities. Pooling of risk by underwriting insurance and annuities. Providing specialized services facilitating or supporting financial intermediation, insurance, and employee benefit programs.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'finance-and-insurance',
    },
    {
        id: 9,
        name: 'Health Care and Social Assistance',
        niceName: 'Health Care and Social Assistance',
        shortDescription:
            'The Health Care and Social Assistance sector comprises establishments providing health care and social assistance for individuals. The sector includes both health care and social assistance because it is sometimes difficult to distinguish between the boundaries of these two activities. The industries in this sector are arranged on a continuum starting with establishments providing medical care exclusively, continuing with those providing health care and social assistance, and finally finishing with those providing only social assistance. Establishments in this sector deliver services by trained professionals.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'health-care-and-social-assistance',
    },
    {
        id: 10,
        name: 'Information',
        niceName: 'Information',
        shortDescription:
            'The Information sector comprises establishments engaged in the following processes: (a) producing and distributing information and cultural products, (b) providing the means to transmit or distribute these products as well as data or communications, and(c) processing data. The main components of this sector are the publishing industries, including software publishing, and both traditional publishing and publishing exclusively on the Internet; the motion picture and sound recording industries; the broadcasting industries, including traditional broadcasting and broadcasting exclusively over the Internet; the telecommunications industries; and Web search portals, data processing industries, and the information services industries.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'information',
    },
    {
        id: 11,
        name: 'Management of Companies and Enterprises',
        niceName: 'Management of Companies and Enterprises',
        shortDescription:
            'The Management of Companies and Enterprises sector comprises (1) establishments that hold the securities of (or other equity interests in) companies and enterprises for the purpose of owning a controlling interest or influencing management decisions or (2) establishments (except government establishments) that administer, oversee, and manage establishments of the company or enterprise and that normally undertake the strategic or organizational planning and decision-making role of the company or enterprise. Establishments that administer, oversee, and manage may hold the securities of the company or enterprise.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'management-of-companies-and-enterprises',
    },
    {
        id: 12,
        name: 'Manufacturing',
        niceName: 'Manufacturing',
        shortDescription:
            "The Manufacturing sector comprises establishments engaged in the mechanical, physical, or chemical transformation of materials, substances, or components into new products. The assembling of component parts of manufactured products is considered manufacturing, except in cases where the activity is appropriately classified in Construction. Establishments in the Manufacturing sector are often described as plants, factories, or mills and characteristically use power-driven machines and material handling equipment. However, establishments that transform materials or substances into new products by hand or in the worker's home and those engaged in selling to the general public products made on the same premises from which they are sold, such as bakeries, candy stores, and custom tailors, may also be included in this sector.",
        longDescription:
            "",
        singleColumn: true,
        slug: 'manufacturing',
    },
    {
        id: 13,
        name: 'Mining, Quarrying, and Oil and Gas Extraction',
        niceName: 'Mining, Quarrying, and Oil and Gas Extraction',
        shortDescription:
            'The Mining, Quarrying, and Oil and Gas Extraction sector comprises establishments that extract naturally occurring mineral solids, such as coal and ores; liquid minerals, such as crude petroleum; and gases, such as natural gas. The term mining is used in the broad sense to include quarrying, well operations, beneficiating (e.g., crushing, screening, washing, and flotation), and other preparation customarily performed at the mine site, or as a part of mining activity.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'mining-quarrying-and-oil-and-gas-extraction',
    },
    {
        id: 14,
        name: 'Other Services (except Public Administration)',
        niceName: 'Other Services (except Public Administration)',
        shortDescription:
            'The Other Services (except Public Administration) sector comprises establishments engaged in providing services not specifically provided for elsewhere in the classification system.  Establishments in this sector are primarily engaged in activities such as equipment and machinery repairing, promoting or administering religious activities, grantmaking, advocacy, and providing dry cleaning and laundry services, personal care services, death care services, pet care services, photofinishing services, temporary parking services, and dating services.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'other-services',
    },
    {
        id: 15,
        name: 'Professional, Scientific, and Technical Services',
        niceName: 'Professional, Scientific, and Technical Services',
        shortDescription:
            'The Professional, Scientific, and Technical Services sector comprises establishments that specialize in performing professional, scientific, and technical activities for others. These activities require a high degree of expertise and training. The establishments in this sector specialize according to expertise and provide these services to clients in a variety of industries and, in some cases, to households. Activities performed include: legal advice and representation; accounting, bookkeeping, and payroll services; architectural, engineering, and specialized design services; computer services; consulting services; research services; advertising services; photographic services; translation and interpretation services; veterinary services; and other professional, scientific, and technical services.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'professional-scientific-and-technical-services',
    },
    {
        id: 16,
        name: 'Public Administration(not covered in economic census)',
        niceName: 'Public Administration',
        shortDescription:
            'The Public Administration sector consists of establishments of federal, state, and local government agencies that administer, oversee, and manage public programs and have executive, legislative, or judicial authority over other institutions within a given area. These agencies also set policy, create laws, adjudicate civil and criminal legal cases, and provide for public safety and for national defense. In general, government establishments in the Public Administration sector oversee governmental programs and activities that are not performed by private establishments. Establishments in this sector typically are engaged in the organization and financing of the production of public goods and services, most of which are provided for free or at prices that are not economically significant.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'public-administration',
    },
    {
        id: 17,
        name: 'Real Estate and Rental and Leasing',
        niceName: 'Real Estate and Rental and Leasing',
        shortDescription:
            'The Real Estate and Rental and Leasing sector comprises establishments primarily engaged in renting, leasing, or otherwise allowing the use of tangible or intangible assets, and establishments providing related services. The major portion of this sector comprises establishments that rent, lease, or otherwise allow the use of their own assets by others. The assets may be tangible, as is the case of real estate and equipment, or intangible, as is the case with patents and trademarks. This sector also includes establishments primarily engaged in managing real estate for others, selling, renting and/or buying real estate for others, and appraising real estate. Excluded from this sector are establishments primarily engaged in renting or leasing equipment with operators. Such establishments are classified in other subsectors depending on the nature of the services provided(e.g., transportation, construction, agriculture).',
        longDescription:
            '',
        singleColumn: true,
        slug: 'real-estate-and-rental-and-leasing',
    },
    {
        id: 18,
        name: 'Retail Trade',
        niceName: 'Retail Trade',
        shortDescription:
            'The Retail Trade sector comprises establishments engaged in retailing merchandise, generally without transformation, and rendering services incidental to the sale of merchandise. The retailing process is the final step in the distribution of merchandise; retailers are, therefore, organized to sell merchandise in small quantities to the general public. This sector comprises two main types of retailers: store and non-store retailers.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'retail-trade',
    },
    {
        id: 19,
        name: 'Transportation and Warehousing',
        niceName: 'Transportation and Warehousing',
        shortDescription:
            'The Transportation and Warehousing sector includes industries providing transportation of passengers and cargo, warehousing and storage for goods, scenic and sightseeing transportation, and support activities related to modes of transportation. Establishments in these industries use transportation equipment or transportation related facilities as a productive asset. The type of equipment depends on the mode of transportation. The modes of transportation are air, rail, water, road, and pipeline.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'transportation-and-warehousing',
    },
    {
        id: 20,
        name: 'Unclassified Establishments',
        niceName: 'Unclassified Establishments',
        shortDescription:
            'Unclassified Establishments includes businesses and other entities which cannot be classified into any other industry sector typically due to a lack of data on an individual entity and/or because of wide variation in the purpose of a government program and the types of entities receiving funds from those programs.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'unclassified-establishments',
    },
    {
        id: 21,
        name: 'Utilities',
        niceName: 'Utilities',
        shortDescription:
            'The Utilities sector comprises establishments engaged in the provision of the following utility services: electric power, natural gas, steam supply, water supply, and sewage removal. Within this sector, the specific activities associated with the utility services provided vary by utility: electric power includes generation, transmission, and distribution; natural gas includes distribution; steam supply includes provision and/or distribution; water supply includes treatment and distribution; and sewage removal includes collection, treatment, and disposal of waste through sewer systems and sewage treatment facilities.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'utilities',
    },
    {
        id: 22,
        name: 'Wholesale Trade',
        niceName: 'Wholesale Trade',
        shortDescription:
            'The Wholesale Trade sector comprises establishments engaged in wholesaling merchandise, generally without transformation, and rendering services incidental to the sale of merchandise.  The merchandise described in this sector includes the outputs of agriculture, mining, manufacturing, and certain information industries, such as publishing. Wholesalers sell merchandise to other businesses and normally operate from a warehouse or office. These warehouses and offices are characterized by having little or no display of merchandise. In addition, neither the design nor the location of the premises is intended to solicit walk-in traffic.',
        longDescription:
            '',
        singleColumn: true,
        slug: 'wholesale-trade',
    },
];

export const industriesId = 21;
