import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../reducers';
import { PC } from '../../types';
import SidebarTabs from '../../components/SidebarTabs';
import MapContainer from '../../components/MapContainer';
import SpendingsHeader from '../../components/Headers/SpendingsHeader';
import { fetchCountiesData, fetchStatesData, fetchZipData } from '../../reducers/MapData';
import MapEarth from '../../components/MapContainer/MapEarth';
import MapSearch from '../../components/MapContainer/MapSearch';

//import SelectIndustryStyles from '../../components/Filter/SelectIndustryStyles';
//import cx from 'classnames';
import styles from './MapView.module.scss';

const SpendingsMapView: PC = React.memo(() => {
    const geoData = useSelector((state: Reducers) => state.geoData);
    const dispatch = useDispatch();
    const { zipData }: any = useSelector(({ mapData }: Reducers) => mapData);

    React.useEffect(() => {
        if (geoData.zip && (!zipData || !zipData[geoData.state[0]])) {
            dispatch(fetchZipData(geoData.state[0]));
        }
    }, [geoData.zip]);

    React.useEffect(() => {
        dispatch(fetchStatesData());
        dispatch(fetchCountiesData());
    }, [dispatch]);

    return (
        <>
            <div className="bg-circles bg-space bg-overwflow">
                <div className="scroll-overflow">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4">
                                <p className="left-info">
                                    The left panel updates on user selection
                                </p>
                                <div className="scroll-sidebar">
                                    <SidebarTabs geoData={geoData} />
                                </div>
                            </div>

                            <div className="col-lg-8 pt-3">
                                <div
                                    style={{ height: window.innerHeight - 110 - 30 }}
                                    className="row"
                                >
                                    {geoData.territory ? (
                                        <div>
                                            <MapEarth />
                                            <div className={styles.mapSearchContainer}>
                                                <MapSearch />
                                            </div>
                                        </div>
                                    ) : (
                                        <div className="col">
                                            <MapContainer geoData={geoData} />
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
});

SpendingsMapView.header = SpendingsHeader;

export default SpendingsMapView;
