import React from 'react';
import styles from '../MapContainer.module.scss';

interface Props {
    mapZoomIn: () => void;
    mapZoomOut: () => void;
    refresh: () => void;
}

const MapZoom: React.FC<Props> = React.memo(
    ({ mapZoomIn, mapZoomOut, refresh }: Props): React.ReactElement => (
        <div className={styles.mapControl}>
            <button onClick={refresh} className={styles.mapRefresh}>
                <span className="icon iconRefresh"></span>
            </button>
            <div className={styles.mapZoom}>
                <button onClick={mapZoomIn} className={styles.mapZoomIn}></button>
                <button onClick={mapZoomOut} className={styles.mapZoomOut}></button>
            </div>
        </div>
    )
);

export default MapZoom;
