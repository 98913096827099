import qs from 'qs';
import MapLayer from './MapLayer';
import ReactMapboxGL from '../map';
import MapSearch from './MapSearch';
import React, { useEffect } from 'react';
import { Reducers } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router';
import { LegendBubble } from '../InfoBubbles';
import styles from './MapContainer.module.scss';
import { GeoDataReducerState, setGeoData } from '../../reducers/GeoData';
import { countyFields, stateFields, zipFields } from '../../constants/map';
import { hasGeoDataSelector } from '../../selectors/geoDataSelector';
import { setMapType, setLayer } from '../../reducers/MapData';

const mappedData: { [key: string]: any[] } = {
    states: stateFields,
    counties: countyFields,
    zip: zipFields,
};

interface Props {
    geoData: GeoDataReducerState;
}

const MapContainer: React.FC<Props> = React.memo(
    ({ geoData }: Props): React.ReactElement => {
        const dispatch = useDispatch();
        const location = useLocation();
        const history = useHistory();
        const refs: any = { mapRef: React.useRef(), viewportRef: React.useRef() };
        const { currentMapType, currentLayer }: any = useSelector(
            ({ mapData }: Reducers) => mapData
        );
        const [isMapInit, setIsMapInit] = React.useState<boolean>(false);
        const hasGeoData = useSelector((state: Reducers) => hasGeoDataSelector(state));

        useEffect(() => {
            if (location.search.length) {
                const geoDataFromUrl: any = qs.parse(location.search.replace('?', ''));
                if (geoDataFromUrl.coordinates?.lng) {
                    geoDataFromUrl.coordinates = {
                        lng: parseFloat(geoDataFromUrl.coordinates.lng),
                        lat: parseFloat(geoDataFromUrl.coordinates.lat),
                    };
                }
                if (geoDataFromUrl.currentLayer) {
                    geoDataFromUrl.currentLayer = parseInt(geoDataFromUrl.currentLayer);
                }
                geoDataFromUrl.territory =
                    geoDataFromUrl.territory && geoDataFromUrl.territory === 'true';
                dispatch(setGeoData(geoDataFromUrl));
                if (geoDataFromUrl.currentMapType) {
                    dispatch(setMapType(geoDataFromUrl.currentMapType));
                }
                if (geoDataFromUrl.currentLayer >= 0) {
                    dispatch(setLayer(geoDataFromUrl.currentLayer));
                }
                history.replace({
                    search: '',
                });
            } else {
                if (!currentMapType) {
                    dispatch(setMapType('states'));
                    dispatch(setLayer(0));
                }
            }
        }, []);

        useEffect(() => {
            if (isMapInit) {
                onGeoDataChange();
            }
        }, [geoData]);

        const onGeoDataChange = React.useCallback(() => {
            const map = refs.mapRef.current.getMap();
            const currentCenter = map.getCenter();
            if (
                !hasGeoData &&
                !geoData.coordinates.lat &&
                (currentCenter.lng !== -93.625 || currentCenter.lat !== 41.5868)
            ) {
                refs.viewportRef.current.viewportUpdateFromParent(3, {
                    lat: 41.5868,
                    lng: -93.625,
                });
                onMapTypeChange('states');
            } else if (hasGeoData) {
                if (geoData.zip) {
                    onMapTypeChange('zip');
                    refs.viewportRef.current.viewportUpdateFromParent(9, geoData?.coordinates);
                } else if (geoData.county[0]) {
                    onMapTypeChange('counties');
                    refs.viewportRef.current.viewportUpdateFromParent(7, geoData?.coordinates);
                } else {
                    onMapTypeChange('states');
                    refs.viewportRef.current.viewportUpdateFromParent(5, geoData?.coordinates);
                }
            }
            setIsMapInit(true);
        }, [hasGeoData, geoData]);

        const onMapTypeChange = React.useCallback(
            (newMapType: string) => {
                dispatch(setMapType(newMapType));
                const map = refs.mapRef.current.getMap();
                Object.keys(mappedData).forEach((mapType) => {
                    mappedData[mapType].forEach((field: any, layerIndex) => {
                        const visibility =
                            mapType === newMapType && layerIndex === currentLayer
                                ? 'visible'
                                : 'none';
                        field.ranges.forEach((range: any, rangeIndex: number) => {
                            map.setLayoutProperty(
                                `${mapType}-layer${layerIndex}-range${rangeIndex}`,
                                'visibility',
                                visibility
                            );
                        });
                        map.setLayoutProperty(
                            `${mapType}-layer${layerIndex}-hover`,
                            'visibility',
                            visibility
                        );
                    });
                });
                if (newMapType === 'states') {
                    map.setLayoutProperty('counties-outline-layer', 'visibility', 'none');
                    map.setLayoutProperty('zip-outline-layer', 'visibility', 'none');
                } else if (newMapType === 'counties') {
                    map.setLayoutProperty('counties-outline-layer', 'visibility', 'visible');
                    map.setLayoutProperty('zip-outline-layer', 'visibility', 'none');
                } else if (newMapType === 'zip') {
                    map.setLayoutProperty('counties-outline-layer', 'visibility', 'none');
                    map.setLayoutProperty('zip-outline-layer', 'visibility', 'visible');
                }
            },
            [currentLayer]
        );

        const onMapLayerChange = React.useCallback(
            (newMapLayer: any) => {
                dispatch(setLayer(newMapLayer.index));
                mappedData[currentMapType].forEach((field, layerIndex) => {
                    const visibility = layerIndex === newMapLayer.index ? 'visible' : 'none';
                    field.ranges.forEach((range: any, rangeIndex: number) => {
                        refs.mapRef.current
                            .getMap()
                            .setLayoutProperty(
                                `${currentMapType}-layer${layerIndex}-range${rangeIndex}`,
                                'visibility',
                                visibility
                            );
                    });
                });
            },
            [currentMapType]
        );

        const changeLegendItemVisibility = React.useCallback(
            (rangeIndex: number) => {
                const map = refs.mapRef.current.getMap();
                const currentVisibility = map.getLayoutProperty(
                    `${currentMapType}-layer${currentLayer}-range${rangeIndex}`,
                    'visibility'
                );
                refs.mapRef.current
                    .getMap()
                    .setLayoutProperty(
                        `${currentMapType}-layer${currentLayer}-range${rangeIndex}`,
                        'visibility',
                        currentVisibility === 'visible' ? 'none' : 'visible'
                    );
            },
            [currentMapType, currentLayer]
        );

        return (
            <div className={styles.mapContainer}>
                {/*<InfoPopupSmall />*/}

                {currentMapType && currentLayer !== undefined && (
                    <ReactMapboxGL
                        onMapTypeChange={onMapTypeChange}
                        onMapLayerChange={onMapLayerChange}
                        onGeoDataChange={onGeoDataChange}
                        mappedData={mappedData}
                        ref={refs}
                    />
                )}

                {currentMapType && currentLayer !== undefined && (
                    <div
                        onMouseEnter={() => refs.viewportRef.current.closePopup()}
                        className={styles.legend}
                    >
                        <LegendBubble
                            mappedData={mappedData}
                            onMapTypeChange={onMapTypeChange}
                            changeLegendItemVisibility={changeLegendItemVisibility}
                        />
                    </div>
                )}

                <div
                    onMouseEnter={() => {
                        refs.viewportRef.current.setPopupData(null);
                    }}
                    className={styles.mapSearch}
                >
                    <MapSearch />
                </div>
                <MapLayer onMapLayerChange={onMapLayerChange} />
            </div>
        );
    }
);

export default MapContainer;
