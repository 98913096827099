import React from 'react';
import { PC } from '../../types';
import Lottie from 'lottie-react-web';
import styles from './NotFoundPage.module.scss';
import cx from 'classnames';
import Footer from '../../components/Footer';

const NotFoundPage: PC = React.memo(() => {
    return (
        <div className={cx('bg-circles', styles.notFoundPage)}>
            <div className={styles.content}>
                <Lottie
                    width="400px"
                    height="200px"
                    options={{
                        animationData: require('./lf30.json'),
                        loop: true,
                    }}
                />

                <h1 className={styles.h1}>Page Not Found</h1>
            </div>
            <Footer />
        </div>
    );
});

export default NotFoundPage;
