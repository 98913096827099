import React, { useEffect } from 'react';
import Chart from 'react-apexcharts';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../reducers';

const defaultConfig = {
    series: [
        {
            name: 'Unemployment rate',
            data: [3.5, 14.8, 10.2, 6.9, 6.3, 6.1, 5.4, 4.6, 4.0, 3.6],
        },
    ],
    options: {
        chart: {
            type: 'line',
            toolbar: {
                show: false,
            },
            zoom: {
                enabled: false
            },
        },
        stroke: {
            width: 3,
            colors: ['#00d1ff']
        },
        grid: {
            borderColor: '#3a3c3e',
            strokeDashArray: 2,
            yaxis: {
                strokeDashArray: 0,
                lines: {
                    show: true,
                },
            },
            xaxis: {
                lines: {
                    show: true,
                },
            },
            padding: {
                left: 10,
                right: 30,
                top: 0,
                bottom: 0,
            },
        },
        legend: {
            show: false,
        },
        dataLabels: {
            enabled: true,
            textAnchor: 'start',
            style: {
                colors: ['#1B1F3A'],
                fontSize: '11px',
                fontFamily: '"Inter", sans-serif',
                fontWeight: '600',
            },
            background: {
                enabled: true,
                foreColor: '#fff',
                padding: 4,
                borderRadius: 2,
                borderWidth: 1,
                borderColor: '#1B1F3A',
                opacity: 1,
            },
            formatter: function (val: number) {
                return `${val}%`;
            },
        },
        tooltip: {
            enabled: false,
        },
        xaxis: {
            categories: [['Jan', '\'20'], ['Apr', '\'20'], ['Jul', '\'20'], ['Oct', '\'20'], ['Jan', '\'21'], ['Apr', '\'21'], ['Jul', '\'21'], ['Oct', '\'21'], ['Jan', '\'22'], ['Apr', '\'22']],
            //max: 8,
            axisTicks: {
                show: false
            },
            axisBorder: {
                show: false,
            },
            labels: {
                show: true,
                style: {
                    colors: '#A4A9BF',
                    align: "left"
                },
            },
        },
        yaxis: {
            min: 0,
            max: 35,
            tickAmount: 5,
            labels: {
                offsetY: 2,
                formatter: function (val: number) {
                    return `${val}%`;
                },
                style: {
                    colors: '#A4A9BF',
                },
            },
        },
    },
};

const LineChart: React.FC = React.memo(
    (): React.ReactElement => {
        const [chartConfig, setChartConfig] = React.useState(defaultConfig);
        const { selectionData }: any = useSelector((state: Reducers) => state.geoData);

        useEffect(() => {
            if (selectionData) {
                const newChartConfig = JSON.parse(JSON.stringify(defaultConfig));
                const jan20 = selectionData.unemployment_jan_20
                    ? selectionData.unemployment_jan_20.toFixed(1)
                    : 0;
                const apr20 = selectionData.unemployment_apr_20
                    ? selectionData.unemployment_apr_20.toFixed(1)
                    : 0;
                const jul20 = selectionData.unemployment_jul_20
                    ? selectionData.unemployment_jul_20.toFixed(1)
                    : 0;
                const oct20 = selectionData.unemployment_oct_20
                    ? selectionData.unemployment_oct_20.toFixed(1)
                    : 0;
                const jan21 = selectionData.unemployment_jan_21
                    ? selectionData.unemployment_jan_21.toFixed(1)
                    : 0;
                const apr21 = selectionData.unemployment_apr_21
                    ? selectionData.unemployment_apr_21.toFixed(1)
                    : 0;
                const jul21 = selectionData.unemployment_jul_21
                    ? selectionData.unemployment_jul_21.toFixed(1)
                    : 0;
                const oct21 = selectionData.unemployment_oct_21
                    ? selectionData.unemployment_oct_21.toFixed(1)
                    : 0;
                const jan22 = selectionData.unemployment_jan_22
                    ? selectionData.unemployment_jan_22.toFixed(1)
                    : 0;
                const apr22 = selectionData.unemployment_apr_22
                    ? selectionData.unemployment_apr_22.toFixed(1)
                    : 0;
                newChartConfig.series[0].data = [jan20, apr20, jul20, oct20, jan21, apr21, jul21, oct21, jan22, apr22];
                setChartConfig(newChartConfig);
            } else {
                setChartConfig(defaultConfig);
            }
        }, [selectionData]);

        return (
            <div className="unemployment-line-chart">
                <Chart
                    options={chartConfig.options}
                    series={chartConfig.series}
                    type="line"
                    width="100%"
                    height={240}
                />
            </div>
        );
    }
);

export default LineChart;
