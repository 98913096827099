import React from 'react';
import { OptionType } from '../../../types';
import styles from './AwardsDropdown.module.scss';

const chunk = require('lodash/chunk');

export type ValueType = string | string[] | number | number[];

interface Props {
    value: ValueType;
    allOnEmpty?: boolean;
    options: OptionType[];
    showSelectAll?: boolean;
    onChange: (value: ValueType) => void;
}

const AwardsDropdown: React.FC<Props> = React.memo(
    ({ options, showSelectAll, ...props }: Props): React.ReactElement => {
        const [value, setValue] = React.useState<any>(props.value);

        const isChecked = React.useCallback(
            (itemValue: number | string): boolean => {
                if (value && Array.isArray(value)) {
                    return value.indexOf(itemValue) !== -1;
                }

                return value === itemValue;
            },
            [value]
        );

        const onChange = React.useCallback(
            (itemValue: number | string): void => {
                let newValue: ValueType = value;

                if (isChecked(itemValue)) {
                    newValue = Array.isArray(value)
                        ? value.filter((value: number | string) => value !== itemValue)
                        : '';
                } else {
                    newValue = Array.isArray(value) ? [...value, itemValue] : itemValue;
                }

                setValue(newValue);
                props.onChange(newValue);
            },
            [value, setValue, props.onChange]
        );

        const allChecked = Array.isArray(value) && value.length === options.length;

        const toggleAll = React.useCallback((): void => {
            const newValue: any = allChecked
                ? []
                : options.map((option: OptionType) => option.value);

            setValue(newValue);
            props.onChange(newValue);
        }, [options, allChecked, setValue, props.onChange]);

        React.useEffect(() => {
            if (props.allOnEmpty && Array.isArray(value) && value.length === 0) {
                toggleAll();
            }
        }, [props.allOnEmpty, value, toggleAll]);

        return (
            <div className={styles.dropdown}>
                {chunk(
                    [showSelectAll ? { value: 'select-all' } : null, ...options].filter((e) => e)
                ).map((options: OptionType[], index: number) => (
                    <div className={styles.dropdownBlock} key={index}>
                        {options.map(({ value, label }: OptionType) => {
                            return (
                                <React.Fragment key={value}>
                                    {value === 'select-all' && (
                                        <div onClick={toggleAll} className={styles.dropdownItem}>
                                            <input
                                                type="checkbox"
                                                checked={allChecked}
                                                onChange={toggleAll}
                                                className={styles.checkboxSelectAll}
                                            />

                                            <label>
                                                <strong>Select All</strong>
                                            </label>
                                        </div>
                                    )}

                                    {value !== 'select-all' && (
                                        <div
                                            onClick={() => onChange(value)}
                                            className={styles.dropdownItem}
                                        >
                                            <input
                                                value={value}
                                                type="checkbox"
                                                checked={isChecked(value)}
                                                onChange={() => onChange(value)}
                                            />

                                            <label>{label}</label>
                                        </div>
                                    )}
                                </React.Fragment>
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    }
);

export default AwardsDropdown;
