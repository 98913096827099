import React from 'react';
import cx from 'classnames';
import Parser from 'html-react-parser';
import styles from './FaqItem.module.scss';

export interface FAQItemType {
    id: number;
    question: string;
    answer: string;
    longText: boolean;
    categoryId: number;
}

interface Props {
    item: FAQItemType;
    onClick: (e: any) => void;
    isActive: boolean;
}

const FaqItem: React.FC<Props> = React.memo(
    ({ item, onClick, isActive }: Props): React.ReactElement => (
        <div className={cx(styles.faqItem, { [styles.faqItemOpen]: isActive })}>
            <div className={styles.faqItemWrapper}>
                <div onClick={onClick} className={styles.faqItemQuestion}>
                    <div className={styles.faqItemQuestionSpacer}>{item.question}</div>
                    <div onClick={onClick} className={styles.faqItemTogglerIcon} />
                </div>

                <div
                    className={cx(styles.faqItemAnswer, {
                        [styles.faqItemAnswerLong]: item.longText,
                    })}
                >
                    <div>{Parser(item.answer)}</div>
                </div>
            </div>

            <div onClick={onClick} className={styles.faqItemToggler} />
        </div>
    )
);

export default FaqItem;
