import React from 'react';
import cx from 'classnames';
import { PC } from '../../types';
import styles from './Tool.module.scss';
import 'swiper/swiper.scss';
import 'swiper/components/effect-fade/effect-fade.scss';
import 'swiper/components/navigation/navigation.scss';

import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, {
    Controller,
    Virtual,
    Mousewheel,
    Navigation,
    EffectFade,
    HashNavigation,
    Thumbs,
} from 'swiper';
import Footer from '../../components/Footer';

SwiperCore.use([Thumbs, Controller, Navigation, Mousewheel, Virtual, EffectFade, HashNavigation]);

const Tool: PC = React.memo(() => {
    const [thumbsSwiper, setThumbsSwiper] = React.useState<any>(null);

    return (
        <>
            <div className="bg-circles bg-line bg-overwflow">
                <div className="scroll-content">
                    <div className={cx('container', styles.toolPage)}>
                        <div className="row no-gutters align-items-stretch flex-grow">
                            <div className={cx('col-md-3', styles.toolPageLeft)}>
                                <div className={styles.Menu}>
                                    <h2 className={styles.MenuTitle}>Tool</h2>
                                    <Swiper
                                        id="thumbs"
                                        tag="section"
                                        wrapperTag="ul"
                                        onSwiper={setThumbsSwiper}
                                        slidesPerView={4}
                                        direction="vertical"
                                        slideActiveClass={styles.active}
                                        allowTouchMove={false}
                                    >
                                        <SwiperSlide tag="li">
                                            <strong>
                                                <span className="icon iconMap"></span>Map Layer
                                            </strong>
                                        </SwiperSlide>
                                        <SwiperSlide tag="li">
                                            <strong>
                                                <span className="icon iconFilter"></span>Filters
                                            </strong>
                                        </SwiperSlide>
                                        <SwiperSlide tag="li">
                                            <strong>
                                                <span className="icon iconDownload"></span>Downloads
                                            </strong>
                                        </SwiperSlide>
                                        <SwiperSlide tag="li">
                                            <strong>
                                                <span className="icon iconInformation"></span>
                                                Additional Information
                                            </strong>
                                        </SwiperSlide>
                                    </Swiper>
                                </div>
                            </div>
                            <div className={cx('col-md-9', styles.toolPageContent)}>
                                <div className={styles.toolPageContentInner}>
                                    <Swiper
                                        className={styles.slide}
                                        slidesPerView={1}
                                        thumbs={{
                                            swiper: thumbsSwiper,
                                            slideThumbActiveClass: styles.active,
                                        }}
                                        watchSlidesVisibility
                                        watchSlidesProgress
                                        effect="fade"
                                        hashNavigation
                                        fadeEffect={{ crossFade: true }}
                                        navigation={{
                                            nextEl: '.button-next',
                                            prevEl: '.button-prev',
                                            disabledClass: styles.disable,
                                        }}
                                    >
                                        <SwiperSlide data-hash="map-layer">
                                            <h1 className={styles.title}>
                                                What's possible with the POGO tool?
                                            </h1>
                                            <div className={styles.imgBlock}>
                                                <img
                                                    className="img-fluid"
                                                    src="/img/tool/map-layer.jpg"
                                                    alt="Map Layer"
                                                />
                                            </div>
                                            <div className={styles.slideCaption}>
                                                <div className={styles.slideNumber}>01</div>

                                                <div className={styles.slideInfo}>
                                                    <h3 className={styles.slideTitle}>Map Layer</h3>

                                                    <p className={styles.slideText}>
                                                        Use the map feature to quickly select
                                                        geographic regions of interest, and then
                                                        drill down for more specific information.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide data-hash="filters">
                                            <h1 className={styles.title}>
                                                What's possible with the POGO tool?
                                            </h1>
                                            <div className={styles.imgBlock}>
                                                <img
                                                    className="img-fluid"
                                                    src="/img/tool/filters.jpg"
                                                    alt="Downloads"
                                                />
                                            </div>
                                            <div className={styles.slideCaption}>
                                                <div className={styles.slideNumber}>02</div>

                                                <div className={styles.slideInfo}>
                                                    <h3 className={styles.slideTitle}>Filters</h3>

                                                    <p className={styles.slideText}>
                                                        On several screens users can track data over
                                                        time by dragging the indicator bars backward
                                                        or forward in time.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide data-hash="downloads">
                                            <h1 className={styles.title}>
                                                What's possible with the POGO tool?
                                            </h1>
                                            <div className={styles.imgBlock}>
                                                <img
                                                    className="img-fluid"
                                                    src="/img/tool/downloads.jpg"
                                                    alt="Downloads"
                                                />
                                            </div>
                                            <div className={styles.slideCaption}>
                                                <div className={styles.slideNumber}>03</div>

                                                <div className={styles.slideInfo}>
                                                    <h3 className={styles.slideTitle}>Downloads</h3>

                                                    <p className={styles.slideText}>
                                                        Lorem ipsum dolor sit amet, consectetur
                                                        adipiscing elit, sed do eiusmod tempor
                                                        incididunt ut labore et dolore magna aliqua.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <SwiperSlide data-hash="additional-information">
                                            <h1 className={styles.title}>
                                                What's possible with the POGO tool?
                                            </h1>
                                            <div className={styles.imgBlock}>
                                                <img
                                                    className="img-fluid"
                                                    src="/img/tool/additional-information.jpg"
                                                    alt="Additional Information"
                                                />
                                            </div>
                                            <div className={styles.slideCaption}>
                                                <div className={styles.slideNumber}>04</div>

                                                <div className={styles.slideInfo}>
                                                    <h3 className={styles.slideTitle}>
                                                        Additional Information
                                                    </h3>

                                                    <p className={styles.slideText}>
                                                        Transaction details include everything the
                                                        government has made public.
                                                    </p>
                                                </div>
                                            </div>
                                        </SwiperSlide>
                                        <div className={styles.navTool}>
                                            <button
                                                className={cx('button-prev', styles.navToolLeft)}
                                            >
                                                <span className="icon iconChevronLeft"></span>
                                            </button>
                                            <button
                                                className={cx('button-next', styles.navToolRight)}
                                            >
                                                <span className="icon iconChevronRight"></span>
                                            </button>
                                        </div>
                                    </Swiper>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Footer />
                </div>
            </div>
        </>
    );
});

export default Tool;
