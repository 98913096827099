import { SearchType } from './../constants';
import axios from 'axios';

export default {
    fetchAgencies(type: string) {
        return axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/agencies?type=${
                    type === 'assistance' ? type : 'contract'
                }`
            )
            .then(({ data }) => data);
    },

    fetchPrograms() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/programs`).then(({ data }) => data);
    },

    fetchIndustries(type: SearchType) {
        return axios
            .get(
                `${process.env.REACT_APP_API_ENDPOINT}/industries?type=${type.toLocaleLowerCase()}`
            )
            .then(({ data }) => data);
    },
};
