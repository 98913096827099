import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';
import { useDispatch } from 'react-redux';
import { GeoDataType } from '../../../types';
import styles from './MapSearch.module.scss';
import { setGeoData } from '../../../reducers/GeoData';
import { debounce } from 'lodash';
import useClickOutside from '../../../hooks/useClickOutside';
import { getGeoResult } from '../../../utils/map';

export type SetGeoData = (geoData: GeoDataType) => void;

interface Props {
    selectionCallback?: SetGeoData;
}

interface SearchOption {
    name: string;
    fips_code: string;
    state_code: string;
    zip: string;
}

const MapSearch: React.FC<Props> = React.memo(
    ({ selectionCallback }: Props): React.ReactElement => {
        const dispatch = useDispatch();
        const [loading, setLoading] = React.useState<boolean>(false);
        const [value, setValue] = React.useState<string>('');
        const ref = React.useRef<HTMLDivElement>(null as any);
        const [isOpened, setOpened] = useClickOutside(ref);
        const [suggestions, setSuggestions] = React.useState<any[]>([]);
        const [activeSuggestion, setActiveSuggestion] = React.useState<number>(0);
        let controller = new AbortController();
        let signal = controller.signal;

        const abortFetch = () => {
            controller.abort();
            controller = new AbortController();
            signal = controller.signal;
        };

        const onSearch = (input: any) => {
            abortFetch();
            const urlToFetch = `${process.env.REACT_APP_API_ENDPOINT}/locations?q=${input.target.value}`;
            setLoading(true);
            fetch(urlToFetch, {
                method: 'get',
                signal,
            })
                .then((response) => response.json())
                .then((response: any) => {
                    setLoading(false);
                    const newSuggestions: any[] = [];
                    response.result.states.forEach((state: SearchOption) => {
                        newSuggestions.push({
                            name: `${state.name},  USA`,
                            address: `${state.name}, USA`,
                            data: state,
                        });
                    });
                    response.result.counties.forEach((county: SearchOption) => {
                        newSuggestions.push({
                            name: `${county.name}, ${county.state_code}`,
                            address: `${county.name}, ${county.state_code}, USA`,
                            data: county,
                        });
                    });
                    response.result.zipcodes.forEach((zip: SearchOption) => {
                        newSuggestions.push({
                            name: `${zip.zip}, ${zip.state_code}`,
                            address: `${zip.zip}, ${zip.state_code}, USA`,
                            data: zip,
                        });
                    });
                    setActiveSuggestion(0);
                    setSuggestions(newSuggestions);
                })
                .catch(() => {});
        };
        const searchHandler = useCallback(
            debounce((val) => onSearch(val), 400),
            []
        );

        const onInputChange = useCallback((event: any) => {
            setValue(event.target.value);
            if (!event.target.value) {
                setTimeout(() => {
                    setSuggestions([]);
                    abortFetch();
                    setLoading(false);
                }, 450);
            } else {
                searchHandler(event);
            }
        }, []);

        const handleSelect = (address: any) => {
            setSuggestions([]);
            const geoResult = getGeoResult(address.data);
            if (selectionCallback) {
                selectionCallback(geoResult);
            }
            dispatch(setGeoData(geoResult));

            setValue('');
        };

        return (
            <div ref={ref} className={styles.mapSearch}>
                <div className={styles.mapSearchWrap}>
                    <input
                        onFocus={() => setOpened(true)}
                        value={value}
                        onChange={onInputChange}
                        placeholder="SEARCH BY STATE, COUNTY, ZIP CODE"
                        className={styles.mapSearchInput}
                    />
                    <span className={cx('icon', 'iconSearch')}></span>
                </div>
                <div
                    onMouseLeave={() => {
                        setActiveSuggestion(-1);
                    }}
                    className={styles.mapSearchResults}
                >
                    {isOpened && loading && (
                        <div className={styles.mapSearchLoading}>Loading...</div>
                    )}
                    {isOpened &&
                        suggestions.map((suggestion, index) => {
                            const className =
                                index === activeSuggestion
                                    ? cx(
                                          styles.mapSearchSuggestion,
                                          styles.mapSearchSuggestionActive
                                      )
                                    : styles.mapSearchSuggestion;
                            return (
                                <React.Fragment key={index}>
                                    <div
                                        onClick={() => handleSelect(suggestion)}
                                        onMouseEnter={() => {
                                            setActiveSuggestion(index);
                                        }}
                                        className={className}
                                    >
                                        <span>{suggestion.name}</span>
                                    </div>
                                </React.Fragment>
                            );
                        })}
                </div>
            </div>
        );
    }
);

export default MapSearch;
