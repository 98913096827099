import { circleRanges, DC_PATH_ELEMENT, filterTypes, tabsData } from '../../constants';
import { State } from '../../types';
import { formatNumber } from '../../utils/formatNumber';
const spendingColors = [
    'rgb(242,253,255)',
    'rgb(204,246,255)',
    'rgb(153,237,255)',
    'rgb(102,227,255)',
    'rgb(59,209,255)',
    'rgb(40,151,190)',
    'rgb(15,73,103)',
    'rgb(8,54,81)',
];

const circlesOffset: { [key: string]: number } = {
    1: 4,
    2: 6,
    3: 12,
    4: 17,
};

export const removeElementsByClassName = (classNames: string[]) => {
    const allElementsToRemove: any[] = [];
    classNames.forEach((classname) => {
        const elements: HTMLCollectionOf<Element> = document.getElementsByClassName(classname);
        allElementsToRemove.push(...Array.prototype.slice.call(elements));
    });
    allElementsToRemove.forEach((element) => {
        element.parentNode.removeChild(element);
    });
};

export const addDistrictOfColumbia = (color: string, path: any) => {
    const oldPath: any = document.getElementById('custom_dc');
    if (oldPath) {
        oldPath.parentNode.removeChild(oldPath);
    }
    const newPath: any = document.createElementNS('http://www.w3.org/2000/svg', 'path');
    newPath.id = 'custom_dc';
    newPath.setAttribute('d', DC_PATH_ELEMENT.data); //Set path's data
    newPath.style.fill = color;
    newPath.setAttribute('stroke', '#111213!important');
    newPath.setAttribute('stroke-width', '1px!important');
    newPath.setAttribute('transform', 'translate(795, 243)'); //Set path's data
    const title: any = document.createElementNS('http://www.w3.org/2000/svg', 'title');
    title.innerHTML = "Washington, DC";
    newPath.appendChild(title);
    path.appendChild(newPath);
};

export const addStateColorBasedOnSpending = (pathData: State, path: any, activeTab: string) => {
    const spendingRange = tabsData[activeTab].legend.find(
        (spendingLevel: number, index: number) => {
            if (index !== tabsData[activeTab].legend.length - 1) {
                return (
                    (pathData?.[activeTab as keyof State] as number) < tabsData[activeTab].legend[index+1] * Math.pow(10, 9)
                );
            } else {
                return true;
            }
        }
    );
    const  color = spendingColors[tabsData[activeTab].legend.indexOf(spendingRange)];
    if (path.nodeName === 'g') {
        addDistrictOfColumbia(color, path);
    } else {
        path.setAttribute('fill', color);
    }
};

export const getBubbleDataAndCircleType = (pathData: State, filterType: string) => {
    let bubbleData: string;
    let circle: number;
    let rangeIndex;
    if (filterType === filterTypes.POPULATION) {
        bubbleData = formatNumber(pathData[filterType as keyof State] as number).toString();
        //Determine which range the user belongs to
        rangeIndex = circleRanges[filterType as string].findIndex(
            (level: any) =>
                parseFloat(bubbleData) >= level.min && parseFloat(bubbleData) <= level.max
        );
        circle = rangeIndex !== -1 ? rangeIndex + 1 : 4;
        if (bubbleData.indexOf('K') !== -1) {
            circle = 1;
            bubbleData = (parseFloat(bubbleData) / 1000).toFixed(1).concat('M');
        }
    } else {
        const value = pathData[filterType as keyof State] as number;
        rangeIndex = (circleRanges as Record<string, object[]>)[filterType as string].findIndex(
            (level: any) => value >= level.min && value <= level.max
        );
        circle = rangeIndex !== -1 ? rangeIndex + 1 : 4;
        bubbleData = value.toFixed(1).concat('%');
    }
    return { bubbleData, circle };
};

export const createTooltipWithCorrectPosition = (
    path: any,
    mapContainer: any,
    mainContainer: any,
    originalSVGHeight: any,
    index: number,
    circle: number,
    scale: number,
    bubbleData: string,
    options?: any
) => {
    const bbox = path.getBBox();
    //Calculate tooltip positions
    const coefficient = mapContainer.clientHeight / parseInt(originalSVGHeight);
    let left = coefficient * bbox.x + (bbox.width * coefficient) / 2;
    let top = coefficient * bbox.y + (bbox.height * coefficient) / 2;
    /* eslint-disable */
    const statesTooltipHigher = [0, 1, 2, 3, 9, 10, 11, 13, 14, 15, 18, 20, 23, 24, 30, 32, 33, 34, 35, 37, 39, 45, 50,];
    const statesTooltipMuchHigher = [19];
    const statesTooltipMoreToRight = [9, 21];
    const statesTooltipLittleToLeft = [5, 8, 38];
    const statesTooltipMoreToLeft = [3, 7, 11, 12, 17, 19, 20, 22, 23, 29, 33, 41, 45, 48,];
    /* eslint-enable */
    if (statesTooltipMuchHigher.includes(index)) {
        top = top - 13;
    }
    if (statesTooltipHigher.includes(index)) {
        top = top - bbox.height / 2 + bbox.height * 0.3;
    }
    if (statesTooltipMoreToRight.includes(index)) {
        left *= 1.03;
    }
    if (statesTooltipMoreToLeft.includes(index)) {
        left = left - 10;
    }
    if (statesTooltipLittleToLeft.includes(index)) {
        left = left - 5;
    }
    if (index === 50) {
        left = coefficient * bbox.x - 10 - 4 * circle;
        top = coefficient * bbox.y - 4 * circle;
    } else {
        left = left - 10 - circlesOffset[circle];
        top = top - circlesOffset[circle];
    }
    left = left + (mainContainer.clientWidth-mapContainer.clientWidth)/2
    const node = document.createElement('DIV');
    const randomDelay = Math.floor(Math.random() * 15) / 10;
    const style = `transform: scale(${scale}); ${
        options && options.animation
            ? `animation: ${options.animation} 0.4s ease;`
            : `animation: rise ${randomDelay}s ease;`
    }`;
    node.innerHTML = `<div style="${style}" class="circle-container"><img src="/img/circle${circle}.svg" alt="MAP"/><div >${bubbleData}</div ></div>`;
    node.style.position = 'absolute';
    node.style.left = left + 'px';
    node.style.top = top + 'px';
    node.className = 'custom-tooltip';
    mapContainer.appendChild(node);
};
