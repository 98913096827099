import React from 'react';
import cx from 'classnames';
import styles from './FaqMenu.module.scss';

export interface FAQMenuType {
    id: number;
    name: string;
}

interface Props {
    activeCategory: number;
    categories: FAQMenuType[];
    onChange: (id: number) => void;
}

const FaqMenu: React.FC<Props> = React.memo(
    ({ activeCategory, categories, onChange }: Props): React.ReactElement => (
        <div className={styles.faqMenu}>
            <h1>FAQ</h1>

            <div className={styles.faqMenuWrapper}>
                {categories.map((item: FAQMenuType, index) => (
                    <button
                        key={index}
                        onClick={() => onChange(item.id)}
                        className={cx({ [styles.faqMenuActive]: item.id === activeCategory })}
                    >
                        {item.name}
                    </button>
                ))}
            </div>
        </div>
    )
);

export default FaqMenu;
