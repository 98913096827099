import { OptionType } from '../types';
import { Reducers } from '../reducers';
import { createSelector } from '@reduxjs/toolkit';
import { GeoDataReducerState } from '../reducers/GeoData/index';

export const geoDataOptionsSelector = createSelector(
    (state: Reducers) => state.geoData,
    (geoData: GeoDataReducerState): OptionType[] => {
        for (const key of ['zip', 'county', 'state']) {
            const value = geoData[key as keyof GeoDataReducerState];

            if (value && (!Array.isArray(value) || value[0])) {
                const label = (Array.isArray(value) ? value[1] || value[0] : value) as string;

                return [
                    {
                        value: key,
                        label: key !== 'state' ? `${label}, ${geoData.state[0]}` : label,
                    },
                ];
            }
        }

        return [];
    }
);

export const hasGeoDataSelector = createSelector(
    (state: Reducers) => state.geoData,
    (geoData: GeoDataReducerState): boolean =>
        !!(geoData.zip || geoData.county[0] || geoData.state[0])
);
