import React, { useEffect } from 'react';
import cx from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Reducers } from '../../reducers';
import { SearchType } from '../../constants';
import styles from './Transaction.module.scss';
import { SpendingsRow, fetchGJFData } from '../../reducers/Spendings';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import Scrollspy from 'react-scrollspy-ez';
import Button from '../Button';
import CopyToClipboard from 'react-copy-to-clipboard';
import shareUrlSelector from '../../selectors/shareUrlSelector';
import { EmailShareButton, FacebookShareButton, TwitterShareButton } from 'react-share';
import { useLocation } from 'react-router-dom';

const get = require('lodash/get');

export interface TransactionProps {
	transaction: SpendingsRow;
	onClose: () => void;
}

type TabItem = [string, string, string];
type TabsType = [string, [TabItem[]]];

const Transaction: React.FC<TransactionProps> = React.memo(
	({ transaction, onClose }: TransactionProps): React.ReactElement => {
		const isPPP = transaction?.program_id && transaction.program_id && transaction.program_id === 303;
		const dispatch = useDispatch();
		const assistanceTabs: TabsType[] = [
			[
				'Recipient',
				[
					[
						['Recipient Name', 'recipient', ''],
						['Recipient DUNS', 'recipient_duns', ''],
						['Recipient Parent Name', 'parent_name', ''],
						['Parent DUNS', 'recipient_parent_duns', ''],
						['Industry Sector', 'industry', ''],
						['Business Types', 'business_types_description', ''],
					]
				],
			],
			[
				'Accountability_Flags',
				[
					[
						['Match Type', 'match_type', ''],
						['Company', 'company', ''],
						['COVID Stimulus Watch Transaction Details', 'csw_record_link', ''],
						['COVID Stimulus Watch Advanced Search', 'csw_advanced_link', ''],
						['Parent Company', 'parent_name', ''],
						['COVID Stimulus Watch Parent Company Profile', 'csw_parent_link', ''],
						['Sum of Penalties Assessed including for Parent', 'pen_total', ''],
						['Employment-Related Penalties including for Parent', 'empl_pen_amt', 'indented'],
						['Contracting-Related Penalties including for Parent', 'contracting_pen_amt', 'indented'],
						['Environmental, Healthcare and Safety-related Penalties including for Parent', 'environ_pen_amt', 'indented'],
						['Consumer Protection, Financial Misconduct and Unfair Competition-Related Penalties including for Parent', 'consumer_pen_amt', 'indented'],
					]
				],
			],
			[
				'Location',
				[
					[
						['Recipient Address Line 1', 'address', ''],
						['Recipient Address Line 2', 'address2', ''],
						['Recipient City', 'city', ''],
						['Recipient County', 'county', ''],
						['Recipient State', 'state_name', ''],
						['Recipient Zip Code', 'zip', ''],
						['Recipient Congressional District', 'congressional_district', ''],
						['Recipient Country', 'country', ''],

					]
				],
			],
			[
				'Award',
				[
					[
						['Awarding Agency Name', 'agency', ''],
						['Assistance Type Description', 'assistance_type_description', ''],
						['Federal Action Obligation', 'federal_action_obligation', ''],
						['Face Value of Loan', 'face_value_of_loan', ''],
						['Action Date', 'action_date', ''],
						['Award Description', 'description', ''],
						['CFDA Number', 'cfda_number', ''],
						['Program Name', 'cfda_title', ''],
					]
				],
			],
			[
				'PPP_Loan_Data',
				[
					[
						['Industry Title', 'industry_title', ''],
						['NAICS Code', 'naics_code', ''],
						['Jobs Supported', 'jobs_supported', ''],
						['Owner Ethnicity', 'ppp_owner_ethnicity', ''],
						['Owner Gender', 'ppp_owner_gender', ''],
						['Owner Veteran Status', 'ppp_owner_veteran_status', ''],
						['Lender Name', 'ppp_lender_name', ''],
						['Lender City', 'ppp_lender_city', ''],
						['Lender State', 'ppp_lender_state', ''],
					]
				],
			],
			[
				'Details',
				[
					[
						['Award ID FAIN', 'award_id_fain', ''],
						['Assistance Transaction Unique Key', 'assistance_transaction_unique_key', ''],
						['Award Amount', 'amount', ''],
						['Award Category', 'type', ''],
						['Disaster Emergency Fund Codes for Overall Award', 'disaster_emergency_fund_codes_for_overall_award', ''],
						['Data Source', 'source', ''],
					]
				],
			],
		];

		const contractTabs: TabsType[] = [
			[
				'Recipient',
				[
					[
						['Recipient Name', 'recipient', ''],
						['Recipient DUNS', 'recipient_duns', ''],
						['Recipient Parent Name', 'parent_name', ''],
						['Parent DUNS', 'recipient_parent_duns', ''],
						['NAICS Description', 'naics_description', ''],
						['NAICS Code', 'naics_code', ''],
						['Industry Sector', 'industry', ''],
					],
				],
			],
			[
				'Accountability_Flags',
				[
					[
						['Match Type', 'match_type', ''],
						['Company', 'company', ''],
						['COVID Stimulus Watch Transaction Details', 'csw_record_link', ''],
						['COVID Stimulus Watch Advanced Search', 'csw_advanced_link', ''],
						['Parent Company', 'parent_name', ''],
						['COVID Stimulus Watch Parent Company Profile', 'csw_parent_link', ''],
						['Sum of Penalties Assessed including for Parent', 'pen_total', ''],
						['Employment-Related Penalties including for Parent', 'empl_pen_amt', 'indented'],
						['Contracting-Related Penalties including for Parent', 'contracting_pen_amt', 'indented'],
						['Environmental, Healthcare and Safety-related Penalties including for Parent', 'environ_pen_amt', 'indented'],
						['Consumer Protection, Financial Misconduct and Unfair Competition-Related Penalties including for Parent', 'consumer_pen_amt', 'indented'],
					]
				],
			],
			[
				'Location',
				[
					[
						['Recipient Address Line 1', 'address', ''],
						['Recipient Address Line 2', 'address2', ''],
						['Recipient City', 'city', ''],
						['Recipient County', 'county', ''],
						['Recipient State', 'state_name', ''],
						['Recipient Zip Code', 'zip', ''],
						['Recipient Congressional District', 'congressional_district', ''],
						['Recipient Country', 'country', ''],
					],
				],
			],
			[
				'Owner',
				[
					[
						['Minority Owned Business', 'minority_owned_business', ''],
						['Woman Owned Business', 'woman_owned_business', ''],
						['Veteran Owned Business', 'veteran_owned_business', ''],
						['For Profit Organization', 'for_profit_organization', ''],
						['Nonprofit Organization', 'nonprofit_organization', ''],
						['Foreign Owned', 'foreign_owned', ''],
						['Contracting Officers Determination of Business Size', 'contracting_officers_determination_of_business_size', ''],
					],
				],
			],
			[
				'Award',
				[
					[
						['Awarding Agency Name', 'agency', ''],
						['Federal Action Obligation', 'federal_action_obligation', ''],
						['Action Date', 'action_date', ''],
						['Award Description', 'description', ''],
						['Product or Service Code', 'product_or_service_code', ''],
						['Product or Service Code Description', 'product_or_service_code_description', ''],
						['Extent Competed', 'extent_competed', ''],
						['Solicitation Procedures', 'solicitation_procedures', ''],
						['Type of Contract Pricing', 'type_of_contract_pricing', ''],
						['Number of Offers Received', 'number_of_offers_received', ''],
					],
				],
			],
			[
				'Details',
				[
					[
						['Award ID PIID', 'award_id_piid', ''],
						['Contract Transaction Unique Key', 'contract_transaction_unique_key', ''],
						['National Interest Action', 'national_interest_action', ''],
						['Disaster Emergency Fund Codes for Overall Award', 'disaster_emergency_fund_codes_for_overall_award', ''],
						['Data Source', 'source', ''],
					],
				],
			],
		];

		const { resultType, gjfData } = useSelector(({ spendings }: Reducers) => spendings);
		const yellowFlag = transaction.gjf_ns && !transaction.gjf_nsv;
		const redFlag = transaction.gjf_nsv;

		const [shouldOpen, setShouldOpen] = React.useState<boolean>(false);
		//let targetElement: HTMLBodyElement | null;
		const targetElement = document.querySelector('body');

		const [linkCopied, setLinkCopied] = React.useState<boolean>(false);
		const [shareOpen, setShareOpen] = React.useState<boolean>(false);
		const shareUrl = shareUrlSelector({
			location: useLocation(),
			state: useSelector((state: Reducers) => state),
		}).concat('&row_id=', transaction.row_id.toString());

		const onLinkCopyLinkClick = React.useCallback(() => {
			setLinkCopied(true);

			setTimeout(() => setLinkCopied(false), 5000);
		}, [setLinkCopied]);

		useEffect(() => {
			setTimeout(() => {
				setShouldOpen(true);
			}, 0);
			targetElement && disableBodyScroll(targetElement);
		}, []);

		useEffect(() => {
			dispatch(fetchGJFData({ transactionType: resultType.toLowerCase(), transaction: transaction.row_id }));
		}, []);

		const availableTabs = resultType === SearchType.Assistance ? assistanceTabs : contractTabs;
		const tabIds: string[] = [];
		availableTabs.map((tabType: TabsType) => tabIds.push(tabType[0]));

		const handleClose = () => {
			setShouldOpen(false);
			targetElement && enableBodyScroll(targetElement);
			setTimeout(() => {
				onClose();
			}, 400);
		};

		return (
			<>
				<nav className={cx(styles.menuWrapper, { [styles.menuOpen]: shouldOpen })}>
					<div>
						<div className={styles.heading}>
							<div
								className={cx(styles.shareDesk, {
									[styles.shareDeskToggle]: shareOpen,
								})}
							>
								<Button
									cls={styles.share}
									onClick={() => {
										setShareOpen(!shareOpen);
									}}
								>
									<span className="icon iconShareNew"></span>Share
								</Button>
								<div className={styles.shareDropDown}>
									<ul>
										<li>
											<EmailShareButton url={shareUrl}>
												<span className="icon iconMail"></span>
												Send Email
											</EmailShareButton>
										</li>
										<li>
											<FacebookShareButton url={shareUrl}>
												<span className="icon iconFacebook"></span>
												Share on Facebook
											</FacebookShareButton>
										</li>
										<li>
											<TwitterShareButton url={shareUrl}>
												<span className="icon iconTwitter"></span>
												Share on Twitter
											</TwitterShareButton>
										</li>
										<li>
											<CopyToClipboard
												text={shareUrl}
												onCopy={onLinkCopyLinkClick}
											>
												<strong>
													<span className="icon iconUrl"></span>
													{!linkCopied ? 'Copy link' : 'Link Copied'}
												</strong>
											</CopyToClipboard>
										</li>
									</ul>
								</div>
							</div>
							<button
								type="button"
								onClick={handleClose}
								className={styles.menuClose}
							>
								<span className={styles.menuCloseIcon}></span>
								Close
							</button>
						</div>

						<div className={styles.transaction}>
							<h1 className={styles.transactionTitle}>{transaction.amount}</h1>
							<p className={styles.transactionInfo}>{transaction.agency}</p>
						</div>

						{resultType === SearchType.Assistance && (
							<div className={styles.program}>
								<div className={cx('row', styles.itemLineTab)}>
									<h2 className={cx('col-lg-4', styles.keyTab)}>Program</h2>
									<div className={cx('col-lg-8', styles.valueTab)}>
										{transaction.program}
									</div>
								</div>
							</div>
						)}

						<div className={styles.recipientInfo}>
							<span className={cx('icon', 'iconCircleArrowDown', styles.icon)}></span>

							<div className={cx('row', styles.itemLineTab)}>
								<h2 className={cx('col-lg-4', styles.keyTab)}>Recipient Name</h2>
								<div className={cx('col-lg-8', styles.valueTab)}>
									{transaction.recipient}
								</div>
							</div>

							<div className={cx('row', styles.itemLineTab)}>
								<h2 className={cx('col-lg-4', styles.keyTab)}>Recipient Address</h2>
								<div className={cx('col-lg-8', styles.valueTab)}>
									{transaction.address ? transaction.address.concat(', ') : ''}
									{transaction.city ? transaction.city.concat(', ') : ''}
									{transaction.state_code} {transaction.zip}
								</div>
							</div>
						</div>
					</div>

					<div className={styles.tabsBlock}>
						<div className={styles.tabsAnchorLinks}>
							<Scrollspy
								ids={tabIds}
								activeItemClassName={styles.active}
								offset={-200}
							/>
						</div>

						<div className={styles.tabsAnchor}>
							<div className={styles.itemTab}>
								{availableTabs.map((tabType: TabsType, index: number) => {
									const hasAccIssues = tabType[0] === 'Accountability_Flags';
									const showTab = (
										(
											!hasAccIssues ||
											(hasAccIssues &&
												(redFlag || yellowFlag))
										) && (
											tabType[0] !== 'PPP_Loan_Data' ||
											(tabType[0] === 'PPP_Loan_Data' && isPPP)
										)
									);

									return showTab ? (
										<div className={styles.itemTabWrapper} key={index}>
											<div
												id={tabType[0].replace(/\s/g, '')}
												className={cx(styles.titleTab, { [styles.titleTabNoBorder]: hasAccIssues })}
											>
												{tabType[0].replace(/_/g, ' ')}

											</div>
											{hasAccIssues &&
												<div className={styles.subtitleTab}>
													Accountability data matches or potentially matches Good Jobs First's database, COVID Stimulus Watch.
												</div>
											}
											{tabType[1][0]
												.filter(tabData => {
													tabData[1] === 'csw_advanced_link' && console.log("tabData[1]", tabData[1], yellowFlag);

													return (
														(tabData[1] !== 'csw_record_link' || tabData[1] === 'csw_record_link' && redFlag) &&
														(tabData[1] !== 'csw_advanced_link' || tabData[1] === 'csw_advanced_link' && yellowFlag)
													)
												})
												.map(([label, key, classes]: TabItem, index: number) => {
													var dollarPrefix = [
														'federal_action_obligation',
														'face_value_of_loan',
														'pen_total',
														'empl_pen_amt',
														'contracting_pen_amt',
														'environ_pen_amt',
														'consumer_pen_amt'
													].includes(key);
													const prefix = dollarPrefix ? '$' : '';

													const isLink = ['csw_record_link', 'csw_advanced_link', 'csw_parent', 'csw_parent_link'].includes(key);

													let value;
													if (hasAccIssues) {
														let resultRow;
														if (yellowFlag && gjfData?.gjf_ns.result) {
															resultRow = gjfData?.gjf_ns.result[0];
														}
														if (redFlag && gjfData?.gjf_nsv.result) {
															resultRow = gjfData?.gjf_nsv.result[0];
														}
														value = get(resultRow, key) || 'N/A';
													} else {
														value = get(transaction, key) || 'N/A';
													}

													value = value !== 'N/A' ? `${prefix}${value}` : 'N/A';
													value = key === 'csw_advanced_link' ? 'https://data.covidstimuluswatch.org/prog.php' : value;

													value = isLink && value !== 'N/A' ?
														<a target="_blank" rel="noopener noreferrer" href={value}>{value}
															<span className="icon iconExternalLink"></span>
														</a>
														: value;
													if (key === 'match_type') {
														value = redFlag ? "Exact" : "Potential";
													}

													return (
														<div className={styles.bodyTab} key={index}>
															<div
																key={index}
																className={cx('row', styles.itemLineTab)}
															>
																<h2 className={cx('col-lg-4', classes, styles.keyTab)}>
																	{label}
																</h2>

																<div
																	className={cx('col-lg-8', classes, styles.valueTab)}
																>
																	{key
																		? value
																		: `${label}!?`}
																</div>
															</div>
														</div>
													)
												}
												)}
										</div>
									) : null
								}
								)}
							</div>
						</div>
					</div>
				</nav>

				<div
					onClick={handleClose}
					className={cx(styles.menuOverlay, { [styles.menuOverlayActive]: shouldOpen })}
				/>
			</>
		);
	}
);

export default Transaction;
