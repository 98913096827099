import React from 'react';
import cx from 'classnames';
import Select from 'react-select';
import { OptionType } from '../../types';
import ReactPaginate from 'react-paginate';
import { numbersOnlyMask } from '../../utils';
import { enterKey } from '../../utils/enterKey';
import styles from './WrappedPagination.module.scss';
import { DropdownIndicator } from '../DropdownConfigs';
import selectStyles from '../../constants/selectStyles';

export type OnPageChangeProps = { page: number; perPage: number };

export interface PaginationProps {
    page: number;
    perPage: number;
    pageCount: number;
    disabled?: boolean;
    onChange: ({ page, perPage }: OnPageChangeProps) => void;
}

const WrappedPagination: React.FC<PaginationProps> = React.memo(
    ({ disabled, page, perPage, onChange, ...props }: PaginationProps): React.ReactElement => {
        const [inputPage, setInputPage] = React.useState<number>(page);
        const [perPageOptions, setPerPageOptions] = React.useState<OptionType[]>([]);

        const onPageChange = React.useCallback(
            ({ selected }) => {
                if (!disabled) {
                    onChange({ page: selected + 1, perPage });
                }
            },
            [disabled, perPage, onChange]
        );

        const onPerPageChange = React.useCallback(
            ({ value }: OptionType) => {
                if (!disabled) {
                    onChange({ page: 1, perPage: value as number });
                }
            },
            [disabled, onChange]
        );

        React.useEffect(() => {
            setPerPageOptions(
                [10, 20, 50, 100].map((value: number) => ({
                    value,
                    label: String(value),
                }))
            );
        }, []);

        React.useEffect(() => {
            setInputPage(page);
        }, [page]);

        return (
            <div className={styles.wrapperPagination}>
                <div className="container">
                    <div className={styles.paginationContainer}>
                        <ReactPaginate
                            breakLabel={'...'}
                            forcePage={page - 1}
                            pageRangeDisplayed={5}
                            marginPagesDisplayed={1}
                            nextLabel={<span className="icon iconChevronRight"></span>}
                            nextClassName={cx('next', styles.next)}
                            pageCount={props.pageCount}
                            onPageChange={onPageChange}
                            previousLabel={<span className="icon iconChevronLeft"></span>}
                            breakClassName={cx('break-me', styles.breakMe)}
                            activeClassName={cx('active', styles.active)}
                            previousClassName={cx('previous', styles.previous)}
                            containerClassName={cx('pagination', styles.paginationLeft)}
                        />

                        <ul className={styles.nav}>
                            <li className={styles.showPerPage}>
                                <label>Show per page:</label>

                                <Select
                                    menuPlacement="top"
                                    placeholder={perPage}
                                    value={perPage as any}
                                    options={perPageOptions}
                                    classNamePrefix="select"
                                    instanceId="desktopPerPage"
                                    className="form-control select"
                                    onChange={onPerPageChange as any}
                                    components={{ DropdownIndicator }}
                                    styles={selectStyles.paginationStyles}
                                />
                            </li>

                            <li>
                                <label>Jump to</label>

                                <input
                                    className={styles.inputText}
                                    type="text"
                                    value={inputPage}
                                    placeholder="Page #"
                                    onChange={(e: any) => setInputPage(e.target.value)}
                                    onKeyPress={(e) => {
                                        enterKey(() =>
                                            onPageChange({ selected: Number(inputPage) - 1 })
                                        )(e);

                                        return numbersOnlyMask(e);
                                    }}
                                />
                            </li>

                            <li className={styles.paginationNextPrev}>
                                <ReactPaginate
                                    breakLabel={'...'}
                                    forcePage={page - 1}
                                    pageRangeDisplayed={5}
                                    marginPagesDisplayed={1}
                                    pageCount={props.pageCount}
                                    onPageChange={onPageChange}
                                    nextClassName={cx('next', styles.next)}
                                    activeClassName={cx('active', styles.active)}
                                    breakClassName={cx('break-me', styles.breakMe)}
                                    disabledClassName={cx('disables', styles.disabled)}
                                    previousClassName={cx('previous', styles.previous)}
                                    nextLabel={<span className="icon iconChevronRight"></span>}
                                    previousLabel={<span className="icon iconChevronLeft"></span>}
                                    containerClassName={cx('pagination', styles.paginationRight)}
                                />
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    }
);

export default WrappedPagination;
