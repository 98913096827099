import React from 'react';
import cx from "classnames";
import styles from './ColorBar.module.scss';

interface Props {
    colorIndex: string | null;
}

const ColorBar: React.FC<Props> = React.memo(
    ({ colorIndex }: Props): React.ReactElement => {
        return <div className={cx(styles.colorBar, `color-${colorIndex}`)}></div>;
    }
);

export default ColorBar;
