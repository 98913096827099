import React from 'react';
import { Reducers } from '../../reducers';
import CheckedButtons from '../Form/CheckedButtons';
import { useDispatch, useSelector } from 'react-redux';
import { clearGeoData } from '../../reducers/GeoData';
import { geoDataOptionsSelector } from '../../selectors/geoDataSelector';

const SelectedGeoData: React.FC = React.memo(
    (): React.ReactElement => {
        const dispatch = useDispatch();
        const geoDataOptions = useSelector((state: Reducers) => geoDataOptionsSelector(state));

        return (
            <CheckedButtons options={geoDataOptions} onDelete={() => dispatch(clearGeoData())} />
        );
    }
);

export default SelectedGeoData;
