import React, { useEffect } from 'react';
import cx from 'classnames';
import { truncate } from '../../utils';
import { Reducers } from '../../reducers';
import Loading from '../../components/Loading';
import Popover from '../../components/Popover';
import Dropdown from '../../components/Form/Dropdown';
import { useDispatch, useSelector } from 'react-redux';
import DownloadBtn from '../../components/DownloadBtn';
import FilterTop from '../../components/Filter/FilterTop';
import SpendingsTable from '../../components/SpendingsTable';
import { loadingSelector } from '../../selectors/loadingSelector';
import SpendingsHeader from '../../components/Headers/SpendingsHeader';
import DownloadTable from '../../components/RightSidebar/DownloadTable';
import { RequestState, TableColumn, PC, OptionType } from '../../types';
import WrappedPagination, { OnPageChangeProps } from '../../components/WrappedPagination';
import { SearchType } from '../../constants';
import {
    SpendingsRow,
    setVisibleColumns,
    setPagination,
    fetchData,
    clearData,
} from '../../reducers/Spendings';
import { clearGeoData } from '../../reducers/GeoData';
import { useLocation } from 'react-router-dom';

const SpendingsTableView: PC = React.memo(() => {
    const dispatch = useDispatch();
    const [downloadOpen, setDownloadOpen] = React.useState<boolean>(false);
    const loading = useSelector((state: Reducers) =>
        loadingSelector(state, ['spendings/fetchData'])
    )['spendings/fetchData'];

    const { searchType } = useSelector(({ spendings }: Reducers) => spendings.filters);
    const isAssistance = searchType === SearchType.Assistance;

    const { visibleColumns, pagination } = useSelector(({ spendings }: Reducers) => spendings);
    const location = useLocation();

    useEffect(() => {
        if (!location.search) {
            dispatch(clearGeoData());
            dispatch(clearData());
        }
        return () => {
            dispatch(clearGeoData());
            dispatch(clearData());
        };
    }, []);

    const disabledCols = ['County', 'Date of receiving', 'Industry'];
    const resetColumns = React.useCallback(() => {
        dispatch(
            setVisibleColumns({
                columns: availableColums
                    .filter((option: OptionType) => !disabledCols.includes(option.value as string))
                    .map((option: OptionType) => option.value as string),
            })
        );
    }, []);

    useEffect(() => {
        resetColumns();
    }, [isAssistance]);

    const setPage = React.useCallback(
        (pageChange: OnPageChangeProps) => {
            dispatch(setPagination(pageChange));

            dispatch(fetchData({}));
        },
        [dispatch, pagination]
    );

    const tableColumnsAssistance: TableColumn<SpendingsRow>[] = [
        ['Amount', (row: SpendingsRow) => <strong>{row.amount}</strong>, 'amount'],
        ['Recipient', [(row: SpendingsRow) => truncate(row.recipient), 'recipient'], 'recipient'],
        ['Agency', [(row: SpendingsRow) => truncate(row.agency), 'agency'], 'agency'],
        ['State', 'state_name', 'state'],
        ['County', 'county', 'county'],
        ['Address', [(row: SpendingsRow) => truncate(row.address), 'address'], 'address'],
        ['City', 'city', 'city'],
        ['Zip', 'zip', 'zip'],
        ['Type', [(row: SpendingsRow) => truncate(row.type), 'type'], 'type'],
        ['Program', [(row: SpendingsRow) => truncate(row.program), 'program'], 'program'],
        ['Industry', [(row: SpendingsRow) => truncate(row.industry), 'industry'], 'industry'],
        ['Description', [(row: SpendingsRow) => truncate(row.description), 'description']],
        ['Date of receiving', 'action_date', 'date'],
        ['Accountability Flags', '', 'accIssues'],
    ];

    const tableColumnsContracts: TableColumn<SpendingsRow>[] = [
        ['Amount', (row: SpendingsRow) => <strong>{row.amount}</strong>, 'amount'],
        ['Recipient', [(row: SpendingsRow) => truncate(row.recipient), 'recipient'], 'recipient'],
        ['Agency', [(row: SpendingsRow) => truncate(row.agency), 'agency'], 'agency'],
        ['State', 'state_name', 'state'],
        ['County', 'county', 'county'],
        ['Address', [(row: SpendingsRow) => truncate(row.address), 'address'], 'address'],
        ['City', 'city', 'city'],
        ['Zip', 'zip', 'zip'],
        ['Industry', [(row: SpendingsRow) => truncate(row.industry), 'industry'], 'industry'],
        ['Description', [(row: SpendingsRow) => truncate(row.description), 'description']],
        ['Date of receiving', 'action_date', 'date'],
        ['Accountability Flags', '', 'accIssues'],
    ];

    const toggleSidebar = () => {
        setDownloadOpen(!downloadOpen);
    };

    const availableColums: OptionType[] = (isAssistance
        ? tableColumnsAssistance
        : tableColumnsContracts
    ).map(([label]: TableColumn<SpendingsRow>) => ({
        label,
        value: label,
    }));

    return (
        <>
            <div
                className={cx('bg-circles', 'bg-space', 'bg-overwflow', {
                    'bg-liberty':
                        loading.state === RequestState.Nothing ||
                        (loading.state === RequestState.Success && pagination.recordCount === 0),
                })}
            >
                <div className="scroll-overflow">
                    <div className="container">
                        <div className="row h-100">
                            <div className="col-12 d-flex flex-column">
                                <FilterTop />

                                <div className="table-scrollable">
                                    <div className="row no-gutters align-items-start">
                                        <div className="col-md-12 d-flex table-caption">
                                            <div className="table-caption-left" />
                                            <div className="table-caption-right">
                                                <div>
                                                    <Popover
                                                        showCloseButton
                                                        label="Columns"
                                                        labelIcon={
                                                            <span className="icon iconAllIndustries"></span>
                                                        }
                                                    >
                                                        {({ closePopover }) => (
                                                            <Dropdown
                                                                allOnEmpty={false}
                                                                applyChanges
                                                                showSelectAll
                                                                onApply={closePopover}
                                                                value={visibleColumns}
                                                                options={availableColums}
                                                                onChange={(visibleColumns) =>
                                                                    dispatch(
                                                                        setVisibleColumns({
                                                                            columns: visibleColumns as string[],
                                                                        })
                                                                    )
                                                                }
                                                            />
                                                        )}
                                                    </Popover>
                                                </div>

                                                <div>
                                                    <div className="search-result">
                                                        Search results:{' '}
                                                        {pagination.recordCount > pagination.perPage
                                                            ? pagination.perPage
                                                            : pagination.recordCount}
                                                        /
                                                        <span className="total">
                                                            {pagination.recordCount.toLocaleString()}
                                                        </span>
                                                    </div>
                                                </div>

                                                <div>
                                                    <DownloadBtn toggleSidebar={toggleSidebar} />
                                                </div>
                                            </div>
                                        </div>

                                        {loading.state === RequestState.Nothing && (
                                            <div className="col-lg-7">
                                                <h1 className="heading-text">
                                                    To begin exploring federal COVID relief spending
                                                    data,{' '}
                                                    <span>
                                                        {
                                                            isAssistance ? "please enter a geography or recipient. Filters for Agency, Program, Industry will become available once one of these search parameters are set."
                                                                : "please enter an Agency, Program, Industry, State, County or Zip Code above."
                                                        }
                                                    </span>
                                                </h1>
                                            </div>
                                        )}

                                        {loading.state === RequestState.Success &&
                                            pagination.recordCount === 0 && (
                                                <div className="col-lg-7">
                                                    <h1 className="heading-text">
                                                        No results found.
                                                    </h1>
                                                </div>
                                            )}
                                        {loading.error && (
                                            <div className="col-lg-12">
                                                <h1 className="heading-text">Server Error: Please retry your search.</h1>
                                            </div>
                                        )}
                                    </div>

                                    {loading.state === RequestState.Pending && (
                                        <div className="loading">
                                            <Loading />

                                            <div className="row justify-center">
                                                <div className="col-xxl-5 col-xl-8">
                                                    <h3 className="loading-note">Please note</h3>

                                                    <h1 className="loading-title">
                                                        Broad database searches will result in
                                                        longer load times.
                                                    </h1>

                                                    <h2 className="loading-subtitle">
                                                        For faster search results, add additional
                                                        filters.
                                                    </h2>
                                                </div>
                                            </div>
                                        </div>
                                    )}

                                    {loading.state === RequestState.Success &&
                                        pagination.recordCount !== 0 && (
                                            <div className="row">
                                                <div className="col">
                                                    <SpendingsTable
                                                        tableColumns={tableColumnsAssistance}
                                                    />
                                                </div>
                                            </div>
                                        )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <DownloadTable
                opened={downloadOpen}
                toggleSidebar={toggleSidebar}
                tableColumns={tableColumnsAssistance.map(([label]) => label)}
            />

            {loading.state === RequestState.Success && pagination.recordCount !== 0 && (
                <WrappedPagination
                    onChange={setPage}
                    page={pagination.page}
                    perPage={pagination.perPage}
                    pageCount={pagination.pageCount}
                />
            )}
        </>
    );
});

SpendingsTableView.header = SpendingsHeader;

export default SpendingsTableView;
