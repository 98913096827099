import React from 'react';
import cx from 'classnames';
import styles from '../SidebarTabs.module.scss';
import { formatNumber } from '../../../utils/formatNumber';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../reducers';
import { hasGeoDataSelector } from '../../../selectors/geoDataSelector';

const SidebarBlockOverview: React.FC = React.memo(
    (): React.ReactElement => {
        const hasGeoData = useSelector((state: Reducers) => hasGeoDataSelector(state));
        const { selectionData }: any = useSelector((state: Reducers) => state.geoData);

        return (
            <div className={cx(styles.sidebarBlock, styles.sidebarBlockSpace)}>
                <ul className={styles.sidebarBlockOverview}>
                    <li>
                        <strong>
                            {selectionData
                                ? `$${formatNumber(
                                      (selectionData.total
                                          ? selectionData.total
                                          : selectionData.total_spending) as number
                                  )}`
                                : hasGeoData
                                ? 'N/A'
                                : '$3.5T'}
                        </strong>
                        <span>TOTAL SPENDING</span>
                    </li>
                    <li>
                        <strong>
                            {selectionData
                                ? `$${formatNumber(
                                      (selectionData.assistance
                                          ? selectionData.assistance
                                          : selectionData.assistance_spending) as number
                                  )}`
                                : hasGeoData
                                ? 'N/A'
                                : '$3.3B'}
                        </strong>
                        <span>ASSISTANCE</span>
                    </li>
                    <li>
                        <strong>
                            {selectionData
                                ? `$${formatNumber(
                                      (selectionData.contract
                                          ? selectionData.contract
                                          : selectionData.contract_spending
                                          ? selectionData.contract_spending
                                          : 0) as number
                                  )}`
                                : hasGeoData
                                ? 'N/A'
                                : '$110B'}
                        </strong>
                        <span>CONTRACTS</span>
                    </li>
                </ul>
            </div>
        );
    }
);

export default SidebarBlockOverview;
