import { CancelTokenSource } from 'axios';
import { FunctionComponent } from 'react';

export interface PageComponent<P = {}> extends FunctionComponent {
    header?: React.FC;
}

export type PC<P = {}> = PageComponent<P>;

export enum RequestState {
    Nothing = 0,
    Pending,
    Success,
    Error,
}

export interface NetworkState {
    state: RequestState;
    error: string | null;
    source?: CancelTokenSource;
    requestId?: string;
}

export interface NetworkStates {
    [key: string]: NetworkState;
}

export interface OptionType {
    label: string;
    value: number | string;
}

export type TableColumnCallback<T> = (row: T) => string | React.ReactNode;

export type TableColumn<T = {}> = [
    string,
    string | TableColumnCallback<T> | Array<string | TableColumnCallback<T>>,
    string?
];

export type AwardType<T = {}> = [string, string?];

export interface PaginationType {
    page: number;
    perPage: number;
    pageCount: number;
    recordCount: number;
}

export enum SortDirection {
    ASC = 1,
    DESC = -1,
}

export type SortType = [string, SortDirection];

export interface ResultItemsPayload<T> {
    status: string;
    result?: Array<T>;
}

export interface LongNamesType {
    state: string;
    county: string;
}

export interface GeoDataType {
    coordinates: { [key: string]: number };
    state: string | undefined;
    county: string | undefined;
    zip: string | number | undefined;
    territory?: boolean;
    longNames: LongNamesType;
}

// @TODO, move to the right component.
export interface State {
    assistance_count: number;
    assistance_spending: number;
    contract_spending: number;
    contracts_count: number;
    federal_spending: number;
    government_spending: number;
    individual_spending: number;
    minority_percent: number;
    mp_hispanic: number;
    mp_white: number;
    mp_black: number;
    mp_asian: number;
    mp_other: number;
    name: string;
    population: number;
    small_business_spending: number;
    state_code: string;
    total_spending: number;
    total_count: number;
    unemployment_percent: number;
    loan_spending: number;
    grants_spending: number;
    direct_payment_spending: number;
    other_spending: number;
    centroid?: string[];
}

export interface HttpResponse {
    status: string;
    result: any; //{ [key: string]: State[] };
}
