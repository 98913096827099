import React from 'react';
import cx from 'classnames';
import Button from '../../Button';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../reducers';
import RightSidebar from '../../RightSidebar';
import styles from './DownloadTable.module.scss';
import getEndpointUrlSelector from '../../../selectors/getEndpointUrlSelector';
import { numberWithCommas } from '../../../utils/formatNumber';
import { GeoDataReducerState } from '../../../reducers/GeoData';
import { OptionType } from '../../../types';
import { getIndustriesSelector } from '../../../selectors/getIndustriesSelector';
import { SearchType, TypesOfLoan } from '../../../constants';
import { hasGeoDataSelector } from '../../../selectors/geoDataSelector';
import { hasSpendingFiltersSelector } from '../../../selectors/spendingFiltersSelector';

export interface SidebarProps {
    opened: boolean;
    tableColumns: string[];
    toggleSidebar: () => void;
}

const DownloadTable: React.FC<SidebarProps> = React.memo(
    ({ opened, toggleSidebar }: SidebarProps): React.ReactElement => {
        const { pagination, downloadUrl } = useSelector((reducers: Reducers) => ({
            ...reducers.spendings,
            downloadUrl: getEndpointUrlSelector({
                state: reducers,
                overide: {
                    limit: '0',
                    download: '1',
                },
            }),
        }));
        const filters = useSelector(({ spendings }: Reducers) => spendings.filters);
        const geoData: GeoDataReducerState = useSelector(({ geoData }: Reducers) => geoData);
        const { agencies, programs } = useSelector(({ filters }: Reducers) => filters);
        const industryOptions = useSelector((reducers: Reducers) =>
            getIndustriesSelector(reducers)
        );
        const hasGeoData = useSelector((state: Reducers) => hasGeoDataSelector(state));
        const hasSpendingFilters = useSelector((state: Reducers) =>
            hasSpendingFiltersSelector(state)
        );
        let awards = '';
        filters.type.split(',').forEach((current, index) => {
            if (current) {
                if (index) {
                    awards += ', ';
                }
                awards = awards.concat(
                    Object.entries(TypesOfLoan).find(
                        ([, value]: [any, string], i: number) => i === parseInt(current) - 1
                    )?.[0] as string
                );
            }
        });

        const agency = agencies.find((el: OptionType) => el.value === filters.agency)?.label;

        const program = programs.find((el: OptionType) => el.value === filters.program)?.label;

        const industry = industryOptions.find((el: OptionType) => el.value === filters.industry)
            ?.label;

        return (
            <RightSidebar open={opened} toggleSidebar={toggleSidebar}>
                <div className={styles.sidebarHeading}>
                    <div className={styles.sidebarHeadingTitle}>
                        <span
                            className={`${styles.icon} ${styles.iconRound} ${styles.iconDownload}`}
                        />
                        Download Transactions
                    </div>

                    <button type="button" className={styles.sidebarClose} onClick={toggleSidebar}>
                        <span className={styles.sidebarCloseBox}>
                            <span className={styles.sidebarCloseInner} />
                        </span>
                    </button>
                </div>

                <div className={styles.sidebarBody}>
                    <div>
                        <p className={styles.sidebarLabel}>
                            Your data download will include the following filters:
                        </p>

                        <div className={styles.dataDownload}>
                            <div className={styles.dataItem}>
                                <div className={styles.dataItemHalf}>
                                    <strong>Type:</strong>
                                    <span>{filters.searchType}</span>
                                </div>
                                <div className={styles.dataItemHalf}>
                                    <strong>State:</strong>
                                    <span>
                                        {geoData.state[1] ? geoData.state[1] : 'None selected'}
                                    </span>
                                </div>
                            </div>
                            <div className={styles.dataItem}>
                                <div className={styles.dataItemHalf}>
                                    <strong>
                                        {geoData.zip
                                            ? 'Zip'
                                            : geoData.county[0]
                                            ? 'County'
                                            : 'County/Zip'}
                                        :
                                    </strong>
                                    <span>
                                        {geoData.zip
                                            ? geoData.zip
                                            : geoData.county[1]
                                            ? geoData.county[1].replace('County', '')
                                            : 'None selected'}
                                    </span>
                                </div>
                                <div className={styles.dataItemHalf}>
                                    <strong>Recipient:</strong>
                                    <span>
                                        {filters.recipient ? filters.recipient : 'None selected'}
                                    </span>
                                </div>
                            </div>
                            <div className={styles.dataItem}>
                                {filters.searchType === SearchType.Assistance && (
                                    <div className={styles.dataItemHalf}>
                                        <strong>Type of Award:</strong>
                                        <span>{awards ? awards : 'None selected'}</span>
                                    </div>
                                )}
                                <div className={styles.dataItemHalf}>
                                    <strong>Awarding Agency:</strong>
                                    <span>{agency ? agency : 'None selected'}</span>
                                </div>
                            </div>
                            <div className={styles.dataItem}>
                                {filters.searchType === SearchType.Assistance && (
                                    <div className={styles.dataItemHalf}>
                                        <strong>Program:</strong>
                                        <span>{program ? program : 'None selected'}</span>
                                    </div>
                                )}
                                <div className={styles.dataItemHalf}>
                                    <strong>Industry:</strong>
                                    <span>{industry ? industry : 'None selected'}</span>
                                </div>
                            </div>
                            <div className={styles.dataItem}>
                                <div className={styles.dataItemHalf}>
                                    <strong>From:</strong>
                                    <span>
                                        {filters.spending[0]
                                            ? filters.spending[0]
                                            : 'None selected'}
                                    </span>
                                </div>
                                <div className={styles.dataItemHalf}>
                                    <strong>To:</strong>
                                    <span>
                                        {filters.spending[1]
                                            ? filters.spending[1]
                                            : 'None selected'}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={cx(styles.sidebarMain, styles.sidebarSecondary)}>
                        <div className={styles.sidebarDescription}>
                            <h3>
                                This data download includes the current search results along with
                                all columns available for download, not just the columns shown.
                            </h3>

                            <div className={styles.sidebarDescriptionText}>
                                For a complete list of our downloadable data fields please{' '}
                                <Link to="/faq">see our FAQ</Link>.
                            </div>

                            <div className={styles.sidebarDescriptionFootnotes}>
                                Maximum Download Size:
                                <p>500k transactions</p>
                            </div>

                            <div className={styles.sidebarDescriptionFootnotes}>
                                Info
                                <p>
                                    If the number of rows displayed below exceeds 500,000, your
                                    download file will be incomplete. To reduce the number of rows
                                    below the download limit, try adding additional filters.
                                </p>
                            </div>

                            <div className={styles.stats}>
                                <div className={styles.statsGroup}>
                                    <div>
                                        <div>Rows:</div>

                                        <div>
                                            <span>{numberWithCommas(pagination?.recordCount)}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className={styles.sidebarActions}>
                            <Button cls="btn-secondary" onClick={toggleSidebar}>
                                Cancel
                            </Button>

                            <Button
                                disabled={!hasGeoData && !hasSpendingFilters}
                                cls={cx(
                                    'btn-primary',
                                    !hasGeoData && !hasSpendingFilters ? 'btn-disabled' : ''
                                )}
                                onClick={() => window.open(downloadUrl)}
                            >
                                Download
                            </Button>
                        </div>
                    </div>
                </div>
            </RightSidebar>
        );
    }
);

export default DownloadTable;
