import React from 'react';
import { Logo } from './Icons';
import { Link } from 'react-router-dom';
import styles from './HeaderLogo.module.scss';

const HeaderLogo: React.FC = React.memo(
    (): React.ReactElement => (
        <Link to="/">
            <span className={styles.logo}>
                <Logo />
            </span>
        </Link>
    )
);

export default HeaderLogo;
