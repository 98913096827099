import { GeoDataType, State } from '../types';

const apiKeys: { [key: string]: string } = {
    counties:
        '/layers/counties_view_2/{z}/{x}/{y}?fields=name,fips_code,state_code,population,unemployment_jan_20,unemployment_apr_20,unemployment_jul_20,unemployment_oct_20,unemployment_jan_21,unemployment_apr_21,unemployment_jul_21,unemployment_oct_21,unemployment_jan_22,unemployment_apr_22,total_spending,total_count,assistance_spending,assistance_count,contract_spending,contracts_count,loan_spending,loan_count,grants_spending,grants_count,direct_payment_spending,direct_payment_count,other_spending,other_count,ppp_loan,ppp_loan_count,mp_total,mp_hispanic,mp_white,mp_black,mp_asian,mp_other,per_capita_spending',
    zip:
        '/layers/zip_codes_view_2/{z}/{x}/{y}?fields=zip,state_code,population,unemployment_jan_20,unemployment_apr_20,unemployment_jul_20,unemployment_oct_20,unemployment_jan_21,unemployment_apr_21,unemployment_jul_21,unemployment_oct_21,unemployment_jan_22,unemployment_apr_22,total_spending,total_count,assistance_spending,assistance_count,contract_spending,contracts_count,loan_spending,loan_count,grants_spending,grants_count,direct_payment_spending,direct_payment_count,other_spending,other_count,ppp_loan,ppp_loan_count,mp_total,mp_hispanic,mp_white,mp_black,mp_asian,mp_other,per_capita_spending',
    states:
        '/layers/states_view_2/{z}/{x}/{y}?fields=name,state_code,population,unemployment_jan_20,unemployment_apr_20,unemployment_jul_20,unemployment_oct_20,unemployment_jan_21,unemployment_apr_21,unemployment_jul_21,unemployment_oct_21,unemployment_jan_22,unemployment_apr_22,total_spending,total_count,assistance_spending,assistance_count,contract_spending,contracts_count,loan_spending,loan_count,grants_spending,grants_count,direct_payment_spending,direct_payment_count,other_spending,other_count,ppp_loan,ppp_loan_count,mp_total,mp_hispanic,mp_white,mp_black,mp_asian,mp_other,per_capita_spending',
};

export type Address = {
    centroid: string[];
    name: string;
    state_code: string;
    state_name: string;
    fips_code?: string;
    zip?: string;
    territory?: boolean;
};

export const getGeoResult = (address: Address) => {
    const geoResult: GeoDataType = {
        coordinates: {
            lng: parseFloat(address.centroid[0]),
            lat: parseFloat(address.centroid[1]),
        },
        state: address.state_code,
        county: address.fips_code ? address.fips_code : undefined,
        zip: address.zip ? address.zip : undefined,
        longNames: {
            state: address.state_name,
            county: address.fips_code ? address.name : '',
        },
        territory: address.territory,
    };

    return geoResult;
};

export const getGeoResultForHover = (
    features: any,
    popupData: any,
    statesData: { [key: string]: State }
) => {
    const geoResult: any = {};
    if (features[0].layer.id.includes('states-layer')) {
        if (popupData?.geoResult.state === features[0].properties.state_code) {
            return;
        }
        geoResult.state = features[0].properties.state_code;
        geoResult.longNames = {
            state: features[0].properties.name,
        };
    } else if (features[0].layer.id.includes('counties-layer')) {
        if (
            popupData?.geoResult.state === features[0].properties.state_code &&
            popupData?.geoResult.county === features[0].properties.fips_code
        ) {
            return;
        }
        geoResult.state = features[0].properties.state_code;
        geoResult.county = features[0].properties.fips_code;
        const stateLong = statesData ? statesData[features[0].properties.state_code]?.name : null;
        geoResult.longNames = {
            state: stateLong || features[0].properties.state_code,
            county: features[0].properties.name,
        };
    } else if (features[0].layer.id.includes('zip-layer')) {
        if (
            popupData?.geoResult.state === features[0].properties.state_code &&
            popupData?.geoResult.zip === features[0].properties.zip
        ) {
            return;
        }
        geoResult.state = features[0].properties.state_code;
        geoResult.zip = features[0].properties.zip;
        const stateLong = statesData ? statesData[features[0].properties.state_code]?.name : null;
        geoResult.longNames = {
            state: stateLong || features[0].properties.state_code,
        };
    }
    if (geoResult.state) {
        let coordinates = features[0].geometry.coordinates[0];
        if (features[0].geometry.type !== 'Polygon') {
            coordinates = coordinates[0];
        }
        const maxLng = Math.max(...coordinates.map((el: number[]) => el[0]));

        const minLng = Math.min(...coordinates.map((el: number[]) => el[0]));
        const maxLat = Math.max(...coordinates.map((el: number[]) => el[1]));
        const minLat = Math.min(...coordinates.map((el: number[]) => el[1]));
        let lng = (maxLng + minLng) / 2;
        let lat = (maxLat + minLat) / 2;
        if (!geoResult.county && !geoResult.zip) {
            if (features[0].properties.state_code === 'FL') {
                lat = 27.719499;
                lng = -81.675213;
            }
            if (features[0].properties.state_code === 'AL') {
                lat = 33.023233;
                lng = -86.644139;
            }
            if (features[0].properties.state_code === 'WI') {
                lat = 44.533835;
                lng = -89.867004;
            }
            if (features[0].properties.state_code === 'KY') {
                lat = 37.332928;
                lng = -84.941721;
            }
            if (features[0].properties.state_code === 'ID') {
                lat = 43.764848;
                lng = -114.296327;
            }
            if (features[0].properties.state_code === 'VA') {
                lat = 37.319081;
                lng = -78.511146;
            }
            if (features[0].properties.state_code === 'NY') {
                lat = 42.780784;
                lng = -75.527303;
            }
            if (features[0].properties.state_code === 'MD') {
                lat = 39.295745;
                lng = -76.997601;
            }
            if (features[0].properties.state_code === 'NJ') {
                lat = 40.02162;
                lng = -74.308263;
            }
            if (features[0].properties.state_code === 'MA') {
                lat = 42.407748;
                lng = -72.132629;
            }
            if (features[0].properties.state_code === 'AK') {
                lat = 65.197254;
                lng = -152.543222;
            }
        }

        geoResult.coordinates = {
            lng,
            lat,
        };
        geoResult.territory = false;
        return geoResult;
    }
};

export const getMapLayers = (newLayerIndex: number, mappedData: any, currentMapType: string) => {
    const layers: any[] = [];
    Object.keys(mappedData).forEach((mapType) => {
        mappedData[mapType].forEach((layer: any, layerIndex: number) => {
            layer.ranges.forEach((range: any, rangeIndex: number) => {
                layers.push({
                    id: `${mapType}-layer${layerIndex}-range${rangeIndex}`,
                    type: 'fill',
                    source: {
                        type: 'vector',
                        tiles: [process.env.REACT_APP_API_ENDPOINT + apiKeys[mapType]],
                        promoteId: 'population',
                        minzoom: 0,
                        maxzoom: 22,
                    },
                    'source-layer': 'tile',
                    paint: {
                        'fill-color': layer.fillColor,
                        'fill-opacity': 1,
                        'fill-outline-color': 'black',
                    },
                    layout: {
                        visibility:
                            mapType === currentMapType && layerIndex === newLayerIndex
                                ? 'visible'
                                : 'none',
                    },
                    filter: [
                        'all',
                        ['>=', ['get', layer.name], layer.ranges[rangeIndex][0]],
                        ['<=', ['get', layer.name], layer.ranges[rangeIndex][1]],
                    ],
                });
            });
        });
    });
    return layers;
};

export const getStateOutlineLayer = (mapType: string) => {
    const outlineLayers: any[] = [];
    Object.keys(apiKeys).forEach((layer) => {
        outlineLayers.push({
            id: `${layer}-outline-layer`,
            type: 'line',
            source: {
                type: 'vector',
                tiles: [process.env.REACT_APP_API_ENDPOINT + apiKeys[layer]],
                minzoom: 0,
                maxzoom: 22,
            },
            'source-layer': 'tile',
            paint: {
                'line-color': layer === 'states' ? 'white' : 'black',
                'line-width': 1,
            },
            layout: {
                visibility: layer === 'states' ? 'visible' : 'none',
            },
        });
    });

    return outlineLayers;
};

export const getMapHoverLayers = (
    newLayerIndex: number,
    mappedData: any,
    currentMapType: string
) => {
    const hoverLayers: any[] = [];
    Object.keys(mappedData).forEach((mapType) => {
        mappedData[mapType].forEach((layer: any, layerIndex: number) => {
            hoverLayers.push({
                id: `${mapType}-layer${layerIndex}-hover`,
                type: 'fill',
                source: {
                    type: 'vector',
                    tiles: [process.env.REACT_APP_API_ENDPOINT + apiKeys[mapType]],
                    promoteId:
                        mapType === 'zip'
                            ? 'zip'
                            : mapType === 'counties'
                            ? 'fips_code'
                            : 'total_spending',
                    minzoom: 0,
                    maxzoom: 22,
                },
                'source-layer': 'tile',
                paint: {
                    'fill-color': [
                        'case',
                        ['boolean', ['feature-state', 'hover'], false],
                        'white',
                        'transparent',
                    ],
                    'fill-outline-color': 'black',
                },
                layout: {
                    visibility:
                        mapType === currentMapType && layerIndex === newLayerIndex
                            ? 'visible'
                            : 'none',
                },
            });
        });
    });
    return hoverLayers;
};
