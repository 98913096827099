import React, { useEffect } from 'react';
import styles from './InfoPopup.module.scss';
import Button from '../Button';
import { GeoDataType, State } from '../../types';
import { filterTypes, tabsData } from '../../constants';
import { formatNumber } from '../../utils/formatNumber';
import { Link } from 'react-router-dom';
import { setGeoData } from '../../reducers/GeoData';
import { useDispatch } from 'react-redux';

interface Props {
    style?: any;
    data: State;
    activeTab: string;
    filterType: string | null;
    closePopup: () => void;
}

const InfoPopup: React.FC<Props> = React.memo(
    ({ style, data, filterType, activeTab, closePopup }: Props): React.ReactElement => {
        const [label, setLabel] = React.useState<string>('');
        const [newGeoData, setNewGeoData] = React.useState<GeoDataType | undefined>();
        const dispatch = useDispatch();

        useEffect(() => {
            const label = Object.keys(filterTypes).find((key) => filterTypes[key] === filterType);
            if (label === 'MINORITY') {
                setLabel('MINORITY POPULATION PERCENTAGE');
            } else {
                setLabel(label as string);
            }
        }, []);

        useEffect(() => {
            const geoResult: GeoDataType = {
                coordinates: {
                    lng: parseFloat(data?.centroid?.[0] as string),
                    lat: parseFloat(data?.centroid?.[1] as string),
                },
                state: data.state_code,
                county: '',
                zip: '',
                longNames: {
                    state: data.name,
                    county: '',
                },
                territory: false,
            };
            setNewGeoData(geoResult);
        }, [data]);

        const exploreData = React.useCallback(() => {
            if (newGeoData) {
                dispatch(setGeoData(newGeoData));
            }
        }, [newGeoData]);

        return (
            <div style={style} className={styles.infoPopup}>
                <div className={styles.infoPopupHeader}>
                    <div className={styles.infoCountryName}>{data.name}</div>

                    <button onClick={closePopup} className={styles.infoClose}>
                        <span className="icon iconClose"></span>
                    </button>

                    {/*<div className={styles.infoActuality}>*/}
                    {/*    <Button cls="btn-link btn-arrow btn-gray">*/}
                    {/*        07.25.2020*/}
                    {/*        <span className="icon iconChevronDown"></span>*/}
                    {/*    </Button>*/}
                    {/*</div>*/}
                </div>

                <div className={styles.infoPopupBody}>
                    <div className={styles.infoLeft}>
                        <div className={styles.infoColumns}>
                            <div>
                                <strong>
                                    ${formatNumber(data[activeTab as keyof State] as number)}
                                </strong>
                                <span>{tabsData[activeTab].totalLabel}</span>
                            </div>

                            <div>
                                <strong>
                                    {tabsData[activeTab].totalCountKey
                                        ? formatNumber(
                                              data[
                                                  tabsData[activeTab].totalCountKey as keyof State
                                              ] as number
                                          )
                                        : 0}
                                </strong>
                                <span>Total Transactions</span>
                            </div>

                            <div>
                                <strong>
                                    $
                                    {formatNumber(
                                        (data[activeTab as keyof State] as number) / data.population
                                    )}
                                </strong>
                                <span>{tabsData[activeTab].perCapitaLabel}</span>
                            </div>

                            {filterType && (
                                <div>
                                    <strong>
                                        {formatNumber(data[filterType as keyof State] as number)}{' '}
                                        {filterType !== filterTypes.POPULATION && '%'}
                                    </strong>
                                    <span>{label}</span>
                                </div>
                            )}
                        </div>
                    </div>
                    <div className={styles.infoRight}>
                        <h4 className={styles.title}>DEMOGRAPHICS</h4>
                        <ul className={styles.listCircles}>
                            <li className={styles.malach}>
                                <span>Asian</span>
                                <strong>{data.mp_asian.toFixed(1)}%</strong>
                            </li>
                            <li className={styles.orange}>
                                <span>Black</span>
                                <strong>{data.mp_black.toFixed(1)}%</strong>
                            </li>
                            <li className={styles.purple}>
                                <span>Hispanic</span>
                                <strong>{data.mp_hispanic.toFixed(1)}%</strong>
                            </li>
                            <li className={styles.blue}>
                                <span>Other</span>
                                <strong>{data.mp_other.toFixed(1)}%</strong>
                            </li>
                            <li className={styles.cyan}>
                                <span>White</span>
                                <strong>{data.mp_white.toFixed(1)}%</strong>
                            </li>
                        </ul>
                    </div>
                </div>

                <div className={styles.infoPopupFooter}>
                    <Link to="/map-view">
                        <Button onClick={exploreData} cls="btn-link">
                            EXPLORE DATA
                        </Button>
                    </Link>
                </div>
            </div>
        );
    }
);

export default InfoPopup;
