import React from 'react';
import Navbar from './Navbar';
import styles from './MainMenu.module.scss';

const MainMenu: React.FC = React.memo(
    (): React.ReactElement => {
        const [navOpen, setNavOpen] = React.useState<boolean>(false);
        const toggleNavbar = () => setNavOpen(!navOpen);

        return (
            <>
                <button type="button" onClick={toggleNavbar} className={styles.toggler}>
                    <span className={styles.togglerBox}>
                        <span className={styles.togglerInner}></span>
                    </span>
                </button>

                <Navbar toggleNavbar={toggleNavbar} open={navOpen} />
            </>
        );
    }
);

export default MainMenu;
