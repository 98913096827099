import React, { useEffect } from 'react';
import Button from '../../Button';
import styles from './InfoPopupSmall.module.scss';
import { formatNumber } from '../../../utils/formatNumber';
import { useDispatch, useSelector } from 'react-redux';
import { setGeoData } from '../../../reducers/GeoData';
import { stateFields } from '../../../constants/map';
import { Reducers } from '../../../reducers';

interface LayerData {
    name: string;
    assistance: number;
    assistance_spending: number;
    assistance_count: number;
    contract: number;
    contract_spending: number;
    contracts_count: number;
    ppp_loan: number;
    ppp_loan_count: number;
    per_capita_spending: number;
    mp_total: number;
    mp_asian: number;
    mp_white: number;
    mp_black: number;
    mp_hispanic: number;
    mp_other: number;
    population: number;
    total: number;
    total_spending: number;
    total_count: number;
    unemployment_jan_20: number;
    unemployment_apr_20: number;
    unemployment_jul_20: number;
    unemployment_oct_20: number;
    unemployment_jan_21: number;
    unemployment_apr_21: number;
    unemployment_jul_21: number;
    unemployment_oct_21: number;
    unemployment_jan_22: number;
    unemployment_apr_22: number;
    geoResult: any;
}

interface Props {
    data: LayerData;
    closePopup: () => void;
    currentLayer: number;
}

const InfoPopupSmall: React.FC<Props> = React.memo(
    ({ data, closePopup, currentLayer }: Props): React.ReactElement => {
        const { statesData, countiesData, zipData }: any = useSelector(
            ({ mapData }: Reducers) => mapData
        );
        const geoData = useSelector(({ geoData }: Reducers) => geoData);
        const [storeData, setStoreData] = React.useState<LayerData>();

        useEffect(() => {
            setStoreData(undefined);
            if (!data.geoResult.state) {
                if (geoData.zip) {
                    if (zipData && zipData[geoData.state[0]]) {
                        setStoreData(zipData[geoData.state[0]][geoData.zip]);
                    }
                } else if (geoData.county[0]) {
                    if (countiesData) {
                        setStoreData(countiesData[geoData.state[0]][geoData.county[0]]);
                    }
                } else {
                    if (statesData) {
                        setStoreData(statesData[geoData.state[0]]);
                    }
                }
            } else {
                setStoreData(data);
            }
        }, [zipData, geoData, data]);
        const name = !data.geoResult.state
            ? `${data.name}${geoData.zip || geoData.county[0] ? ', ' + geoData.state[0] : ''}`
            : !data.geoResult.zip
            ? data.name
            : data.name.concat(`, ${data.geoResult.state}`);
        let total = formatNumber(data.total_spending);
        let perCapita = formatNumber(data.total_spending / data.population);
        let transactions = storeData?.total_count
            ? formatNumber(storeData.total_count)
            : data.total_count
            ? formatNumber(data.total_count)
            : 'N/A';

        let totalSpentLabel = 'Total Spent';
        let perCapitaLabel = 'Per Capita Spending';
        if (stateFields[currentLayer].name === 'assistance_spending') {
            totalSpentLabel = 'Total Assistance';
            perCapitaLabel = 'Per Capita Assistance';
            transactions = storeData?.assistance_count
                ? formatNumber(storeData.assistance_count)
                : 'N/A';
            if (storeData?.assistance_spending) {
                total = formatNumber(storeData?.assistance_spending);
                perCapita = formatNumber(storeData.assistance_spending / storeData.population);
            } else {
                total = 'N/A';
                perCapita = 'N/A';
            }
        } else if (stateFields[currentLayer].name === 'contract_spending') {
            totalSpentLabel = 'Total Contracts';
            perCapitaLabel = 'Per Capita Contracts';
            transactions = storeData?.contracts_count
                ? formatNumber(storeData.contracts_count as number)
                : 'N/A';
            if (storeData?.contract_spending) {
                total = formatNumber(storeData.contract_spending);
                perCapita = formatNumber(storeData.contract_spending / storeData.population);
            } else {
                total = 'N/A';
                perCapita = 'N/A';
            }
        } else if (stateFields[currentLayer].name === 'ppp_loan') {
            totalSpentLabel = 'Total PPP';
            perCapitaLabel = 'Per Capita PPP';
            transactions = storeData?.ppp_loan_count
                ? formatNumber(storeData.ppp_loan_count as number)
                : 'N/A';
            if (storeData?.ppp_loan) {
                total = formatNumber(storeData.ppp_loan);
                perCapita = formatNumber(storeData.ppp_loan / storeData.population);
            } else {
                total = 'N/A';
                perCapita = 'N/A';
            }
        }
        const additionalValue = formatNumber(
            data[stateFields[currentLayer].name as keyof LayerData]
        )
            .toString()
            .concat(' ');




            console.log("here", data.geoResult.state, storeData && storeData);


        return (
            <div
                className={styles.infoPopup}
                style={
                    currentLayer === 7
                        ? { width: '350px', left: 'calc(50% - 170px)', marginBottom: '-25px' }
                        : {}
                }
            >
                <div className={styles.infoPopupHeader}>
                    <div className={styles.infoCountryName}>{name}</div>

                    <button onClick={closePopup} className={styles.infoClose}>
                        <span className="icon iconClose"></span>
                    </button>
                </div>

                <div className={styles.infoPopupBody}>
                    <div className={styles.infoColumns}>
                        <div>
                            <strong>${total}</strong>
                            <span>{totalSpentLabel}</span>
                        </div>

                        <div>
                            <strong>{transactions}</strong>
                            <span>Total Transactions</span>
                        </div>

                        <div>
                            <strong>${perCapita}</strong>
                            <span>{perCapitaLabel}</span>
                        </div>
                        {![0, 1, 2, 3, 4].includes(currentLayer) && (
                            <div>
                                <strong>
                                    {data.geoResult.state
                                        ? additionalValue
                                        : formatNumber(
                                              storeData &&
                                                  storeData[
                                                      stateFields[currentLayer]
                                                          .name as keyof LayerData
                                                  ]
                                          )}

                                    {stateFields[currentLayer].label.includes('Percentage') ||
                                        (stateFields[currentLayer].label.includes('Unemployment') &&
                                            '%')}
                                </strong>
                                <span>{stateFields[currentLayer].label}</span>
                            </div>
                        )}
                    </div>
                    {currentLayer === 7 && (
                        <div className={styles.infoRight}>
                            <h4 className={styles.title}>DEMOGRAPHICS</h4>
                            <ul className={styles.listCircles}>
                                <li className={styles.malach}>
                                    <span>Asian</span>
                                    <strong>&nbsp;{storeData?.mp_asian?.toFixed(1)}%</strong>
                                </li>
                                <li className={styles.orange}>
                                    <span>Black</span>
                                    <strong>&nbsp;{storeData?.mp_black?.toFixed(1)}%</strong>
                                </li>
                                <li className={styles.purple}>
                                    <span>Hispanic</span>
                                    <strong>&nbsp;{storeData?.mp_hispanic?.toFixed(1)}%</strong>
                                </li>
                                <li className={styles.blue}>
                                    <span>Other</span>
                                    <strong>&nbsp;{storeData?.mp_other?.toFixed(1)}%</strong>
                                </li>
                                <li className={styles.cyan}>
                                    <span>White</span>
                                    <strong>&nbsp;{storeData?.mp_white?.toFixed(1)}%</strong>
                                </li>
                            </ul>
                        </div>
                    )}
                </div>
            </div>
        );
    }
);

export default InfoPopupSmall;
