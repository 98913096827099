export const formatNumber = (population: number, precision?: number): string | number => {
    const absValue = Math.abs(Number(population));
    const trillions = 1.0e12;
    const billions = 1.0e9;
    const millions = 1.0e6;
    const thousands = 1.0e3;
    const prec = precision ? precision : 1;
    return absValue >= trillions
        ? parseFloat((absValue / trillions).toFixed(prec)).toFixed(prec) + 'T'
        : // Nine Zeroes for Billions
        absValue >= billions
        ? parseFloat((absValue / billions).toFixed(prec)).toFixed(prec) + 'B'
        : // Six Zeroes for Millions
        absValue >= millions
        ? parseFloat((absValue / millions).toFixed(prec)).toFixed(prec) + 'M'
        : // Three Zeroes for Thousands
        absValue >= thousands
        ? parseFloat((absValue / thousands).toFixed(prec)).toFixed(prec) + 'K'
        : parseFloat(absValue.toFixed(prec));
};

export const parseGroupedFloat = (stringValue: string | number) => {
    if (typeof stringValue === 'number') return stringValue;
    stringValue = stringValue.trim();
    let result = stringValue.replace(/[^0-9]/g, '');
    if (/[,.]\d{2}$/.test(stringValue)) {
        result = result.replace(/(\d{2})$/, '.$1');
    }
    const parsedResult = parseFloat(result);
    return parsedResult;
};

export const parseAndFormatAmt = (amt: string | number | undefined, prefix?: string) => {
    if (amt || (typeof amt !== 'number' && amt?.length == 0)) {
        const parsed = parseGroupedFloat(amt);
        const formatted = !isNaN(parsed) ? `${prefix ? prefix : ''}${formatNumber(parsed)}` : '-';
        return formatted;
    }
    return 0;
};

export const numberWithCommas = (x: number | undefined) => {
    if (x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    }
    return 0;
};
