import React, { useEffect } from 'react';
import styles from './LegendBubble.module.scss';
import cx from 'classnames';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../reducers';

interface Props {
    onMapTypeChange: (type: string) => void;
    changeLegendItemVisibility: (index: number) => void;
    mappedData: { [key: string]: any[] };
}

const defaultLegendGroups = [
    {
        id: 1,
        name: 'State',
        key: 'states',
        caption: 'Covid Spending',
        colorScheme: [
            'checkbox-azure',
            'checkbox-olive',
            'checkbox-blue',
            'checkbox-yellow',
            'checkbox-light-green',
            'checkbox-light-purple',
            'checkbox-purple',
            'checkbox-rose',
            'checkbox-light-blue',
            'checkbox-light-rose',
            'checkbox-light-rose1',
            'checkbox-light-rose2',
            'checkbox-oct21',
            'checkbox-jan22',
            'checkbox-apr22',
            'checkbox-orange',
            'checkbox-green',
        ],
        isChecked: true,
        isExpanded: true,
    },
    {
        id: 2,
        name: 'County',
        key: 'counties',
        caption: 'Covid Spending',
        colorScheme: [
            'checkbox-azure',
            'checkbox-olive',
            'checkbox-blue',
            'checkbox-yellow',
            'checkbox-light-green',
            'checkbox-light-purple',
            'checkbox-purple',
            'checkbox-rose',
            'checkbox-light-blue',
            'checkbox-light-rose',
            'checkbox-light-rose1',
            'checkbox-light-rose2',
            'checkbox-oct21',
            'checkbox-jan22',
            'checkbox-apr22',
            'checkbox-orange',
            'checkbox-green',
        ],
        isChecked: false,
        isExpanded: false,
    },
    {
        id: 3,
        name: 'Zip',
        key: 'zip',
        caption: 'Covid Spending',
        colorScheme: [
            'checkbox-azure',
            'checkbox-olive',
            'checkbox-blue',
            'checkbox-yellow',
            'checkbox-light-green',
            'checkbox-light-purple',
            'checkbox-purple',
            'checkbox-rose',
            'checkbox-light-blue',
            'checkbox-light-rose',
            'checkbox-light-rose1',
            'checkbox-light-rose2',
            'checkbox-oct21',
            'checkbox-jan22',
            'checkbox-apr22',
            'checkbox-orange',
            'checkbox-green',
        ],
        isChecked: false,
        isExpanded: false,
    },
];

const LegendBubble: React.FC<Props> = React.memo(
    ({ onMapTypeChange, changeLegendItemVisibility, mappedData }: Props): React.ReactElement => {
        const [legendGroups, setLegendGroups] = React.useState(defaultLegendGroups);
        const [isFirstRender, setIsFirstRender] = React.useState(true);
        const { currentMapType, currentLayer }: any = useSelector(
            ({ mapData }: Reducers) => mapData
        );

        const getLegendItems = React.useCallback(() => {
            const legendItems: any[] = [];
            Object.keys(mappedData).forEach((key, index) => {
                const stateData = mappedData[key as string][currentLayer];
                stateData.ranges.forEach((range: any, i: number) => {
                    let suffix =
                        (stateData.name.indexOf('unemployment') &&
                            stateData.name.indexOf('mp_total')) > -1
                            ? ' percent'
                            : '';
                    let prefix = suffix.length ? '' : '$';
                    if (stateData.name.indexOf('population') > -1) {
                        suffix = '';
                        prefix = '';
                    }
                    const min = prefix
                        .concat(range[0])
                        .toString()
                        .concat(suffix)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    const max = prefix
                        .concat(range[1])
                        .toString()
                        .concat(suffix)
                        .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
                    const item = {
                        groupId: index + 1,
                        color: stateData.fillColor[i + 4],
                        id: i + 1,
                        value: `${min} - ${max}`,
                        isChecked: true,
                        currentLayer,
                    };
                    if (currentLayer === 2 && min === '$0') {
                        item.value = '$0';
                    }
                    legendItems.push(item);
                });
            });
            return legendItems;
        }, [currentLayer]);

        const [legendItems, setLegendItems] = React.useState<any>(getLegendItems());

        useEffect(() => {
            setLegendItems(getLegendItems());
        }, [currentLayer]);

        useEffect(() => {
            const group = legendGroups.find((group) => group.key === currentMapType);
            if (group) {
                const event = {
                    target: {
                        value: group.id,
                        checked: true,
                    },
                };
                handleAllChecked(event, group.id);
            }
        }, [currentMapType]);

        const handleAllChecked = React.useCallback(
            (event: any, id: number) => {
                const groupsMutated = legendGroups;
                const legendMutated = getLegendItems();
                legendMutated.forEach((option: any) => {
                    option.isChecked = option.groupId === id ? event.target.checked : false;
                });

                groupsMutated.forEach((group) => {
                    group.isChecked = group.id === id && event.target.checked;
                    group.isExpanded = group.isChecked;
                    if (group.id === id && !isFirstRender) {
                        onMapTypeChange(group.key);
                    }
                });
                setLegendGroups([...groupsMutated]);
                setLegendItems([...legendMutated]);
                setIsFirstRender(false);
            },
            [isFirstRender, currentLayer]
        );

        const handleCheckChildElement = (event: any) => {
            const legendMutated = legendItems;
            let legendItemIndex = 0;
            legendMutated.forEach((option: any) => {
                if (`${option.groupId}-${option.id}` === event.target.value) {
                    legendItemIndex = option.id - 1;
                    option.isChecked = event.target.checked;
                }
            });
            setLegendItems([...legendMutated]);
            changeLegendItemVisibility(legendItemIndex);
        };

        const handleChevronClick = (groupId: number) => {
            const groupsMutated = legendGroups;

            groupsMutated
                .filter((group: any) => group.id === groupId)
                .forEach((group) => {
                    if (group.isChecked) {
                        group.isExpanded = !group.isExpanded;
                    }
                });
            setLegendGroups([...groupsMutated]);
        };
        return (
            <div className={styles.legend}>
                <h3>Legend</h3>

                {legendGroups.map((item, index) => (
                    <div className={styles.legendCollapse} key={index}>
                        <div
                            onClick={() => handleChevronClick(item.id)}
                            className={cx(styles.legendCollapseHead, {
                                [styles.legendCollapseHeadOpen]: item.isExpanded,
                            })}
                        >
                            <input
                                type="radio"
                                className={styles.radio}
                                onChange={($event) => handleAllChecked($event, item.id)}
                                value="checkedall"
                                id={`${item.id}`}
                                disabled={item.isChecked}
                                checked={item.isChecked}
                            />
                            <label htmlFor={`${item.id}`}>{item.name}</label>
                        </div>
                        <div
                            className={cx(styles.legendCollapseBody, {
                                [styles.legendCollapseBodyOpen]: item.isExpanded,
                            })}
                        >
                            {/*<div className={styles.legendCollapseCaption}>{item.caption}</div>*/}
                            {legendItems
                                .filter((option: any) => option.groupId === item.id)
                                .map((option: any, index: any) => {
                                    return (
                                        <div
                                            key={index}
                                            className={`${item.colorScheme[currentLayer]}`}
                                        >
                                            <input
                                                type="checkbox"
                                                key={`${item.id}-${option.id}`}
                                                onChange={handleCheckChildElement}
                                                value={`${item.id}-${option.id}`}
                                                id={`${item.id}-${option.id}`}
                                                checked={option.isChecked}
                                            />
                                            <label htmlFor={`${item.id}-${option.id}`}>
                                                {option.value}
                                            </label>
                                        </div>
                                    );
                                })}
                        </div>
                    </div>
                ))}
            </div>
        );
    }
);

export default LegendBubble;
