import React, { useEffect } from 'react';
import styles from './SpendingBubble.module.scss';
import StatCircle from './StatCircle';
import cx from 'classnames';
import { circleRanges, filterTypes } from '../../../constants';

export interface CategoryProps {
    id: number;
    key: string;
    name: string;
    caption: string;
    data: { value: string }[];
}

interface Props {
    setFilterType: Function;
}

let FixWithoutRounding = (v: number, l: number) => {
    const intPart = Math.trunc(v).toString()
    const fractionPart = v.toString().slice(v.toString().indexOf('.') + 1)
    if (fractionPart.length > l) {
        return Number(intPart.concat('.', fractionPart.slice(0, l)))
    } else {
        const padded = intPart.concat('.', fractionPart.padEnd(l, '0'))
        return padded
    }
}

const SpendingBubble = React.forwardRef(
    ({ setFilterType }: Props, ref: any): React.ReactElement => {
        useEffect(() => {
            ref.current.handleCheckElement = handleCheckElement;
        });

        const categories = [
            {
                id: 1,
                key: filterTypes.UNEMPLOYMENT,
                name: 'Unemployment',
                caption: 'State Unemployment Rate (April 2022)',
                data: circleRanges[filterTypes.UNEMPLOYMENT],
            },
            {
                id: 2,
                key: filterTypes.POPULATION,
                name: 'Population',
                caption: 'State Population',
                data: circleRanges[filterTypes.POPULATION],
            },
            {
                id: 3,
                key: filterTypes.MINORITY,
                name: 'Minority Population %',
                caption: 'State Minority Population Percentage',
                data: circleRanges[filterTypes.MINORITY],
            },
        ];

        const [categoryContent, setCategoryContent] = React.useState<CategoryProps | null>();

        const handleCheckElement = (event: any) => {
            const selectedCategory: any = categories.find(
                (item) => item.id === +event?.target?.value
            );
            if (categoryContent?.key === selectedCategory?.key || !event) {
                setCategoryContent(null);
                setFilterType(null);
            } else {
                const mutatedCategory = [selectedCategory];
                setCategoryContent(mutatedCategory[0]);
                setFilterType(selectedCategory.key);
            }
        };

        return (
            <div className={styles.spending}>
                <h3>
                    Total Covid Spending <span>vs</span>
                </h3>

                {categories.map((item, index) => (
                    // eslint-disable-next-line react/jsx-key
                    <div className={cx(styles.spendingCategory)} key={index}>
                        <input
                            checked={item.id === categoryContent?.id}
                            type="checkbox"
                            value={item.id}
                            name="spending-category"
                            onChange={handleCheckElement}
                            id={`${item.id}`}
                        />
                        <label htmlFor={`${item.id}`}>{item.name}</label>
                    </div>
                ))}
                {categoryContent ? (
                    <h3 className={styles.spendingCategoryTitle}>{categoryContent.caption}</h3>
                ) : null}
                <ul className={styles.spendingContent}>
                    {categoryContent
                        ? categoryContent.data.map((row: any, index) => (
                            <React.Fragment key={index}>
                                <li className={styles.spendingContentRow}>
                                    <div className={styles.spendingContentCircles}>
                                        <StatCircle circleCount={index + 1} />
                                    </div>
                                    {row.min < 1 ? 1 : row.min}-{categoryContent.key === filterTypes.UNEMPLOYMENT ? FixWithoutRounding(row.max, 1) : row.max}
                                    {categoryContent.key === filterTypes.POPULATION ? 'M' : '%'}
                                </li>
                            </React.Fragment>
                        ))
                        : null}
                </ul>
            </div>
        );
    }
);

export default SpendingBubble;
