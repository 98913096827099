import React from 'react';
import styles from './InfoBubbles.module.scss';
import SubscribeBubble from './SubscribeBubble';
import LegendBubble from './LegendBubble';
import SpendingBubble from './SpendingBubble';

export interface InfoProps {
    children: React.ReactNode;
}

const InfoBubblesWrapper: React.FC<InfoProps> = React.memo(
    ({ children }: InfoProps): React.ReactElement => (
        <div className={styles.infoBubbleWrapper}>{children}</div>
    )
);

export { InfoBubblesWrapper, SubscribeBubble, LegendBubble, SpendingBubble };
