import React from 'react';
import Select from 'react-select';
import { OptionType } from '../../../types';
import { Reducers } from '../../../reducers';
import { getSelectOption } from '../../../utils';
import { useDispatch, useSelector } from 'react-redux';
import { DropdownIndicator } from '../../DropdownConfigs';
import selectStyles from '../../../constants/selectStyles';
import { setFilters, SpendingsFilters } from '../../../reducers/Spendings';
import { getIndustriesSelector } from '../../../selectors/getIndustriesSelector';

const SelectIndustryStyles: React.FC = React.memo(
    (): React.ReactElement => {
        const dispatch = useDispatch();
        const [value, setValue] = React.useState<OptionType>();
        const { industry } = useSelector(({ spendings }: Reducers) => spendings.filters);

        const industryOptions = useSelector((reducers: Reducers) =>
            getIndustriesSelector(reducers)
        );

        const onChange = React.useCallback(
            (value: OptionType | any) => {
                dispatch(
                    setFilters({
                        industry: value.value,
                    } as SpendingsFilters)
                );

                setValue(value);
            },
            [dispatch]
        );

        React.useEffect(() => {
            setValue(getSelectOption(industryOptions, industry));
        }, [industry, industryOptions]);

        const industryGroupedOptions = [
            {
                label: 'Industry',
                options: industryOptions,
            },
        ];

        return (
            <>
                <Select
                    name="color"
                    onChange={onChange}
                    isSearchable={false}
                    placeholder="INDUSTRY"
                    className="basic-single"
                    classNamePrefix="select"
                    value={value}
                    options={industryGroupedOptions}
                    components={{ DropdownIndicator }}
                    styles={selectStyles.industryStyles}
                    formatGroupLabel={(el: any) => (data: { label: string }): any => (
                        <>{data.label}</>
                    )}
                />
            </>
        );
    }
);

export default SelectIndustryStyles;
