import React from 'react';
import styles from './Footer.module.scss';

const Footer: React.FC = React.memo(
    (): React.ReactElement => {
        return (
            <footer>
                <div className="container">
                    <div className={styles.footerContainer}>
                        <div className={styles.copyright}>
                            &copy; {new Date().getFullYear()} <a href="https://www.pogo.org/">POGO</a>
                        </div>
                        <a className={styles.linkContact} href="mailto:covidtracker@pogo.org">
                            Contact us
                        </a>
                        <div className={styles.links}>
                            <a
                                href="https://www.pogo.org/about/privacy-policy/"
                                //target="_blank"
                                //rel="noopener noreferrer"
                            >
                                Privacy Policy
                            </a>
                        </div>
                    </div>
                </div>
            </footer>
        );
    }
);

export default Footer;
