import React from 'react';
import cx from 'classnames';
import styles from './Navbar.module.scss';
//import { deviceDetect } from '../../../utils/deviceDetect';

export interface NavbarProps {
    open: boolean;
    toggleNavbar: () => void;
}

const Navbar: React.FC<NavbarProps> = React.memo(
    ({ open, toggleNavbar }: NavbarProps): React.ReactElement => {
        //const { isMobile } = deviceDetect();

        return (
            <>
                <nav className={`${styles.menuWrapper} ${open ? styles.menuOpen : ''}`}>
                    <div className={styles.menuHeading}>
                        <button type="button" className={styles.menuClose} onClick={toggleNavbar}>
                            <span className={styles.menuCloseBox}>
                                <span className={styles.menuCloseInner}></span>
                            </span>
                        </button>
                    </div>

                    <div className={styles.menuBody}>
                        <ul id="menu" className={styles.menuMain}>
                            {[
                                [styles.iconOverview, '/', 'Home'],
                                //[styles.iconAssistance, '/#', 'Assistance Spending'],
                                //[styles.iconContracts, '/#', 'Contracts Spending'],
                                [styles.iconInformation, '/about-the-project', 'About the Project'],
                                [styles.iconMethodology, '/data-methodology', 'Methodology'],
                                /*[
                                    styles.iconAllIndustries,
                                    '/industry-overviews',
                                    'All Industries',
                                    '',
                                    isMobile ? 'mobile' : '',
                                ],*/
                                [styles.iconHelp, '/faq', 'FAQ'],
                            ].map(([icon, to, name, target, hideOn], index) => (
                                <React.Fragment key={index}>
                                    {hideOn !== 'mobile' && (
                                        <li className={styles.menuNavItem}>
                                            <a
                                                href={to as string}
                                                target={target || undefined}
                                                onClick={toggleNavbar}
                                            >
                                                <span className={`${styles.icon} ${icon}`}></span>
                                                {name}
                                            </a>
                                        </li>
                                    )}
                                </React.Fragment>
                            ))}
                        </ul>

                        <div className={styles.menuFooter}>
                            <h3>Know of something we should investigate now?</h3>
                            <p><a href="https://www.pogo.org/pogo-seeks-covid-19-tips/" rel="noopener noreferrer" target="_blank">Send us a tip</a> about the federal response to COVID-19.</p>
                        </div>
                        {/*
                        <ul
                            id="secondary-menu"
                            className={`${styles.menuMain} ${styles.menuSecondary}`}
                        >
                            {[
                                [
                                    styles.iconPossible,
                                    '/tool',
                                    "See What's Possible",
                                    '',
                                    isMobile ? 'mobile' : '',
                                ],
                                [styles.iconHelp, '/faq', 'FAQ'],
                            ].map(([icon, to, name, target, hideOn], index) => (
                                <React.Fragment key={index}>
                                    {hideOn !== 'mobile' && (
                                        <li className={styles.menuNavItem}>
                                            <a
                                                href={to as string}
                                                target={target || undefined}
                                                onClick={toggleNavbar}
                                            >
                                                <span className={`${styles.icon} ${icon}`}></span>
                                                {name}
                                            </a>
                                        </li>
                                    )}
                                </React.Fragment>
                            ))}
                            <li className={styles.menuModeSwitch}>
                                {[
                                    [styles.iconDark, '/#', 'Dark', null, true],
                                    [styles.iconLight, '/#', 'Light', null, false],
                                ].map(([icon, to, name, url, isActive], index) => (
                                    <React.Fragment key={index}>
                                        <a
                                            href={(to || url) as string}
                                            onClick={toggleNavbar}
                                            className={cx({
                                                [styles.menuModeSwitchActive]: isActive,
                                            })}
                                        >
                                            <span className={`${styles.icon} ${icon}`}></span>
                                            {name}
                                        </a>
                                    </React.Fragment>
                                ))}
                            </li>
                        </ul>
                        */}
                    </div>
                </nav>
                {
                    <div
                        onClick={toggleNavbar}
                        className={cx(styles.menuOverlay, {
                            [styles.menuOverlayActive]: open,
                        })}
                    ></div>
                }
            </>
        );
    }
);

export default Navbar;
