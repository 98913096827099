import { OptionType } from '../types';
import { Reducers } from '../reducers';
import { SearchType } from '../constants';
import { createSelector } from '@reduxjs/toolkit';
import { IndustriesOptions } from '../reducers/Filters';

export const getIndustriesSelector = createSelector(
    (state: Reducers) => state.filters.industries,
    (state: Reducers) => state.spendings.filters.searchType,
    (industries: IndustriesOptions, searchType: SearchType): OptionType[] => {
        return industries[searchType] || [];
    }
);
