import React from 'react';
import cx from 'classnames';
import { PC } from '../../types';
import { Link } from 'react-router-dom';
import styles from './About.module.scss';
import Footer from '../../components/Footer';

const AboutTheProject: PC = React.memo(() => {
    return (
        <>
            <div className="bg-solid bg-overwflow">
                <div className="scroll-content">
                    <div className={cx('container', styles.aboutPage)}>
                        <div className="row">
                            <div className="col-md-5">
                                <h2 className={styles.aboutPageTitle}>About the Site</h2>
                            </div>
                            <div className="col-md-6 offset-md-1">
                                <Link to="#"></Link>

                                <p>
                                    POGO endeavors to provide the most comprehensive and detailed picture of federal spending available by including the full range of grants, loans, and contracts, as well as relevant agency spending that is not currently captured by other reporting. This COVID Relief Spending Tracker uses millions of transaction and award records to present users with the means to follow the money as far as we can and answer the tough questions. Who got money? How much? From which agencies? What for?
                                </p>
                                <p>
                                    The COVID Relief Spending Tracker also features interactive maps to allow users to better explore the spending by location. Find out which states, cities, zip codes got more. Users can then compare those spending patterns with data on unemployment rates and population demographics to better understand if the money missed key communities. 
                                </p>
                                <p>
                                    Currently the COVID Relief Spending Tracker makes approximately 29.5 million transactions across dozens of agencies and hundreds of government programs available for public review. The site will periodically be updated with new data as well as improved features and functions.
                                </p>
                                <p>
                                    Please review our <Link to="/data-methodology">data methodology</Link> for details on our sources and methods. If you have other questions, please check our {' '}
                                    <Link to="/faq">FAQ page</Link>
                                    . If your question isn’t covered, please{' '}
                                    <a href="mailto:covidtracker@pogo.org" target="_self">
                                        contact us
                                        </a>{' '}
                                    with it. Also contact us with any problems you encounter using the tracker and any suggestions for improvements.
                                </p>
                            </div>
                        </div>
                    </div>

                    <Footer />
                </div>
            </div>
        </>
    );
});

export default AboutTheProject;
