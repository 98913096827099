import React, { useEffect } from 'react';
import cx from 'classnames';
import Sortable from '../Sortable';
import Transaction from '../Transaction';
import { Reducers } from '../../reducers';
import { TableColumn, SortType, SortDirection } from '../../types';
import { useDispatch, useSelector } from 'react-redux';
import { fetchData, setSort, SpendingsRow } from '../../reducers/Spendings';
import { useLocation } from 'react-router';
import { SearchType } from "../../constants";
import qs from 'qs';

const get = require('lodash/get');

interface Props {
	tableColumns: TableColumn<SpendingsRow>[];
}

const SpendingsTable: React.FC<Props> = React.memo(
	({ tableColumns }: Props): React.ReactElement => {
		const dispatch = useDispatch();
		const [activeRow, setActiveRow] = React.useState<number | null>();
		const [transactionDetails, setTransactionDetails] = React.useState<SpendingsRow | null>(
			null
		);

		const [isInit, setIsInit] = React.useState<boolean>(false);
		const location = useLocation();
		const { visibleColumns, result, sort } = useSelector(
			({ spendings }: Reducers) => spendings
		);
		const { searchType } = useSelector(
			({ spendings }: Reducers) => spendings.filters
		);
		const mappedResult = result.map((result) => {
			let amount = result.amount;
			if (amount) {
				if (amount.toString().includes('-')) {
					amount = amount.toString().replace('-', '-$');
				} else {
					amount = '$'.concat(amount.toString());
				}
			}
			return {
				...result,
				amount,
				state_name: result?.state_name?.toUpperCase(),
				type: result?.type?.toUpperCase(),
			};
		});

		useEffect(() => {
			if (!isInit && location.search.length) {
				const dataFromUrl: any = qs.parse(location.search.replace('?', ''));
				if (dataFromUrl.row_id) {
					const row: any = mappedResult.find(
						(el: SpendingsRow) => el.row_id === parseInt(dataFromUrl.row_id)
					);
					if (row) {
						setTransactionDetails(row);
					}
				}
				setIsInit(true);
			}
		}, [result]);

		const onSortChange = React.useCallback(
			(sort: SortType) => {
				dispatch(setSort({ sort }));

				dispatch(fetchData({}));
			},
			[dispatch]
		);

		const onGjfSortChange = (sort: SortType) => {
			if (sort[1] === -1) {
				sort[0] = 'gjf_ns'
			} else {
				sort[0] = 'gjf_nsv'
			}

			sort[1] = SortDirection.DESC;
			onSortChange(sort);
		}

		const toggleActiveRow = React.useCallback(
			(index: number) => {
				setActiveRow(index === activeRow ? null : index);
			},
			[activeRow]
		);

		const filteredColumns = tableColumns.filter(
			([label]: TableColumn<SpendingsRow>) =>
				visibleColumns.length === 0 || visibleColumns.indexOf(label) !== -1
		);
		return (
			<div className="table-responsive">
				<table className="table">
					<thead>
						<tr>
							{filteredColumns.map(
								(
									[label, , sortableField]: TableColumn<SpendingsRow>,
									index: number
								) => sortableField !== 'accIssues' ? (
									<th key={`${label}-${index}`}>
										{
											sortableField !== 'accIssues' && (
												sortableField ? (
													<Sortable
														sort={sort}
														label={label}
														field={sortableField}
														onChange={onSortChange}
													/>
												) : (
													label
												))
										}
									</th>
								) : (
										<th className="sticky-th">
											<Sortable
												sort={sort}
												label={"Accountability \nFlags"}
												field="gjf_nsv"
												onChange={onGjfSortChange}
											/>
										</th>
									)
							)}
						</tr>
					</thead>

					<tbody>
						{mappedResult.map((row: SpendingsRow, index: number) => {
							const isRowActive = index === activeRow;

							const yellowFlag = row.gjf_ns && !row.gjf_nsv;
							const redFlag = row.gjf_nsv;

							return (
								<tr
									key={index}
									onClick={(e: any) => {
										e.stopPropagation();
										setTransactionDetails(row);
									}}
									className={cx({
										active: isRowActive,
									})}
								>
									{filteredColumns.map(
										(
											[, keys, colKey]: TableColumn<SpendingsRow>,
											columnIndex: number
										) => {
											const key = Array.isArray(keys)
												? isRowActive
													? keys[1]
													: keys[0]
												: keys;

											return colKey !== 'accIssues' ? (
												<td key={`${index}${columnIndex}`}>
													{typeof key === 'function'
														? key(row)
														: get(row, key)
													}
												</td>
											) : (
												<td className="sticky-td">
													{(yellowFlag || redFlag) && (
															<div className="action-cell">
																<button type="button">
																	<span className={cx("icon", { "yellow": yellowFlag, "iconYellowFlag": yellowFlag, "iconRedFlag": redFlag })} />
																</button>
															</div>
														)
													}
												</td>
											)
										}
									)}
								</tr>
							);
						})}
					</tbody>
				</table>

				{transactionDetails !== null && (
					<Transaction
						transaction={transactionDetails}
						onClose={() => setTransactionDetails(null)}
					/>
				)}
			</div>
		);
	}
);

export default SpendingsTable;
