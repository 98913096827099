import React from 'react';
import cx from 'classnames';
import styles from './StatesSlider.module.scss';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
//import 'swiper/components/lazy/lazy.scss';
import { Reducers } from '../../reducers';
import { useDispatch, useSelector } from 'react-redux';
import { fetchStatesSpendings } from '../../reducers/Industry';
import { formatNumber } from '../../utils/formatNumber';

import { Swiper, SwiperSlide } from 'swiper/react';

import SwiperCore, { Navigation, Scrollbar, Lazy } from 'swiper';
import { Link } from 'react-router-dom';
SwiperCore.use([Navigation, Scrollbar, Lazy]);

interface SliderProps {
    industryId?: number;
}

const StatesSlider: React.FC<SliderProps> = React.memo(({ industryId }: SliderProps) => {
    const dispatch = useDispatch();
    const { statesData, statesNumbers } = useSelector(({ industry }: Reducers) => industry);

    React.useEffect(() => {
        dispatch(fetchStatesSpendings());
    }, [dispatch]);

    const getStateData = (state: string) => {
        return statesNumbers.filter((obj) => {
            return obj.state_abbr === state;
        });
    };

    const customSorting = (a: any, b: any) => {
        if (a[1].state_code === 'Non-US') return -1;
        if (a[1].name < b[1].name) {
            return -1;
        }
        if (a[1].name > b[1].name) {
            return 1;
        }
        return 0;
    }

    const states = Object.entries(statesData);
    states.sort(customSorting);
    const nonUs = states.filter((obj) => {
        return obj[1].state_code === 'Non-US';
    });
    if (nonUs.length > 0) {
        states.push(...nonUs);
        states.shift();
    }

    return (
        <>
            {states && states.length !== 0 ? (
                <Swiper
                    className={styles.slider}
                    scrollbar={{
                        draggable: true,
                        el: '.' + styles.scrollbar,
                        dragClass: styles.scrollbarDrag,
                        dragSize: 120,
                    }}
                    initialSlide={0}
                    navigation={{
                        nextEl: '.button-next',
                        prevEl: '.button-prev',
                        disabledClass: styles.disable,
                    }}
                    spaceBetween={10}
                    slidesPerView="auto"
                    preloadImages={false}
                    watchSlidesVisibility
                    lazy={{
                        loadPrevNext: true,
                        elementClass: styles.lazy,
                        loadingClass: styles.lazyLoading,
                        loadedClass: styles.lazyLoaded,
                        preloaderClass: styles.lazyPreloader,
                    }}
                >
                    {states?.map((state: any, index: number) => {
                        const stateValues = state[1];
                        const stateNumbers = getStateData(stateValues.state_code);
                        const notUsedStates = [
                            'AS',
                            'FM',
                            'GU',
                            'MH',
                            'MP',
                            'Non-US/UD',
                            'PR',
                            'PW',
                            'VI',
                        ];

                        if (notUsedStates.includes(stateValues.state_code)) {
                            return;
                        }

                        return (
                            <SwiperSlide key={index} className={styles.slide} data-hash="">
                                <div className={styles.slideInfo}>
                                    <div className={styles.slideImgContainer}>
                                        <img
                                            alt={`POGO - ${stateValues.name}`}
                                            data-src={`/img/states/${stateValues.state_code}.jpg`}
                                            className={styles.lazy}
                                        />
                                        <div className={styles.lazyPreloader}></div>
                                    </div>
                                    <h3>
                                        {stateValues.name.length <= 15
                                            ? stateValues.name
                                            : stateValues.state_code}
                                    </h3>
                                    <ul>
                                        <li>
                                            <span>Total Spent</span>
                                            <strong>
                                                ${formatNumber(stateNumbers[0]?.award_amount || 0)}
                                            </strong>
                                        </li>
                                        <li>
                                            <span># of Awards</span>
                                            <strong>
                                                {formatNumber(stateNumbers[0]?.award_count || 0)}
                                            </strong>
                                        </li>
                                    </ul>
                                </div>
                                <Link
                                    to={`/table-view?state[0]=${stateValues.state_code}&state[1]=${stateValues.name}&searchType=Assistance&industry=${industryId}&page=1&perPage=20&sort[0]=amount&sort[1]=-1`}
                                >
                                    <button className="btn btn-link">Explore data</button>
                                </Link>
                            </SwiperSlide>
                        );
                    })}

                    <div className={styles.navContainer}>
                        <div className={styles.nav}>
                            <div className={styles.scrollbar}>
                                <div className={styles.scrollbarDrag}></div>
                            </div>
                            <button className={cx('button-prev', styles.navLeft)}>
                                <span className="icon iconChevronLeft"></span>
                            </button>
                            <button className={cx('button-next', styles.navRight)}>
                                <span className="icon iconChevronRight"></span>
                            </button>
                        </div>
                    </div>
                </Swiper>
            ) : null}
        </>
    );
});

export default StatesSlider;
