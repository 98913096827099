import axios from 'axios';

export default {
    fetchStates() {
        return axios.get(`${process.env.REACT_APP_API_ENDPOINT}/states2`).then(({ data }) => data);
    },
    fetchCounties() {
        return axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/counties2`)
            .then(({ data }) => data);
    },
    fetchZip(state: string) {
        return axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/zipcodes2?state=${state}`)
            .then(({ data }) => data);
    },
};
