import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import MainMenu from '../MainMenu';
import HeaderLogo from './HeaderLogo';
import styles from './Header.module.scss';
import SocialButtons from '../SocialButtons';

interface Props {
    cls?: string | string[];
    children?: React.ReactNode;
}

const Header: React.FC<Props> = React.memo(
    ({ children, cls }: Props): React.ReactElement => {
        const [isSticky, setSticky] = useState(false);
        const ref = useRef<HTMLDivElement>(null);
        const handleScroll = () => {
            const offset = window.scrollY;

            if (offset >= 10) {
                setSticky(true);
            } else {
                setSticky(false);
            }
        };
        useEffect(() => {
            window.addEventListener('scroll', handleScroll);
            return () => {
                window.removeEventListener('scroll', () => handleScroll);
            };
        }, []);

        return (
            <header className={cx('header', styles.header, { ['sticky']: isSticky })} ref={ref}>
                <div className="container">
                    <div className="row">
                        <div className="col">
                            <nav
                                className={cx(
                                    styles.navbar,
                                    ...(Array.isArray(cls) ? cls : [cls || ''])
                                )}
                            >
                                <div className={styles.navbarLeft}>
                                    <HeaderLogo />
                                </div>

                                <div className={styles.navbarCenter}>{children}</div>

                                <div className={styles.navbarRight}>
                                    <SocialButtons />

                                    <MainMenu />
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        );
    }
);

export default Header;
