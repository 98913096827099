import { components } from 'react-select';
import React from 'react';

const _arrowMenu = () => ({
    ':before': {
        content: '" "',
        display: 'block',
        height: 0,
        width: 0,
        right: 14,
        top: -8,
        position: 'absolute',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: '8px solid #0E1620',
    },
});

const _controlHover = () => ({
    borderRadius: 0,
    ':hover': {
        borderColor: 'transparent',
        boxShadow: 'none',
    },
    ':focus': {
        borderColor: 'transparent',
        boxShadow: 'none',
    },
});

const _optionCheckboxActive = () => ({
    ':active': {
        backgroundColor: 'transparent',
    },
});

const _pseudoCheckbox = () => ({
    ':before': {
        content: '" "',
        display: 'block',
        height: 16,
        width: 16,
        left: 16,
        top: 7,
        border: '1px solid #A4A9BF',
        borderRadius: 2,
        position: 'absolute',
        backgroundColor: 'transparent',
    },
});

const _pseudoCheckboxActive = () => ({
    ':before': {
        content: '" "',
        display: 'block',
        height: 16,
        width: 16,
        left: 16,
        top: 7,
        borderRadius: 2,
        position: 'absolute',
        border: '1px solid #00D1FF',
        backgroundColor: '#00D1FF',
    },
    ':after': {
        content: '" "',
        display: 'block',
        height: 16,
        width: 16,
        top: 7,
        left: 16,
        position: 'absolute',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(/img/tilda.svg)',
    },
});

const placeholderActive = () => ({
    color: '#00D1FF',
});

const CheckboxDropdownStyles = {
    control: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: 'transparent',
        boxShadow: isDisabled ? null : isSelected ? null : isFocused ? null : null,
        border: '0 none',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ..._controlHover(),
    }),

    input: (styles: any) => ({
        ...styles,
    }),

    placeholder: (styles: any, { isFocused }: any) => ({
        ...styles,
        //color: '#fff',
        marginLeft: null,
        marginRight: null,
        ...(isFocused && { ...placeholderActive() }),
    }),

    singleValue: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        color: isDisabled ? 'red' : isSelected ? 'red' : isFocused ? 'yellow' : 'white',
    }),

    valueContainer: (styles: any) => ({
        ...styles,
        padding: '7px 4px 7px 8px',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    dropdownIndicator: () => ({
        padding: null,
        display: 'flex',
    }),

    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#0E1620',
        borderRadius: 5,
        boxShadow: null,
        ..._arrowMenu(),
    }),

    menuList: (styles: any) => ({
        ...styles,
        color: '#fff',
        padding: '8px 0',
    }),

    groupHeading: (styles: any) => ({
        ...styles,
        color: '#fff',
        paddingTop: 8,
        paddingBottom: 3,
        paddingLeft: 16,
        paddingRight: 16,
        opacity: 0.75,
    }),

    group: (styles: any) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),

    option: (styles: any, { isSelected }: any) => ({
        ...styles,
        backgroundColor: null,
        padding: '8px 16px 8px 40px',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        position: 'relative',
        ..._pseudoCheckbox(),
        ..._optionCheckboxActive(),
        ...(isSelected && { ..._pseudoCheckboxActive() }),
    }),
};

const DropdownIndicator = (props: any) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <span className="icon iconChevronDown"></span>
            </components.DropdownIndicator>
        )
    );
};

const _optionActive = () => ({
    ':active': {
        backgroundColor: 'rgba(0,209,255,0.4)',
    },
});

const SimpleDropdownStyles = {
    control: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: '#0E1620',
        //borderRadius: '4px',
        boxShadow: isDisabled ? null : isSelected ? null : isFocused ? null : null,
        border: '0 none',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ..._controlHover(),
    }),

    input: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),

    placeholder: (styles: any) => ({
        ...styles,
        color: '#fff',
        marginLeft: null,
        marginRight: null,
    }),

    singleValue: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),

    valueContainer: (styles: any) => ({
        ...styles,
        padding: '7px 0px 7px 16px',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    dropdownIndicator: () => ({
        padding: '13px 16px',
        display: 'flex',
    }),

    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#0E1620',
        borderRadius: 5,
        boxShadow: null,
        marginTop: 4,
    }),

    menuList: (styles: any) => ({
        ...styles,
        color: '#fff',
        padding: '8px 0',
    }),

    groupHeading: (styles: any) => ({
        ...styles,
        color: '#fff',
        paddingTop: 8,
        paddingBottom: 3,
        paddingLeft: 16,
        paddingRight: 16,
        opacity: 0.75,
    }),

    group: (styles: any) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),

    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
            ? 'rgba(0,209,255,0.4)'
            : isFocused
            ? 'rgba(0,209,255,0.4)'
            : null,
        padding: '8px 16px',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ..._optionActive(),
    }),
};

const _seachIcon = () => ({
    position: 'relative',
    ':before': {
        content: '" "',
        display: 'block',
        height: 16,
        width: 16,
        left: 16,
        top: 'calc(50% - 8px)',
        position: 'absolute',
        backgroundImage: 'url(/img/search.svg)',
    },
});

const SearchDropdownStyles = {
    control: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: '#0E1620',
        //borderRadius: '4px',
        boxShadow: isDisabled ? null : isSelected ? null : isFocused ? null : null,
        border: '0 none',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ..._seachIcon(),
        ..._controlHover(),
    }),

    input: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        color: isDisabled ? '#ff0' : isSelected ? '#ff0' : isFocused ? '#ff0' : '#fff',
    }),

    placeholder: (styles: any) => ({
        ...styles,
        color: '#fff',
        marginLeft: null,
        marginRight: null,
    }),

    singleValue: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),

    valueContainer: (styles: any) => ({
        ...styles,
        padding: '7px 4px 7px 39px',
        whiteSpace: 'no-wrap',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    indicatorsContainer: () => ({
        display: 'none',
    }),

    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#0E1620',
        borderRadius: 5,
        boxShadow: null,
        marginTop: 4,
    }),

    menuList: (styles: any) => ({
        ...styles,
        color: '#fff',
        padding: '8px 0',
    }),

    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
            ? 'rgba(0,209,255,0.4)'
            : isFocused
            ? 'rgba(0,209,255,0.4)'
            : null,
        padding: '8px 16px',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ..._optionActive(),
    }),
};

export { CheckboxDropdownStyles, DropdownIndicator, SimpleDropdownStyles, SearchDropdownStyles };
