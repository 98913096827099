const spendingColors = ['#00D1FF', '#02AAD4', '#0483A8', '#065D7D', '#083651', '#062436'];
const minorityColors = ['#18E670', '#15BB61', '#129052', '#106544', '#0D3A35', '#092623'];
const populationColors = ['#CE5B2E', '#9D482C', '#9D482C', '#6C352A', '#3B2228', '#28171b'];
const unemploymentColors = ['#AF2BE3', '#8E25BD', '#6D2097', '#4C1A72', '#2B154C', '#1e0e38'];
const assistanceColors = ['#23C2B5', '#1E9E98', '#197A7C', '#15575F', '#0F3343', '#0b2531'];
const contractColors = ['#2B59C3', '#244AA3', '#1E3B84', '#172D65', '#111E45', '#091024'];
const pppColors = ['#FFCA0F', '#CEA514', '#9D7F18', '#6C5A1D', '#3B3421', '#211d13'];
const pcsColors = ['#ADFF00', '#8FD105', '#72A30B', '#547610', '#374817', '#4B6F17', '#2B3F1E', '#1A2722'];
const unemploymentPColors = ['#FF31AC', '#CE2A91', '#9D2376', '#6C1D5C', '#3B1641', '#270f2b'];
const unemploymentOctColors = ['#7CC8FF', '#68A5D1', '#5482A4', '#416076', '#2D3D49', '#38597D', '#213451', '#15223C'];
const unemploymentJan20Colors = ['#897CFF', '#7368D1', '#5C55A4', '#464176', '#2F2E49', '#3D3B7D', '#232551', '#171A3C'];
const unemploymentJanColors = ['#FF5D5D', '#D15050', '#A34243', '#753535', '#472728', '#6C2E3C', '#3B1F31', '#23172B'];
const unemploymentAprColors = ['#FFADC8', '#D190A5', '#A37283', '#755560', '#47373E', '#241d20'];
const unemploymentJulColors = ['#E9977A', '#BF7E67', '#966554', '#6C4C41', '#43332E', '#302825'];
const unemploymentOct21Colors = ['#866B43', '#866B43', '#5A4B33', '#453A2B', '#2F2A23'];
const unemploymentJan22Colors = ['#FFEE97', '#D1C37E', '#A39965', '#756F4D', '#474434'];
const unemploymentApr22Colors = ['#AACF7B', '#8DAB68', '#708755', '#536241', '#363E2E'];

// Selection fields
export const stateFields = [
    {
        name: 'total_spending',
        label: 'Total COVID Spending',
        fillColor: [
            'step',
            ['get', 'total_spending'],
            spendingColors[0],
            12500000000,
            spendingColors[1],
            24500000000,
            spendingColors[2],
            42500000000,
            spendingColors[3],
            81700000000,
            spendingColors[4],
            425000000000,
            spendingColors[5],
        ],
        ranges: [
            [81700000000, 425000000000],
            [42500000000, 81699999999],
            [24500000000, 42499999999],
            [12500000000, 24499999999],
            [25100000, 12499999999],
        ],
    },
    {
        name: 'assistance_spending',
        label: 'Assistance COVID Spending',
        fillColor: [
            'step',
            ['get', 'assistance_spending'],
            assistanceColors[0],
            12300000000,
            assistanceColors[1],
            24400000000,
            assistanceColors[2],
            42100000000,
            assistanceColors[3],
            71300000000,
            assistanceColors[4],
            419000000000,
            assistanceColors[5],
        ],
        ranges: [
            [71300000000, 419000000000],
            [42100000000, 71299999999],
            [24400000000, 42099999999],
            [12300000000, 22499999999],
            [25100000, 12299999999],
        ],
    },
    {
        name: 'contract_spending',
        label: 'Contracts Spending',
        fillColor: [
            'step',
            ['get', 'contract_spending'],
            contractColors[0],
            38000000,
            contractColors[1],
            310000000,
            contractColors[2],
            581000000,
            contractColors[3],
            2350000000,
            contractColors[4],
            29200000000,
            contractColors[5],
        ],
        ranges: [
            [2350000000, 29200000000],
            [581000000, 2349999999],
            [310000000, 580999999],
            [38000000, 309999999],
            [0, 37999999],
        ],
    },
    {
        name: 'ppp_loan',
        label: 'PPP Spending',
        fillColor: [
            'step',
            ['get', 'ppp_loan'],
            pppColors[0],
            2520000000,
            pppColors[1],
            4520000000,
            pppColors[2],
            9980000000,
            pppColors[3],
            18100000000,
            pppColors[4],
            104000000000,
            pppColors[5],
        ],
        ranges: [
            [18100000000, 104000000000],
            [9980000000, 18099999999],
            [4520000000, 9979999999],
            [2520000000, 4519999999],
            [0, 2519999999],
        ],
    },
    {
        name: 'per_capita_spending',
        label: 'Per Capita Spending',
        fillColor: [
            'step',
            ['get', 'per_capita_spending'],
            pcsColors[0],
            8120,
            pcsColors[1],
            9370,
            pcsColors[2],
            10300,
            pcsColors[3],
            11700,
            pcsColors[4],
            25900,
            pcsColors[5],
        ],
        ranges: [
            [11700, 25900],
            [10300, 11699],
            [9370, 10299],
            [8120, 9369],
            [0, 8119],
        ],
    },
    {
        name: 'unemployment_jan_20',
        label: 'Unemployment (January 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_jan_20'],
            unemploymentJan20Colors[0],
            2.88,
            unemploymentJan20Colors[1],
            3.49,
            unemploymentJan20Colors[2],
            4.07,
            unemploymentJan20Colors[3],
            4.42,
            unemploymentJan20Colors[4],
            6.58,
            unemploymentJan20Colors[5],
        ],
        ranges: [
            [4.42, 6.58],
            [4.07, 4.42],
            [3.49, 4.06],
            [2.88, 3.48],
            [0, 2.87],
        ],
    },
    {
        name: 'unemployment_apr_20',
        label: 'Unemployment (April 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_apr_20'],
            unemploymentColors[0],
            9.1,
            unemploymentColors[1],
            11.6,
            unemploymentColors[2],
            13.7,
            unemploymentColors[3],
            16.1,
            unemploymentColors[4],
            30.1,
            unemploymentColors[5],
        ],
        ranges: [
            [16.1, 30.1],
            [13.7, 16.09],
            [11.6, 13.69],
            [9.1, 11.59],
            [0, 9.09],
        ],
    },
    {
        name: 'unemployment_jul_20',
        label: 'Unemployment (July 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_jul_20'],
            unemploymentPColors[0],
            5.07,
            unemploymentPColors[1],
            7.64,
            unemploymentPColors[2],
            9.06,
            unemploymentPColors[3],
            10.9,
            unemploymentPColors[4],
            16.3,
            unemploymentPColors[5],
        ],
        ranges: [
            [10.9, 16.3],
            [9.06, 10.89],
            [7.64, 9.05],
            [5.07, 7.63],
            [0, 5.06],
        ],
    },
    {
        name: 'unemployment_oct_20',
        label: 'Unemployment (October 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_oct_20'],
            unemploymentOctColors[0],
            3.62,
            unemploymentOctColors[1],
            5.07,
            unemploymentOctColors[2],
            5.89,
            unemploymentOctColors[3],
            7.2,
            unemploymentOctColors[4],
            14.2,
            unemploymentOctColors[5],
        ],
        ranges: [
            [7.2, 14.2],
            [5.89, 7.19],
            [5.07, 5.88],
            [3.62, 5.06],
            [0, 3.61],
        ],
    },
    {
        name: 'unemployment_jan_21',
        label: 'Unemployment (January 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_jan_21'],
            unemploymentJanColors[0],
            3.95,
            unemploymentJanColors[1],
            5.1,
            unemploymentJanColors[2],
            6.29,
            unemploymentJanColors[3],
            7.66,
            unemploymentJanColors[4],
            9.9,
            unemploymentJanColors[5],
        ],
        ranges: [
            [7.66, 9.9],
            [6.29, 7.65],
            [5.1, 6.28],
            [3.95, 5.09],
            [0, 3.94],
        ],
    },
    {
        name: 'unemployment_apr_21',
        label: 'Unemployment (April 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_apr_21'],
            unemploymentAprColors[0],
            2.98,
            unemploymentAprColors[1],
            4.16,
            unemploymentAprColors[2],
            5.43,
            unemploymentAprColors[3],
            6.52,
            unemploymentAprColors[4],
            8.1,
            unemploymentAprColors[5],
        ],
        ranges: [
            [6.52, 8.1],
            [5.43, 6.51],
            [4.16, 5.42],
            [2.98, 4.15],
            [0, 2.97],
        ],
    },
    {
        name: 'unemployment_jul_21',
        label: 'Unemployment (July 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_jul_21'],
            unemploymentJulColors[0],
            2.98,
            unemploymentJulColors[1],
            4.16,
            unemploymentJulColors[2],
            5,
            unemploymentJulColors[3],
            6.45,
            unemploymentJulColors[4],
            9.2,
            unemploymentJulColors[4],
        ],
        ranges: [
            [6.45, 9.2],
            [5, 6.44],
            [4.16, 4.99],
            [2.98, 4.15],
            [0, 2.97],
        ],
    },
    {
        name: 'unemployment_oct_21',
        label: 'Unemployment (October 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_oct_21'],
            unemploymentOct21Colors[0],
            2.38,
            unemploymentOct21Colors[1],
            3.22,
            unemploymentOct21Colors[2],
            4.05,
            unemploymentOct21Colors[3],
            4.84,
            unemploymentOct21Colors[4],
            7.1,
            unemploymentOct21Colors[4],
        ],
        ranges: [
            [4.84, 7.1],
            [4.05, 4.83],
            [3.22, 4.04],
            [2.38, 3.21],
            [0, 2.37],
        ],
    },
    {
        name: 'unemployment_jan_22',
        label: 'Unemployment (January 2022)',
        fillColor: [
            'step',
            ['get', 'unemployment_jan_22'],
            unemploymentJan22Colors[0],
            2.78,
            unemploymentJan22Colors[1],
            3.5,
            unemploymentJan22Colors[2],
            4.35,
            unemploymentJan22Colors[3],
            5.03,
            unemploymentJan22Colors[4],
            6.4,
            unemploymentJan22Colors[4],
        ],
        ranges: [
            [5.03, 6.4],
            [4.35, 5.02],
            [3.5, 4.34],
            [2.78, 3.49],
            [0, 2.77],
        ],
    },
    {
        name: 'unemployment_apr_22',
        label: 'Unemployment (April 2022)',
        fillColor: [
            'step',
            ['get', 'unemployment_apr_22'],
            unemploymentApr22Colors[0],
            2.1,
            unemploymentApr22Colors[1],
            2.6,
            unemploymentApr22Colors[2],
            3.4,
            unemploymentApr22Colors[3],
            3.73,
            unemploymentApr22Colors[4],
            5.8,
            unemploymentApr22Colors[4],
        ],
        ranges: [
            [3.73, 5.8],
            [3.4, 3.72],
            [2.6, 3.39],
            [2.1, 2.59],
            [0, 2.09],
        ],
    },
    {
        name: 'population',
        label: 'Population',
        fillColor: [
            'step',
            ['get', 'population'],
            populationColors[0],
            756000,
            populationColors[1],
            2590000,
            populationColors[2],
            4760000,
            populationColors[3],
            7800000,
            populationColors[4],
            39600000,
            populationColors[5],
        ],
        ranges: [
            [7800000, 39600000],
            [4760000, 7799999],
            [2590000, 4759999],
            [756000, 2589999],
            [0, 755999],
        ],
    },
    {
        name: 'mp_total',
        label: 'Minority Population Percentage',
        fillColor: [
            'step',
            ['get', 'mp_total'],
            minorityColors[0],
            12.9,
            minorityColors[1],
            20.8,
            minorityColors[2],
            32.1,
            minorityColors[3],
            44.2,
            minorityColors[4],
            99.2,
            minorityColors[4],
        ],
        ranges: [
            [44.2, 99.2],
            [32.1, 44.19],
            [20.8, 32.09],
            [12.9, 20.79],
            [0, 12.89],
        ],
    },
];

export const countyFields = [
    {
        name: 'total_spending',
        label: 'Total COVID Spending',
        fillColor: [
            'step',
            ['get', 'total_spending'],
            spendingColors[0],
            29100000,
            spendingColors[1],
            60100000,
            spendingColors[2],
            116000000,
            spendingColors[3],
            320000000,
            spendingColors[4],
            77300000000,
            spendingColors[5],
        ],
        ranges: [
            [320000000, 77300000000],
            [116000000, 319999999],
            [60100000, 115999999],
            [29100000, 60099999],
            [0, 29099999],
        ],
    },
    {
        name: 'assistance_spending',
        label: 'Assistance COVID Spending',
        fillColor: [
            'step',
            ['get', 'assistance_spending'],
            assistanceColors[0],
            28900000,
            assistanceColors[1],
            59700000,
            assistanceColors[2],
            115000000,
            assistanceColors[3],
            310000000,
            assistanceColors[4],
            77200000000,
            assistanceColors[5],
        ],
        ranges: [
            [310000000, 77200000000],
            [115000000, 309999999],
            [59700000, 114999999],
            [28900000, 59699999],
            [0, 28899999],
        ],
    },
    {
        name: 'contract_spending',
        label: 'Contracts Spending',
        fillColor: [
            'step',
            ['get', 'contract_spending'],
            contractColors[0],
            64800,
            contractColors[1],
            656000,
            contractColors[2],
            10500000,
            contractColors[3],
            22200000000,
            contractColors[4],
        ],
        ranges: [
            [10500000, 22200000000],
            [656000, 10499999],
            [64800, 655999],
            [1, 64799],
            [0, 0],
        ],
    },
    {
        name: 'ppp_loan',
        label: 'PPP Spending',
        fillColor: [
            'step',
            ['get', 'ppp_loan'],
            pppColors[0],
            13300000,
            pppColors[1],
            29100000,
            pppColors[2],
            60900000,
            pppColors[3],
            168000000,
            pppColors[4],
            29800000000,
            pppColors[5],
        ],
        ranges: [
            [168000000, 29800000000],
            [60900000, 167999999],
            [29100000, 60899999],
            [13300000, 29099999],
            [0, 13299999],

        ],
    },
    {
        name: 'per_capita_spending',
        label: 'Per Capita Spending',
        fillColor: [
            'step',
            ['get', 'per_capita_spending'],
            pcsColors[0],
            2000,
            pcsColors[1],
            2930,
            pcsColors[2],
            3950,
            pcsColors[3],
            5970,
            pcsColors[4],
            173000,
            pcsColors[5],
        ],
        ranges: [
            [5970, 173000],
            [3950, 5969],
            [2930, 3949],
            [2000, 2929],
            [0, 1999],
        ],
    },
    {
        name: 'unemployment_jan_20',
        label: 'Unemployment (January 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_jan_20'],
            unemploymentJan20Colors[0],
            3.2,
            unemploymentJan20Colors[1],
            3.9,
            unemploymentJan20Colors[2],
            4.6,
            unemploymentJan20Colors[3],
            5.8,
            unemploymentJan20Colors[4],
            20.3,
            unemploymentJan20Colors[5],
        ],
        ranges: [
            [5.8, 20.3],
            [4.6, 5.79],
            [3.9, 4.59],
            [3.2, 3.89],
            [0, 3.19],
        ],
    },
    {
        name: 'unemployment_apr_20',
        label: 'Unemployment (April 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_apr_20'],
            unemploymentColors[0],
            8.1,
            unemploymentColors[1],
            10.6,
            unemploymentColors[2],
            13.1,
            unemploymentColors[3],
            16.4,
            unemploymentColors[4],
            41,
            unemploymentColors[5],
        ],
        ranges: [
            [16.4, 41],
            [13.1, 16.39],
            [10.6, 13.09],
            [8.1, 10.59],
            [0, 8.09],
        ],
    },
    {
        name: 'unemployment_jul_20',
        label: 'Unemployment (July 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_jul_20'],
            unemploymentPColors[0],
            5.4,
            unemploymentPColors[1],
            6.8,
            unemploymentPColors[2],
            8.2,
            unemploymentPColors[3],
            10.1,
            unemploymentPColors[4],
            27,
            unemploymentPColors[5],
        ],
        ranges: [
            [10.1, 27],
            [8.2, 10.09],
            [6.8, 8.19],
            [5.4, 6.79],
            [0, 5.39],
        ],
    },
    {
        name: 'unemployment_oct_20',
        label: 'Unemployment (October 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_oct_20'],
            unemploymentOctColors[0],
            3.5,
            unemploymentOctColors[1],
            4.5,
            unemploymentOctColors[2],
            5.5,
            unemploymentOctColors[3],
            6.8,
            unemploymentOctColors[4],
            22.2,
            unemploymentOctColors[5],
        ],
        ranges: [
            [6.8, 22.2],
            [5.5, 6.79],
            [4.5, 5.49],
            [3.5, 4.49],
            [0, 3.49],
        ],
    },
    {
        name: 'unemployment_jan_21',
        label: 'Unemployment (January 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_jan_21'],
            unemploymentJanColors[0],
            4.1,
            unemploymentJanColors[1],
            5.1,
            unemploymentJanColors[2],
            6.1,
            unemploymentJanColors[3],
            7.6,
            unemploymentJanColors[4],
            21.4,
            unemploymentJanColors[5],
        ],
        ranges: [
            [7.6, 21.4],
            [6.1, 7.59],
            [5.1, 6.09],
            [4.1, 5.09],
            [0, 4.09],
        ],
    },
    {
        name: 'unemployment_apr_21',
        label: 'Unemployment (April 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_apr_21'],
            unemploymentAprColors[0],
            3.2,
            unemploymentAprColors[1],
            4,
            unemploymentAprColors[2],
            4.97,
            unemploymentAprColors[3],
            6.2,
            unemploymentAprColors[4],
            21.6,
            unemploymentAprColors[5],
        ],
        ranges: [
            [6.2, 21.6],
            [4.97, 6.19],
            [4, 4.96],
            [3.2, 3.99],
            [0, 3.19],
        ],
    },
    {
        name: 'unemployment_jul_21',
        label: 'Unemployment (July 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_jul_21'],
            unemploymentJulColors[0],
            3.2,
            unemploymentJulColors[1],
            4.1,
            unemploymentJulColors[2],
            4.9,
            unemploymentJulColors[3],
            6.1,
            unemploymentJulColors[4],
            21.9,
            unemploymentJulColors[5],
        ],
        ranges: [
            [6.1, 21.9],
            [4.9, 6.09],
            [4.1, 4.89],
            [3.2, 4.09],
            [0, 3.19],
        ],
    },
    {
        name: 'unemployment_oct_21',
        label: 'Unemployment (October 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_oct_21'],
            unemploymentOct21Colors[0],
            2.4,
            unemploymentOct21Colors[1],
            3.2,
            unemploymentOct21Colors[2],
            3.8,
            unemploymentOct21Colors[3],
            4.7,
            unemploymentOct21Colors[4],
            16.9,
            unemploymentOct21Colors[4],
        ],
        ranges: [
            [4.7, 16.9],
            [3.8, 4.69],
            [3.2, 3.79],
            [2.4, 3.19],
            [0, 2.39],
        ],
    },
    {
        name: 'unemployment_jan_22',
        label: 'Unemployment (January 2022)',
        fillColor: [
            'step',
            ['get', 'unemployment_jan_22'],
            unemploymentJan22Colors[0],
            3,
            unemploymentJan22Colors[1],
            3.7,
            unemploymentJan22Colors[2],
            4.4,
            unemploymentJan22Colors[3],
            5.5,
            unemploymentJan22Colors[4],
            17.5,
            unemploymentJan22Colors[4],
        ],
        ranges: [
            [5.5, 17.5],
            [4.4, 5.49],
            [3.7, 4.39],
            [3, 3.69],
            [0, 2.99],
        ],
    },
    {
        name: 'unemployment_apr_22',
        label: 'Unemployment (April 2022)',
        fillColor: [
            'step',
            ['get', 'unemployment_apr_22'],
            unemploymentApr22Colors[0],
            2.1,
            unemploymentApr22Colors[1],
            2.7,
            unemploymentApr22Colors[2],
            3.3,
            unemploymentApr22Colors[3],
            4.2,
            unemploymentApr22Colors[4],
            16,
            unemploymentApr22Colors[4],
        ],
        ranges: [
            [4.2, 16],
            [3.3, 4.19],
            [2.7, 3.29],
            [2.1, 2.69],
            [0, 2.09],
        ],
    },
    {
        name: 'population',
        label: 'Population',
        fillColor: [
            'step',
            ['get', 'population'],
            populationColors[0],
            8900,
            populationColors[1],
            18600,
            populationColors[2],
            36700,
            populationColors[3],
            93200,
            populationColors[4],
            10100000,
            populationColors[5],
        ],
        ranges: [
            [93200, 10100000],
            [36700, 93199],
            [18600, 36699],
            [8900, 18599],
            [75, 8899],
        ],
    },
    {
        name: 'mp_total',
        label: 'Minority Population Percentage',
        fillColor: [
            'step',
            ['get', 'mp_total'],
            minorityColors[0],
            6.21,
            minorityColors[1],
            11.9,
            minorityColors[2],
            22.6,
            minorityColors[3],
            39.8,
            minorityColors[4],
            99.3,
            minorityColors[5],
        ],
        ranges: [
            [39.8, 99.3],
            [22.6, 39.79],
            [11.9, 22.59],
            [6.21, 11.89],
            [0, 6.2],
        ],
    },
];

export const zipFields = [
    {
        name: 'total_spending',
        label: 'Total COVID Spending',
        fillColor: [
            'step',
            ['get', 'total_spending'],
            spendingColors[0],
            795000,
            spendingColors[1],
            3110000,
            spendingColors[2],
            13100000,
            spendingColors[3],
            64400000,
            spendingColors[4],
            65700000000,
            spendingColors[5],
        ],
        ranges: [
            [64400000, 65700000000],
            [13100000, 64399999],
            [3110000, 13099999],
            [795000, 3109999],
            [0, 794999],
        ],
    },
    {
        name: 'assistance_spending',
        label: 'Assistance COVID Spending',
        fillColor: [
            'step',
            ['get', 'assistance_spending'],
            assistanceColors[0],
            793000,
            assistanceColors[1],
            3100000,
            assistanceColors[2],
            13000000,
            assistanceColors[3],
            63600000,
            assistanceColors[4],
            65600000000,
            assistanceColors[5],
        ],
        ranges: [
            [63600000, 65600000000],
            [13000000, 63599999],
            [3100000, 12999999],
            [793000, 3099999],
            [0, 792999],

        ],
    },
    {
        name: 'contract_spending',
        label: 'Contract Spending',
        fillColor: [
            'step',
            ['get', 'contract_spending'],
            contractColors[0],
            1,
            contractColors[1],
            46000,
            contractColors[2],
            304000,
            contractColors[3],
            2450000,
            contractColors[4],
            21400000000,
            contractColors[5],
        ],
        ranges: [
            [2450000, 21400000000],
            [304000, 2449999],
            [46000, 303999],
            [1, 45999],
            [0, 0],
        ],
    },
    {
        name: 'ppp_loan',
        label: 'PPP Spending',
        fillColor: [
            'step',
            ['get', 'ppp_loan'],
            pppColors[0],
            445000,
            pppColors[1],
            1820000,
            pppColors[2],
            7090000,
            pppColors[3],
            34600000,
            pppColors[4],
            1740000000,
            pppColors[5],
        ],
        ranges: [
            [34600000, 1740000000],
            [7090000, 34599999],
            [1820000, 7089999],
            [445000, 1819999],
            [0, 444999],
        ],
    },
    {
        name: 'per_capita_spending',
        label: 'Per Capita Spending',
        fillColor: [
            'step',
            ['get', 'per_capita_spending'],
            pcsColors[0],
            921,
            pcsColors[1],
            1810,
            pcsColors[2],
            2960,
            pcsColors[3],
            5150,
            pcsColors[4],
            49200000,
            pcsColors[5],
        ],
        ranges: [
            [5150, 49200000],
            [2960, 5149],
            [1810, 2959],
            [921, 1809],
            [0, 920],
        ],
    },
    {
        name: 'unemployment_jan_20',
        label: 'Unemployment (January 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_jan_20'],
            unemploymentJan20Colors[0],
            3.2,
            unemploymentJan20Colors[1],
            3.9,
            unemploymentJan20Colors[2],
            4.5,
            unemploymentJan20Colors[3],
            5.8,
            unemploymentJan20Colors[4],
            20.3,
            unemploymentJan20Colors[5],
        ],
        ranges: [
            [5.8, 20.3],
            [4.5, 5.79],
            [3.9, 4.49],
            [3.2, 3.89],
            [0, 3.19],
        ],
    },
    {
        name: 'unemployment_apr_20',
        label: 'Unemployment (April 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_apr_20'],
            unemploymentColors[0],
            9.6,
            unemploymentColors[1],
            12.3,
            unemploymentColors[2],
            14.6,
            unemploymentColors[3],
            17,
            unemploymentColors[4],
            41,
            unemploymentColors[5],
        ],
        ranges: [
            [17, 41],
            [14.6, 16.99],
            [12.3, 14.59],
            [9.6, 12.29],
            [0, 9.59],
        ],
    },
    {
        name: 'unemployment_jul_20',
        label: 'Unemployment (July 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_jul_20'],
            unemploymentPColors[0],
            6.3,
            unemploymentPColors[1],
            7.9,
            unemploymentPColors[2],
            9.6,
            unemploymentPColors[3],
            12.1,
            unemploymentPColors[4],
            27,
            unemploymentPColors[5],
        ],
        ranges: [
            [12.1, 27],
            [9.6, 12.09],
            [7.9, 9.59],
            [6.3, 7.89],
            [0, 6.29],
        ],
    },
    {
        name: 'unemployment_oct_20',
        label: 'Unemployment (October 2020)',
        fillColor: [
            'step',
            ['get', 'unemployment_oct_20'],
            unemploymentOctColors[0],
            4,
            unemploymentOctColors[1],
            5.1,
            unemploymentOctColors[2],
            6.1,
            unemploymentOctColors[3],
            7.3,
            unemploymentOctColors[4],
            22.2,
            unemploymentOctColors[5],
        ],
        ranges: [
            [7.3, 22.2],
            [6.1, 7.29],
            [5.1, 6.09],
            [4, 5.09],
            [0, 3.99],
        ],
    },
    {
        name: 'unemployment_jan_21',
        label: 'Unemployment (January 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_jan_21'],
            unemploymentJanColors[0],
            4.5,
            unemploymentJanColors[1],
            5.7,
            unemploymentJanColors[2],
            6.7,
            unemploymentJanColors[3],
            8.1,
            unemploymentJanColors[4],
            21.4,
            unemploymentJanColors[5],
        ],
        ranges: [
            [8.1, 21.4],
            [6.7, 8.09],
            [5.7, 6.69],
            [4.5, 5.69],
            [0, 4.49],
        ],
    },
    {
        name: 'unemployment_apr_21',
        label: 'Unemployment (April 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_apr_21'],
            unemploymentAprColors[0],
            3.6,
            unemploymentAprColors[1],
            4.6,
            unemploymentAprColors[2],
            5.5,
            unemploymentAprColors[3],
            6.7,
            unemploymentAprColors[4],
            18,
            unemploymentAprColors[5],
        ],
        ranges: [
            [6.7, 18],
            [5.5, 6.69],
            [4.6, 5.49],
            [3.6, 4.59],
            [0, 3.59],
        ],
    },
    {
        name: 'unemployment_jul_21',
        label: 'Unemployment (July 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_jul_21'],
            unemploymentJulColors[0],
            3.6,
            unemploymentJulColors[1],
            4.6,
            unemploymentJulColors[2],
            5.4,
            unemploymentJulColors[3],
            6.6,
            unemploymentJulColors[4],
            20.1,
            unemploymentJulColors[5],
        ],
        ranges: [
            [6.6, 20.1],
            [5.4, 6.59],
            [4.6, 5.39],
            [3.6, 4.59],
            [0, 3.59],
        ],
    },
    {
        name: 'unemployment_oct_21',
        label: 'Unemployment (October 2021)',
        fillColor: [
            'step',
            ['get', 'unemployment_oct_21'],
            unemploymentOct21Colors[0],
            2.7,
            unemploymentOct21Colors[1],
            3.4,
            unemploymentOct21Colors[2],
            4.1,
            unemploymentOct21Colors[3],
            5.1,
            unemploymentOct21Colors[4],
            16.9,
            unemploymentOct21Colors[4],
        ],
        ranges: [
            [5.1, 16.9],
            [4.1, 5.09],
            [3.4, 4.09],
            [2.7, 3.39],
            [0, 2.69],
        ],
    },
    {
        name: 'unemployment_jan_22',
        label: 'Unemployment (January 2022)',
        fillColor: [
            'step',
            ['get', 'unemployment_jan_22'],
            unemploymentJan22Colors[0],
            3.2,
            unemploymentJan22Colors[1],
            3.9,
            unemploymentJan22Colors[2],
            4.6,
            unemploymentJan22Colors[3],
            5.7,
            unemploymentJan22Colors[4],
            17.5,
            unemploymentJan22Colors[4],
        ],
        ranges: [
            [5.7, 17.5],
            [4.6, 5.69],
            [3.9, 4.59],
            [3.2, 3.89],
            [0, 3.19],
        ],
    },
    {
        name: 'unemployment_apr_22',
        label: 'Unemployment (April 2022)',
        fillColor: [
            'step',
            ['get', 'unemployment_apr_22'],
            unemploymentApr22Colors[0],
            2.3,
            unemploymentApr22Colors[1],
            2.9,
            unemploymentApr22Colors[2],
            3.5,
            unemploymentApr22Colors[3],
            4.4,
            unemploymentApr22Colors[4],
            13.7,
            unemploymentApr22Colors[4],
        ],
        ranges: [
            [4.4, 13.7],
            [3.5, 4.39],
            [2.9, 3.49],
            [2.3, 2.89],
            [0, 2.29],
        ],
    },
    {
        name: 'population',
        label: 'Population',
        fillColor: [
            'step',
            ['get', 'population'],
            populationColors[0],
            496,
            populationColors[1],
            1640,
            populationColors[2],
            4970,
            populationColors[3],
            17900,
            populationColors[4],
            123000,
            populationColors[5],
        ],
        ranges: [
            [17900, 123000],
            [4970, 17899],
            [1640, 4969],
            [496, 1639],
            [0, 495],
        ],
    },
    {
        name: 'mp_total',
        label: 'Minority Population Percentage',
        fillColor: [
            'step',
            ['get', 'mp_total'],
            minorityColors[0],
            3.04,
            minorityColors[1],
            7.79,
            minorityColors[2],
            18.4,
            minorityColors[3],
            40.9,
            minorityColors[4],
            100,
            minorityColors[5],
        ],
        ranges: [
            [40.9, 100],
            [18.4, 40.89],
            [7.79, 18.39],
            [3.04, 7.78],
            [0, 3.03],
        ],
    },
];
