import React, { useEffect } from 'react';
import Button from '../../Button';
import { OptionType } from '../../../types';
import styles from './Dropdown.module.scss';

const chunk = require('lodash/chunk');

export type ValueType = string | string[] | number | number[];

interface Props {
    value: ValueType;
    allOnEmpty?: boolean;
    options: OptionType[];
    applyChanges?: boolean;
    showSelectAll?: boolean;
    onApply?: (value: ValueType) => void;
    onClear?: () => void;
    onChange: (value: ValueType) => void;
}

const Dropdown: React.FC<Props> = React.memo(
    ({ options, showSelectAll, ...props }: Props): React.ReactElement => {

        const [value, setValue] = React.useState<any>(props.value);
        const isChecked = React.useCallback(
            (itemValue: number | string): boolean => {
                if (value && Array.isArray(value)) {
                    return value.indexOf(itemValue) !== -1;
                }

                return value === itemValue;
            },
            [value]
        );

        const onChange = React.useCallback(
            (itemValue: number | string): void => {
                let newValue: ValueType = value;

                if (isChecked(itemValue)) {
                    newValue = Array.isArray(value)
                        ? value.filter((value: number | string) => value !== itemValue)
                        : '';
                } else {
                    newValue = Array.isArray(value) ? [...value, itemValue] : itemValue;
                }

                setValue(newValue);

                if (!props.applyChanges) {
                    props.onChange(newValue);
                }
            },
            [value, setValue, props.onChange]
        );

        const allChecked = Array.isArray(value) && value.length === options.length;

        const toggleAll = React.useCallback((): void => {
            const newValue: any = allChecked
                ? []
                : options.map((option: OptionType) => option.value);

            setValue(newValue);

            if (!props.applyChanges) {
                props.onChange(newValue);
            }
        }, [options, allChecked, setValue, props.onChange]);

        const onApply = React.useCallback((): void => {
            props.onChange(value);

            if (typeof props.onApply === 'function') {
                props.onApply(value);
            }
        }, [value, props.onApply]);

        React.useEffect(() => {
            setValue(props.value);
        }, [props.value]);

        return (
            <div className={styles.dropdown}>
                {chunk(
                    [showSelectAll ? { value: 'select-all' } : null, ...options].filter((e) => e),
                    Math.ceil((options.length + (showSelectAll ? 1 : 0)) / 2)
                ).map((options: OptionType[], index: number) => (
                    <div className={styles.dropdownBlock} key={index}>
                        {options.map(({ value, label }: OptionType) => (
                            <React.Fragment key={value}>
                                {value === 'select-all' && (
                                    <div onClick={toggleAll} className={styles.dropdownItem}>
                                        <input
                                            type="checkbox"
                                            checked={allChecked}
                                            onChange={toggleAll}
                                            className={styles.checkboxSelectAll}
                                        />

                                        <label>
                                            <strong>Select All</strong>
                                        </label>
                                    </div>
                                )}

                                {value !== 'select-all' && (
                                    <div
                                        onClick={() => onChange(value)}
                                        className={styles.dropdownItem}
                                    >
                                        <input
                                            value={value}
                                            type="checkbox"
                                            checked={isChecked(value)}
                                            onChange={() => onChange(value)}
                                        />

                                        <label>{label}</label>
                                    </div>
                                )}
                            </React.Fragment>
                        ))}
                    </div>
                ))}

                {props.applyChanges && (
                    <div className={styles.dropdownButtons}>
                        <Button cls="btn-link mr-2" onClick={() => {
                            const disabledCols = ['County', 'Date of receiving', 'Industry'];
                            setValue(options
                                .filter((option: OptionType) => !disabledCols.includes(option.value as string))
                                .map((option: OptionType) => option.value as string))
                        }}>
                            CLEAR
                        </Button>

                        <Button
                            disabled={!value.length}
                            cls={`btn-primary ${!value.length ? 'btn-disabled' : ''}`}
                            onClick={onApply}
                        >
                            APPLY
                        </Button>
                    </div>
                )}
            </div>
        );
    }
);

export default Dropdown;
