import React, { useEffect } from 'react';
import cx from 'classnames';
import styles from './MapChart.module.scss';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../reducers';

const defaultChartData = [
    { label: 'Asian', key: 'mp_asian', value: 5.32 },
    { label: 'Black', key: 'mp_black', value: 3.41 },
    { label: 'Hispanic', key: 'mp_hispanic', value: 18.65 },
    { label: 'Other', key: 'mp_other', value: 12.17 },
    { label: 'White', key: 'mp_white', value: 60.44 },
];

const MppChart: React.FC = React.memo(
    (): React.ReactElement => {
        const [chartData, setChartData] = React.useState(defaultChartData);
        const { selectionData }: any = useSelector((state: Reducers) => state.geoData);

        useEffect(() => {
            if (selectionData) {
                const newChartData = JSON.parse(JSON.stringify(defaultChartData))
                    .map((data: { [key: string]: number | string }) => {
                        data.value = selectionData[data.key] ? selectionData[data.key] : 0;
                        return data;
                    })
                    .sort(
                        (
                            el1: { [key: string]: number | string },
                            el2: { [key: string]: number | string }
                        ) => {
                            if (el1.label < el2.label) {
                                return -1;
                            }
                            if (el1.label > el2.label) {
                                return 1;
                            }
                            return 0;
                        }
                    );
                setChartData(newChartData);
            } else {
                setChartData(defaultChartData);
            }
        }, [selectionData]);

        const totalAmt = React.useCallback(() => {
            return chartData
                .map((a) => a.value)
                .reduce((a, b) => {
                    return a + b;
                });
        }, [chartData]);

        return (
            <div className={cx(styles.mppChart)}>
                <div className={cx(styles.mppChartBar)}>
                    {chartData.map((group, index) => (
                        <div
                            key={index}
                            className={styles.mppChartBarElement}
                            style={{
                                width: group.value ? `${(group.value * 100) / totalAmt()}%` : '0.1%',
                            }}
                        ></div>
                    ))}
                </div>
                <div className={cx(styles.mppChartLegend)}>
                    {chartData.map((group, index) => (
                        <div key={index} className={cx(styles.mppChartLegendItem)}>
                            <div className={cx(styles.mppChartLegendItemVal)}>
                                {group.value ? ((group.value * 100) / totalAmt()).toFixed(1) : 0}%
                            </div>
                            <span>{group.label}</span>
                        </div>
                    ))}
                </div>
            </div>
        );
    }
);

export default MppChart;
