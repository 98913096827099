import React from 'react';
import { PC } from '../../types';
import FaqItem from '../../components/FaqItem';
import FaqMenu from '../../components/FaqMenu';
import { FAQItemType } from '../../components/FaqItem';
import { FAQMenuType } from '../../components/FaqMenu';
import Footer from '../../components/Footer';
import { deviceDetect } from '../../utils/deviceDetect';
import styles from './faq.module.scss';
import cx from 'classnames';

const faqCategories: FAQMenuType[] = [
    {
        id: 0,
        name: 'All',
    },
    {
        id: 1,
        name: 'Data questions',
    },
    {
        id: 2,
        name: 'Spending Explorer',
    },
];
const faqQuestions: FAQItemType[] = [
    {
        id: 101,
        question: `What are the sources of this data?`,
        answer: `The primary source of the spending data is USAspending.gov, though additional data for certain assistance programs is drawn from the Federal Reserve, the Small Business Administration, the Department of Health and Human Services, the Centers for Medicare & Medicaid Services, and the IRS. The data on population totals and minority percentage populations are drawn from the Census Bureau, and the unemployment data is from the Bureau of Labor Statistics. For details please review our data methodology <a href="/data-methodology">here</a>.`,
        longText: false,
        categoryId: 1,
    },
    {
        id: 102,
        question: `What is the methodology?`,
        answer: `You can view our data methodology <a href="/data-methodology">here</a>.`,
        longText: false,
        categoryId: 1,
    },
    {
        id: 103,
        question: `Does this tracker have all of the COVID-related spending made by the federal government?`,
        answer: `Congress has authorized more than $5.2 trillion in COVID19 emergency spending. This tracker seeks to provide the most comprehensive dataset of COVID spending based on specific transactions and awards. Our methodology identifies $3.5 trillion in federal spending, leaving $1.7 trillion unaccounted for. Limitations to the government data available that have prevented us from including certain types of spending include: \
            <ul>\
            <li>Spending on expanded unemployment insurance and required paid leave are not included because the government has not released usable data.</li>\
            <li>Tax expenditures are not included in this tracker because although the federal government has released estimates on the cost of these COVID relief-related tax breaks, there is no data on the specific recipients or geographic location of the awarded benefits.</li>\
            <li>There is also data that the government has released for which we have not yet determined a reliable methodology for including. For instance, the Department of Health and Human Services posts information on COVID-related awards on its website that overlaps with data from USAspending.gov. Since the agency’s data does not contain sufficient detail to resolve potential conflicts with USAspending.gov award data, it remains largely unused. Similarly, USAspending.gov recently launched new data fields to tag COVID-related spending. Because of observed inconsistencies, we have refrained from widespread use of the awards newly tagged as being related to COVID. We do not want to double count any spending or report spending amounts that are unrelated to the COVID crisis.</li>\
            <li>Finally, the tracker may be missing recent spending that was disclosed after our last data update.</li>\
            </ul>`,
        longText: true,
        categoryId: 1,
    },
    {
        id: 104,
        question: `Are there limitations to the data?`,
        answer: `There are three data limitations that should be noted.\
            \n\nFirst, much of the data pulled from sources other than USAspending.gov is missing key fields, including transaction dates, information on parent companies, award descriptions, and details on recipient locations like street addresses, county names, and congressional districts. These missing data elements limit how much this data can be searched, sorted, or mapped.\
            \n\nSecond, the government data sources include many transactions for which information has been withheld. For instance, the <a href="https://www.sba.gov/article/2020/jul/13/sba-treasury-announce-release-paycheck-protection-program-loan-data" target="_blank">administration has withheld</a> the names and street addresses for recipients of awards from programs such as the Disaster Assistance Loans program and Coronavirus Food Assistance Program. These withholdings make it much more difficult to fully evaluate the spending decisions of agencies and hold them accountable for their actions.\
            \n\nThird, some of the government data sources have known data quality problems. It has been <a href="https://ncrc.org/government-data-on-ppp-loans-is-mostly-worthless-but-its-not-too-late-to-fix-it/" target="_blank">widely reported</a> that the disclosed PPP data includes numerous records with serious errors including information filled into the wrong fields; blank fields; fields with errors, such as misspelled city or recipient names; and inconsistent formats for recipient names and locations. These problems can make certain records difficult to use in a tracker.`,
        longText: true,
        categoryId: 1,
    },
    {
        id: 105,
        question: `Can I download this data?`,
        answer: `Yes. You can download up to 500,000 records from your search results. This limitation is to help preserve bandwidth so the website is responsive to other users' searches. If you are interested in bulk downloads of spending data you might consider downloading source data from USAspending.gov and other government datasets used for this tracker.`,
        longText: false,
        categoryId: 1,
    },
    {
        id: 106,
        question: `What fields or columns are available to download from this data?`,
        answer: `Downloads use the complete set data fields available for each type of award. Unfortunately, we are not able to allow users to select or limit the fields that they receive in the downloaded file. Click here for a list of all available <a href="https://docs.pogo.org/document/2020/POGO_COVID19_Relief_Methodology_Assistance_Data_Fields.pdf" target="_blank">assistance data fields</a> and here for <a href="https://docs.pogo.org/document/2020/POGO_COVID19_Relief_Methodology_Contract_Data_Fields.pdf" target="_blank">contract data fields</a>.`,
        longText: false,
        categoryId: 1,
    },
    {
        id: 107,
        question: `I want to report an error in this data.`,
        answer: `We welcome any reports of specific errors or data problems. Please send us a message <a href="mailto:covidtracker@pogo.org">here</a>. This tracker presents largely unaltered data as disclosed by the federal government. Reported errors will be passed on federal agencies and appropriate congressional committees in hopes of fixing some of the problems in the data being disclosed.`,
        longText: false,
        categoryId: 1,
    },

    {
        id: 201,
        question: `Can I look up myself or someone in my neighborhood?`,
        answer: `No. You cannot see any specific payments to individuals or households in order to protect personal privacy. Direct assistance to households or individuals either has the recipient name withheld or is reported as part of aggregated summaries of awards made to an area, like a county or zip code. Any names you see listed as recipients are likely there because the recipient is using their name as their company name also. There are many businesses where this is fairly common: doctors, dentists, lawyers, farmers, insurance agents, mechanics, and real estate agents are a few examples.`,
        longText: false,
        categoryId: 2,
    },
    {
        id: 202,
        question: `Why are certain filters unavailable in the table view unless I enter a location or recipient name?`,
        answer: `The requirement to enter a location or recipient name in the table view searches before being allowed to access the other filters (award type, agency, program, industry sector, etc.) is an effort to avoid extended search times. As the number of records included in this tracker grew, larger searches for all awards from an agency or to an industry sector were requiring much longer processing times to complete. Requiring a location or recipient name helps limit the number of responsive records the site must return and sort. If these requirements impede your research, please <a href="mailto:covidtracker@pogo.org">contact us</a> to request assistance.`,
        longText: false,
        categoryId: 2,
    },
    {
        id: 203,
        question: `Can I see how much federal assistance reached unemployed individuals?`,
        answer: `No. The award data does not include information on whether or not the recipient is unemployed. However, the tracker provides information on the rate of unemployment for states and local areas. This should provide context to evaluate if awards were going to communities where unemployment was high or low.`,
        longText: false,
        categoryId: 2,
    },
    {
        id: 204,
        question: `Can I search for awards based on the number of jobs retained or created by companies that received COVID-19 relief funds?`,
        answer: `No. Unfortunately almost all of the award data does not include any information about the number of jobs saved, supported, or created. The data disclosed for Paycheck Protection Program (PPP) loans is the one exception. The PPP loan data includes the number of jobs saved, which is based on information provided by loan applicants. It should be noted that <a href="https://www.bloomberg.com/news/articles/2020-07-13/ppp-data-errors-raise-questions-about-effectiveness-of-stimulus" target="_blank">serious questions have been raised</a> about the reliability of the jobs data included in the PPP data as there are many instances when the numbers don’t make sense, including blank entries, zeros, very low employment numbers for large loans, or high employment numbers for small loans. The jobs information for each PPP loan is included in the detailed award data but it is not searchable on the website.`,
        longText: false,
        categoryId: 2,
    },
    {
        id: 205,
        question: `I found an award or recipient on another website about COVID spending but I can’t find them here. Why is it missing?`,
        answer: `If you are unable to find an award or recipient in our tracker that you found listed among COVID spending in another dataset or website there are a few possible explanations. First, it may be that the award data has changed. Federal agencies are regularly updating the data on their COVID spending. For example, they may update data when awarding new contracts or removing assistance recipients that have decided to decline previously awarded loans. Not all non-governmental data portals are being regularly updated to account for these changes. Second, it may be that the award you are looking for has been disclosed since our last data update and hasn’t yet been added to this tracker. Finally, the methodology we use may not recognize the award you are looking for as being related to COVID relief. For example, USAspending.gov has established a new Disaster Emergency Fund Code intended to track funds authorized through a relief law. While helpful, POGO has noted inconsistencies in the funds tracked by the code. As such, use of these new fields in identifying assistance spending for this tracker is limited at this time, which may result in certain awards being identified on USAspending.gov as being COVID-related but those awards not being included here.`,
        longText: true,
        categoryId: 2,
    },
    {
        id: 206,
        question: `Where do other map statistics (Unemployment, Population, And Minority Population Percentage) come from?`,
        answer: `The unemployment rate is pulled from the <a href="https://www.bls.gov/lau/" target="_blank">Local Area Unemployment Statistics</a> from the Bureau of Labor Statistics. The map displays the unemployment rates for four months each year -- January, April, July, and October for 2020, 2021, and January and April for 2022. These numbers provide a check point for each quarter and allow easy review of unemployment trends over time.<br/> <br/>The total population and minority population percentages are both pulled from the 2018 data for the 5 year <a href="https://www.census.gov/programs-surveys/acs" target="_blank">American Community Survey</a> from the Census Bureau. The ethinic demographic categories (Asian, Black, Latin, White) are for those that identify as a single racial ethnicity. The Other category includes those that listed more than one ethnicity, didn’t report an ethnicity, or reported an ethnicity that does not fit within the main categories used here. The Latin category consists of the population numbers that identified as hispanic or lantino even if they reported another ethnicity (white, black, asian). Those populations of latin individuals are removed from the other ethnicity categories to avoid double counting.`,
        longText: true,
        categoryId: 2,
    },
];

const Faq: PC = React.memo(() => {
    const [activeCategory, setActiveCategory] = React.useState<number>(0);
    const [activeItems, setActiveItems] = React.useState<number[]>([]);
    const { isMobile } = deviceDetect();

    const switchActiveCategory = React.useCallback(
        (id: number) => {
            if (id === activeCategory) return;

            setActiveCategory(id);

            setActiveItems([]);
        },
        [activeCategory, setActiveCategory, setActiveItems]
    );

    const toggleFaqItem = React.useCallback(
        (id: number) => {
            const newActiveItems: number[] = [...activeItems];
            const activeItemIndex = activeItems.indexOf(id);

            if (activeItemIndex !== -1) {
                newActiveItems.splice(activeItemIndex, 1);
            } else {
                newActiveItems.splice(0, 1, id);
            }

            setActiveItems(newActiveItems);
        },
        [activeItems, setActiveItems]
    );

    const handleScroll = (e: any) => {
        isMobile &&
            setTimeout(() => {
                e.target.scrollIntoView({ behavior: 'smooth', block: 'start' });
            }, 150);
    };

    return (
        <div className="bg-solid bg-overwflow">
            <div className="scroll-content">
                <div className={cx('container', styles.faqContainer)}>
                    <div className="row">
                        <div className="col-sm-3">
                            <FaqMenu
                                activeCategory={activeCategory}
                                categories={faqCategories}
                                onChange={switchActiveCategory}
                            />
                        </div>

                        <div className="col-sm-9">
                            <div className="row">
                                <div className="col mt-2">
                                    {faqQuestions
                                        .filter(
                                            (item) =>
                                                item.categoryId === activeCategory ||
                                                activeCategory === 0
                                        )
                                        .map((item: FAQItemType, index) => (
                                            <FaqItem
                                                key={index}
                                                item={item}
                                                onClick={(e) => {
                                                    toggleFaqItem(item.id);
                                                    handleScroll(e);
                                                }}
                                                isActive={activeItems.indexOf(item.id) !== -1}
                                            />
                                        ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <Footer />
            </div>
        </div>
    );
});

export default Faq;
