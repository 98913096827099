import { RequestState, NetworkStates } from '../types';

const get = require('lodash/get');

export type LoadingsReducerState = NetworkStates;

const initialState: LoadingsReducerState = {};

const requestStateMapping: { [key: string]: RequestState } = {
    pending: RequestState.Pending,
    source: RequestState.Pending,
    fulfilled: RequestState.Success,
    rejected: RequestState.Error,
};

const loadings = (state = initialState, action: any) => {
    const { type, meta } = action;
    const matches = /(.*)\/(pending|fulfilled|rejected|source|clearData|clearDataExceptSearchType)/.exec(
        type
    );
    // not a */pending / */fulfilled / */rejected actions, so we ignore them
    if (!matches) return state;

    const [, requestName, requestState] = matches;

    if (requestState === 'clearData' || requestState === 'clearDataExceptSearchType') {
        return initialState;
    }

    if (
        requestState === 'rejected' &&
        meta.requestId &&
        state[requestName] &&
        meta.requestId !== state[requestName].requestId
    ) {
        return state;
    }

    return {
        ...state,
        [requestName]: {
            state: requestStateMapping[requestState],
            error: requestState === 'rejected' ? get(action, 'error.message', '') : '',
            source: action?.payload?.source || null,
            requestId: meta.requestId,
        },
    };
};

export default loadings;
