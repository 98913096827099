import React, { useEffect } from 'react';
import styles from '../MapContainer.module.scss';
import Select, { components } from 'react-select';
import { stateFields } from '../../../constants/map';
import { useSelector } from 'react-redux';
import { Reducers } from '../../../reducers';

const DropdownIndicator = (props: any) => {
    return (
        components.DropdownIndicator && (
            <components.DropdownIndicator {...props}>
                <span className="icon iconChevronDown"></span>
            </components.DropdownIndicator>
        )
    );
};

const formatGroupLabel = (data: { label: any }): any => <>{data.label}</>;

const controlHover = () => ({
    borderRadius: 0,
    ':hover': {
        borderColor: 'transparent',
        boxShadow: 'none',
    },
    ':focus': {
        borderColor: 'transparent',
        boxShadow: 'none',
    },
});

const arrowMenu = () => ({
    ':before': {
        content: '" "',
        display: 'block',
        height: 0,
        width: 0,
        right: 10,
        top: -8,
        position: 'absolute',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: '8px solid #1E252E',
    },
});

const optionActive = () => ({
    ':active': {
        backgroundColor: 'rgba(0,209,255,0.4)',
    },
});

const simpleTransparentStyles: any = {
    control: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: 'transparent',
        boxShadow: isDisabled ? null : isSelected ? null : isFocused ? null : null,
        border: '0 none',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        fontWeight: 'bold',
        ...controlHover(),
    }),

    input: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),

    placeholder: (styles: any) => ({
        ...styles,
        color: 'white',
        marginLeft: null,
        marginRight: null,
    }),

    singleValue: (styles: any) => ({
        ...styles,
        color: 'white',
    }),

    valueContainer: (styles: any) => ({
        ...styles,
        padding: '7px 4px 7px 16px',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    dropdownIndicator: () => ({
        padding: null,
        display: 'flex',
        marginRight: 10,
    }),

    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#1E252E',
        borderRadius: 5,
        boxShadow: null,
        ...arrowMenu(),
    }),

    menuList: (styles: any) => ({
        ...styles,
        color: '#A4A9BF',
        padding: '8px 0',
    }),

    groupHeading: (styles: any) => ({
        ...styles,
        color: '#fff',
        paddingTop: 8,
        paddingBottom: 3,
        paddingLeft: 16,
        paddingRight: 16,
        opacity: 0.75,
    }),

    group: (styles: any) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),

    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
            ? 'rgba(0,209,255,0.4)'
            : isFocused
            ? null
            : null,
        color: isDisabled ? null : isSelected ? '#fff' : isFocused ? '#fff' : null,
        padding: '8px 16px',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ...optionActive(),
    }),
};

interface Props {
    onMapLayerChange: any;
}

const MapLayer: React.FC<Props> = React.memo(
    ({ onMapLayerChange }: Props): React.ReactElement => {
        const { currentLayer }: any = useSelector(({ mapData }: Reducers) => mapData);
        const [defaultOption, setDefaultOption] = React.useState<any>();
        const options = [
            {
                label: 'Map Layer',
                options: stateFields.map((field, index) => {
                    return { value: field.name, label: field.label, index };
                }),
            },
        ];

        useEffect(() => {
            const option = options[0].options.find((option, index) => index === currentLayer);
            setDefaultOption(option);
        }, [currentLayer]);

        return (
            <div className={styles.mapLayer}>
                {defaultOption !== undefined && (
                    <Select
                        options={options}
                        placeholder="Unemployment stats with covid funding"
                        classNamePrefix="select"
                        onChange={onMapLayerChange}
                        defaultValue={defaultOption}
                        value={defaultOption}
                        components={{ DropdownIndicator }}
                        name="federal-action-obligation"
                        isSearchable={false}
                        styles={simpleTransparentStyles}
                        formatGroupLabel={(el: any) => formatGroupLabel(el)}
                    />
                )}
            </div>
        );
    }
);

export default MapLayer;
