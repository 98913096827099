import { GeoDataType, HttpResponse } from '../../types';
import { SetDataFromUrlPayload } from '../Spendings';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const cloneDeep = require('lodash/cloneDeep');

export type GeoDataItem = [string, string];

export interface GeoDataReducerState {
    zip: string;
    state: GeoDataItem;
    county: GeoDataItem;
    coordinates: {
        lng: number | null;
        lat: number | null;
    };
    selectionData: HttpResponse | undefined;
    territory?: boolean | undefined;
}

export const initialState: GeoDataReducerState = {
    zip: '',
    state: ['', ''],
    county: ['', ''],
    coordinates: {
        lng: null,
        lat: null,
    },
    selectionData: undefined,
    territory: false,
};

const geoDataReducer = createSlice({
    name: 'geoData',
    initialState: cloneDeep(initialState),
    reducers: {
        setGeoData(state, { payload }: PayloadAction<GeoDataType>) {
            const newState = cloneDeep(initialState);

            Object.entries(newState).forEach(([key, value]: [string, any]) => (state[key] = value));
            if (payload.zip) {
                state.zip = payload.zip;
            } else if (payload.county) {
                if (payload.longNames) {
                    state.county = [payload.county, payload.longNames.county || payload.county];
                } else {
                    state.county = payload.county;
                }
            }

            if (payload.state) {
                if (payload.longNames) {
                    state.state = [payload.state, payload.longNames.state || payload.state];
                } else {
                    state.state = payload.state;
                }
            }

            if (payload.coordinates) {
                state.coordinates = payload.coordinates;
            }
            state.territory = payload.territory;
        },

        setSelectionData(state, { payload }: PayloadAction<HttpResponse | undefined>) {
            state.selectionData = payload;
        },

        setGeoDataFromUrl(state, { payload }: PayloadAction<SetDataFromUrlPayload>) {
            Object.keys(payload).forEach((key) => {
                if (Object.prototype.hasOwnProperty.call(state, key)) {
                    state[key] = payload[key];
                }
            });
        },

        clearGeoData(state) {
            const newState = cloneDeep(initialState);

            Object.entries(newState).forEach(([key, value]: [string, any]) => (state[key] = value));
        },
    },
});

export const {
    setGeoData,
    setSelectionData,
    setGeoDataFromUrl,
    clearGeoData,
} = geoDataReducer.actions;

export default geoDataReducer.reducer;
