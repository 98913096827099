import axios, { CancelTokenSource } from 'axios';

export default {
    fetch(endpoint: string, source: CancelTokenSource) {
        return axios.get(endpoint, { cancelToken: source.token }).then(({ data }) => data);
    },
    fetchGJF(transactionType: string, transaction: number) {
        return axios
            .get(`${process.env.REACT_APP_API_ENDPOINT}/goodjobsfirst?type=${transactionType}&type_id=${transaction}`)
            .then(({ data }) => data);
    },
};
