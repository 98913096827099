import Header from '..';
import React from 'react';
import { useHistory } from 'react-router-dom';
import styles from './IndustryHeader.module.scss';
import MapSearch from '../../MapContainer/MapSearch';
import DisplayMode from '../../DisplayMode';

const IndustryHeader: React.FC = React.memo(
    (): React.ReactElement => {
        const history = useHistory();

        const onSearchResultSelect = () => {
            history.push('/map-view');
        };

        return (
            <Header cls={styles.navbarBorder}>
                <div className={styles.searchRightContainer}>
                    <DisplayMode />

                    <div className={styles.searchBlock}>
                        <MapSearch selectionCallback={onSearchResultSelect} />
                    </div>
                </div>
            </Header>
        );
    }
);

export default IndustryHeader;
