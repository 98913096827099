import * as React from 'react';
import Layout from '../Layout';
import Faq from '../../pages/faq';
import Tool from '../../pages/tool';
import MapView from '../../pages/map-view';
import TableView from '../../pages/table-view';
import LandingPage from '../../pages/landing-page';
import { deviceDetect } from '../../utils/deviceDetect';
import DataMethodology from '../../pages/data-methodology';
import AboutTheProject from '../../pages/about-the-project';
import IndustryOverviews from '../../pages/industry-overviews';
import MobileLandingPage from '../../pages/mobile-landing-page';
import { Switch, Route, Redirect, RouteProps } from 'react-router-dom';
import IndustryDetailed from '../../pages/industry-overviews/industry-detailed';
import NotFoundPage from '../../pages/404';
import ScrollToTop from '../ScrollToTop';

const App: React.FC = (): React.ReactElement => {
    const [isMobile, setIsMobile] = React.useState<boolean>(false);

    React.useEffect(() => {
        setIsMobile(deviceDetect().isMobile);
    }, []);

    return (
        <Switch>
            <Route
                exact
                path="/"
                render={(props: RouteProps) => (
                    <Layout
                        pageProps={props}
                        Component={isMobile ? MobileLandingPage : LandingPage}
                    />
                )}
            />

            <Route
                exact
                path="/faq"
                render={(props: RouteProps) => <Layout pageProps={props} Component={Faq} />}
            />

            <Route
                exact
                path="/about-the-project"
                render={(props: RouteProps) => (
                    <Layout pageProps={props} Component={AboutTheProject} />
                )}
            />

            <Route
                exact
                path="/data-methodology"
                render={(props: RouteProps) => (
                    <Layout pageProps={props} Component={DataMethodology} />
                )}
            />

            {isMobile ? <Redirect to="/" /> : null}

            <Route
                exact
                path="/tool"
                render={(props: RouteProps) => <Layout pageProps={props} Component={Tool} />}
            />

            <Route
                exact
                path="/industry-overviews"
                render={(props: RouteProps) => (
                    <Layout pageProps={props} Component={IndustryOverviews} />
                )}
            />

            <Route
                exact
                path="/industry/:industry"
                render={(props: RouteProps) => (
                    <React.Fragment>
                        <ScrollToTop />
                        <Layout pageProps={props} Component={IndustryDetailed} />
                    </React.Fragment>
                )}
            />

            <Route
                exact
                path="/map-view"
                render={(props: RouteProps) => <Layout pageProps={props} Component={MapView} />}
            />

            <Route
                exact
                path="/table-view"
                render={(props: RouteProps) => <Layout pageProps={props} Component={TableView} />}
            />

            <Route
                render={(props: RouteProps) => (
                    <Layout pageProps={props} Component={NotFoundPage} />
                )}
            />
        </Switch>
    );
};

export default App;
