import React from 'react';
import styles from './DownloadBtn.module.scss';
import cx from 'classnames';
import Button from '../Button';

export type ToggleSidebar = () => void;

export interface ComponentProps {
    toggleSidebar?: ToggleSidebar;
}

const DownloadBtn: React.FC<ComponentProps> = React.memo(
    ({ toggleSidebar }: ComponentProps): React.ReactElement => (
        <Button cls={cx('btn-link', styles.downloadButton)} onClick={toggleSidebar}>
            <span className={cx(styles.icon, styles.iconDownload)}></span>
            DOWNLOAD
        </Button>
    )
);

export default DownloadBtn;
