import { Reducers } from '../reducers';
import { createSelector } from '@reduxjs/toolkit';
import { SpendingsReducerState } from '../reducers/Spendings';

export const hasSpendingFiltersSelector = createSelector(
    (state: Reducers) => state.spendings,
    ({ filters }: SpendingsReducerState): boolean =>
        !!(
            filters.agency ||
            filters.program ||
            filters.recipient ||
            filters.spending[0] ||
            filters.spending[1] ||
            filters.industry ||
            filters.type
        )
);
