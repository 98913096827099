import Header from '..';
import React from 'react';
import DisplayMode from '../../DisplayMode';
import styles from './SpendingsHeader.module.scss';

const SpendingsHeader: React.FC = React.memo(
    (): React.ReactElement => {
        return (
            <Header cls={styles.navbarBorder}>
                <DisplayMode />
            </Header>
        );
    }
);

export default SpendingsHeader;
