import React from 'react';
import styles from './Popover.module.scss';
import cx from 'classnames';

interface ChildrenProps {
    closePopover: () => void;
}

interface Props {
    showCloseButton?: boolean;
    labelIcon?: React.ReactNode;
    label?: string | React.ReactNode;
    children: (props: ChildrenProps) => React.ReactNode;
    smallPopover?: boolean;
    badgeValue?: number;
}

const Popover: React.FC<Props> = React.memo(
    ({ label, ...props }: Props): React.ReactElement => {
        const popoverRef = React.useRef<HTMLDivElement>(null);
        const [isPopoverOpened, setIsPopoverOpened] = React.useState<boolean>(false);

        const togglePopoverOpened = React.useCallback((): void => {
            setIsPopoverOpened(!isPopoverOpened);
        }, [isPopoverOpened, setIsPopoverOpened]);

        const closePopover = React.useCallback((): void => {
            setIsPopoverOpened(false);
        }, [isPopoverOpened, setIsPopoverOpened]);

        const handleOutsideClick = React.useCallback(
            (e: any): void => {
                if (popoverRef && popoverRef.current && !popoverRef.current.contains(e.target)) {
                    setIsPopoverOpened(false);
                }
            },
            [popoverRef]
        );

        React.useEffect(() => {
            if (!isPopoverOpened) {
                return undefined;
            }

            document.addEventListener('click', handleOutsideClick);

            return () => {
                document.removeEventListener('click', handleOutsideClick);
            };
        }, [handleOutsideClick, isPopoverOpened]);

        return (
            <div ref={popoverRef} className={styles.popover}>
                <button
                    onClick={togglePopoverOpened}
                    className={cx(styles.popoverButton, {
                        [styles.popoverButtonWhite]: props.smallPopover,
                    })}
                >
                    {props.labelIcon || ''}
                    {label}

                    {props.badgeValue !== undefined && props.badgeValue !== 0 ? (
                        <span className={cx('badge', 'badge-red', styles.badge)}>
                            {`${props.badgeValue}`}
                        </span>
                    ) : (
                        <span className={cx(styles.badgeSpacer)}></span>
                    )}
                    <span
                        className={cx('icon', 'iconChevronDown', {
                            [styles.popoverButtonSmallIcon]: props.smallPopover,
                            [styles.popoverButtonNoSpace]:
                                props.badgeValue !== undefined && props.badgeValue !== 0,
                        })}
                    ></span>
                </button>

                {isPopoverOpened && (
                    <div
                        className={cx(styles.popoverContent, {
                            [styles.popoverContentAuto]: props.smallPopover,
                        })}
                    >
                        <div className={styles.popoverContentTitle}>
                            {label}

                            {props.showCloseButton && (
                                <span className="icon iconClose" onClick={togglePopoverOpened} />
                            )}
                        </div>

                        {props.children({ closePopover }) || ''}
                    </div>
                )}
            </div>
        );
    }
);

export default Popover;
