import * as t from './types';
import { Reducers } from '../';
import api from '../../api/spendings';
import { setGeoDataFromUrl } from '../GeoData';
import axios, { CancelTokenSource } from 'axios';
import { transformPagination } from '../../utils';
import { SortDirection, RequestState } from '../../types';
import { SearchType, defaultPagination, defaultGjf } from '../../constants';
import { loadingSelector } from '../../selectors/loadingSelector';
import getEndpointUrlSelector from '../../selectors/getEndpointUrlSelector';
import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

const cloneDeep = require('lodash/cloneDeep');

export * from './types';

const initialState: t.SpendingsReducerState = {
    sort: ['amount', SortDirection.DESC],
    result: [],
    resultType: SearchType.Assistance,
    filters: {
        searchType: SearchType.Assistance,
        recipientName: '',
        recipient: '',
        industry: '',
        type: '',
        agency: '',
        program: '',
        accIssues: '',
        gjf_nsv: '',
        gjf_ns: '',
        spending: ['', ''],
    },
    pagination: cloneDeep(defaultPagination),
    visibleColumns: [],
    gjfData: cloneDeep(defaultGjf)
};

let fetchDataSource: CancelTokenSource;

export const fetchData = createAsyncThunk(
    'spendings/fetchData',
    async (props: any | undefined = {}, { getState, dispatch, requestId, signal }) => {
        fetchDataSource = axios.CancelToken.source();

        return await api
            .fetch(getEndpointUrlSelector({ state: getState() as Reducers }), fetchDataSource)
            .catch((e) => {
                throw e.response.data.error || e.message;
            });
    },
    {
        condition: (props: any | undefined = {}, { getState }) => {
            const state = getState() as Reducers;

            const loading = loadingSelector(state, ['spendings/fetchData'])['spendings/fetchData'];

            if (loading.state === RequestState.Pending && fetchDataSource) {
                fetchDataSource.cancel();
            }

            const enoughFilters = !!(
                state.geoData.zip ||
                state.geoData.county[0] ||
                state.geoData.state[0] ||
                state.spendings.filters.agency ||
                state.spendings.filters.program ||
                state.spendings.filters.industry ||
                state.spendings.filters.recipient
            );
            return enoughFilters;
        },
    }
);

export const fetchGJFData = createAsyncThunk('mapData/fetchGJFData', async (tr: {transactionType: string, transaction: number}) => {
    return await api.fetchGJF(tr.transactionType, tr.transaction).catch((e) => {
        throw e.response.data.error || e.message;
    });
});

export const setDataFromUrl = createAsyncThunk(
    'spendings/setDataFromUrl',
    async (payload: t.SetDataFromUrlPayload, { dispatch }) => {
        dispatch(setSort(payload));
        dispatch(setFilters(payload));
        dispatch(setVisibleColumns(payload));
        dispatch(setPagination(payload));
        dispatch(setGeoDataFromUrl(payload));
    }
);

const spendingsReducer = createSlice({
    name: 'spendings',
    initialState: cloneDeep(initialState),
    reducers: {
        setFilters(state, { payload }: PayloadAction<t.SetFiltersPayload>) {
            Object.keys(payload).forEach((key) => {
                if (Object.prototype.hasOwnProperty.call(state.filters, key)) {
                    state.filters[key] = payload[key];
                }
            });

            state.pagination.page = 1;
        },

        setSort(state, { payload }: PayloadAction<t.SetSortPayload>) {
            let sortDirection = SortDirection[SortDirection[payload.sort[1]] as keyof typeof SortDirection]
            const sortField = payload.sort[0]

            if (sortField === 'gjf_ns' || sortField === 'gjf_nsv') {
                sortDirection = SortDirection.DESC;
            }

            state.sort = [
                sortField,
                sortDirection,
            ];

            state.pagination.page = 1;
        },

        setVisibleColumns(state, { payload }: PayloadAction<t.SetVisibleColumnsPayload>) {
            if (payload.columns && Array.isArray(payload.columns)) {
                state.visibleColumns = payload.columns;
            }
        },

        setPagination(state, { payload }: PayloadAction<t.SetPaginationPayload>) {
            if (payload.page) {
                state.pagination.page = payload.page;
            }

            if (payload.perPage) {
                state.pagination.perPage = payload.perPage;
            }
        },

        clearData(state) {
            const newState = cloneDeep(initialState);

            Object.keys(newState).forEach((key) => {
                state[key] = newState[key];
            });
        },
        clearDataExceptSearchType(state) {
            const newState = cloneDeep(initialState);
            newState.resultType = state.result.type;
            newState.filters.searchType = state.filters.searchType;

            Object.keys(newState).forEach((key) => {
                state[key] = newState[key];
            });
        },
    },
    extraReducers: {
        [fetchData.pending as any]: (state) => {
            state.result = [];
        },

        [fetchData.fulfilled as any]: (state, action) => {
            const { status, result, pagination } = action.payload;

            if (status === 'SUCCESS' && Array.isArray(result)) {
                state.result = result;
                state.resultType = state.filters.searchType;
                state.pagination = transformPagination(pagination);
            }
        },

        [fetchGJFData.fulfilled as any]: (state, action) => {
            const { status } = action.payload;



            if (status === 'SUCCESS') {
                console.log("fetchGJFData", action.payload);
                state.gjfData = action.payload;
            }
        },
    },
});

export const {
    setSort,
    clearData,
    clearDataExceptSearchType,
    setFilters,
    setPagination,
    setVisibleColumns,
} = spendingsReducer.actions;

export default spendingsReducer.reducer;
