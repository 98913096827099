import React from 'react';
import cx from 'classnames';

export type OnClick = () => void;

type Props = {
    onClick?: OnClick;
    cls?: string | string[];
    children: React.ReactNode;
    disabled?: boolean;
};

const Button: React.FC<Props> = React.memo(
    ({ onClick, cls, children, disabled }: Props): React.ReactElement => {
        return (
            <button
                type="button"
                onClick={onClick}
                disabled={disabled}
                className={cx('btn', ...(Array.isArray(cls) ? cls : [cls || '']))}
            >
                {children}
            </button>
        );
    }
);

export default Button;
