import './App.scss';
import React, {useEffect} from 'react';
import Router from './components/Router';
import { BrowserRouter } from 'react-router-dom';
import ReactGa from 'react-ga'

const App: React.FC = (): React.ReactElement => {

    useEffect(() => {
        ReactGa.initialize("UA-6026600-11")
        ReactGa.pageview(window.location.pathname + window.location.search)
    }, [])

    return <BrowserRouter>
        <Router/>
    </BrowserRouter>
};

export default App;
