import React from 'react';
import cx from 'classnames';
import styles from './ChartSwitch.module.scss';

export type SwitchChart = (index: number) => void;

export interface ComponentProps {
    chartSwitch: SwitchChart;
    active: number;
}

const ChartSwitch: React.FC<ComponentProps> = React.memo(
    ({ chartSwitch, active }: ComponentProps): React.ReactElement => {
        const tabs = ['Unemployment Rate, %', 'Minority Population'];
        return (
            <div className={cx(styles.switcher, { [styles.switcher_1]: 1 === active })}>
                {tabs.map((title, index) => (
                    <button
                        key={index}
                        className={cx({ [styles.switcherActive]: active === index })}
                        onClick={() => chartSwitch(index)}
                    >
                        {title}
                    </button>
                ))}
            </div>
        );
    }
);

export default ChartSwitch;
