import React, { useCallback, useEffect } from 'react';
import cx from 'classnames';
import styles from './RecipientSearch.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { setFilters } from '../../../reducers/Spendings';
import { debounce } from 'lodash';
import { useLocation } from 'react-router';
import qs from 'qs';
import { Reducers } from '../../../reducers';

const RecipientSearch: React.FC = React.memo(
    (): React.ReactElement => {
        const dispatch = useDispatch();
        const geoDataFromUrl: any = qs.parse(useLocation().search.replace('?', ''));
        const [recipient, setRecipient] = React.useState<string>(
            geoDataFromUrl?.recipient ? geoDataFromUrl.recipient : ''
        );
        const filters = useSelector(({ spendings }: Reducers) => spendings.filters);
        const [isInit, setIsInit] = React.useState<boolean>(false);
        const inputHandler = useCallback(
            debounce((payload) => {
                dispatch(setFilters(payload));
            }, 400),
            []
        );

        useEffect(() => {
            if (isInit && !filters.recipient) {
                setRecipient('');
            }
            setIsInit(true);
        }, [filters.recipient]);

        const onChange = useCallback((event: any) => {
            setRecipient(event.target.value);
            const payload: any = { recipient: event.target.value };
            inputHandler(payload);
        }, []);
        return (
            <div className={styles.recipientSearch}>
                <div className={styles.recipientSearchWrap}>
                    <input
                        value={recipient}
                        onChange={onChange}
                        placeholder="SEARCH BY RECIPIENT"
                        className={styles.recipientSearchInput}
                    />
                    <span className={cx('icon', 'iconSearch')}></span>
                </div>
            </div>
        );
    }
);

export default RecipientSearch;
