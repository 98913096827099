import React from 'react';
import styles from './SubscribeBubble.module.scss';
import Button from '../../Button';

const subscribe = () => {
    window.open('https://www.pogo.org/topics/covid-19/');
};

const SubscribeBubble: React.FC = React.memo(
    (): React.ReactElement => (
        <div className={styles.subscribeBubble}>
            <p>
                Read our latest reporting and analysis of coronavirus relief spending
            </p>
            <Button onClick={subscribe} cls="btn-link btn-sm">
                Visit pogo.org
            </Button>
        </div>
    )
);

export default SubscribeBubble;
