import React from 'react';
import styles from '../MapContainer.module.scss';

const MapEarth: React.FC = React.memo(
    (): React.ReactElement => (
        <div className={styles.mapEarth}>
            <div className="row justify-center align-items-center">
                <div className="col-lg-6">
                    <h3>US Territories and Foreign Spending</h3>
                    <p>
                        POGO’s COVID-19 Relief Spending Tracker currently allows users to map
                        federal spending at the state, county, and zip code level in all 50 US
                        states. Future site enhancements may expand mapping to the US territories,
                        including American Samoa, Guam, the Northern Mariana Islands, Puerto Rico,
                        and the US Virgin Islands.
                    </p>
                    <p>
                        Federal COVID-19 relief spending in US territories, as well as spending in
                        foreign countries, can be explored and downloaded using the “Table" view.
                    </p>
                </div>
            </div>
        </div>
    )
);

export default MapEarth;
