import React from 'react';
import { OptionType } from '../../../types';
import styles from './CheckedButtons.module.scss';

interface Props {
    options: OptionType[];
    onDelete: (value: string) => void;
}

const CheckedButtons: React.FC<Props> = React.memo(
    ({ options, onDelete }: Props): React.ReactElement => (
        <div className={styles.checkedButtons}>
            {options.map((option: OptionType, index) => (
                <button key={index} className={styles.checkedItem}>
                    {option.label}{' '}
                    <span
                        className="icon iconClose"
                        onClick={() => onDelete(option.value as string)}
                    />
                </button>
            ))}
        </div>
    )
);

export default CheckedButtons;
