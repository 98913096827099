import React, { Dispatch, SetStateAction } from 'react';

export default function useClickOutside(
    ref: React.RefObject<HTMLElement>
): [boolean, Dispatch<SetStateAction<boolean>>, () => void] {
    const [isOpened, setOpened] = React.useState<boolean>(false);

    const toggleOpened = React.useCallback((): void => {
        setOpened(!isOpened);
    }, [isOpened, setOpened]);

    const handleOutsideClick = React.useCallback(
        (e: any): void => {
            if (ref && ref.current && !ref.current.contains(e.target)) {
                setOpened(false);
            }
        },
        [ref]
    );

    React.useEffect(() => {
        if (!isOpened) {
            return undefined;
        }

        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, [handleOutsideClick, isOpened]);

    return [isOpened, setOpened, toggleOpened];
}
