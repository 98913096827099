const controlHover = () => ({
    ':hover': {
        borderColor: 'inherit',
        boxShadow: 'none',
    },
    ':focus': {
        borderColor: 'inherit',
        boxShadow: 'none',
    },
});

// const controlHover = () => ({
//     cursor: 'pointer',
//     borderRadius: 0,
//     ':hover': {
//         borderColor: 'transparent',
//         boxShadow: 'none',
//     },
//     ':focus': {
//         borderColor: 'transparent',
//         boxShadow: 'none',
//     },
// });

const optionActive = () => ({
    ':active': {
        backgroundColor: 'rgba(0,209,255,0.4)',
    },
});

const simpleStyles: any = {
    control: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: '#0E1620',
        borderRadius: '4px',
        boxShadow: isDisabled ? null : isSelected ? null : isFocused ? null : null,
        border: '0 none',
        cursor: 'pointer',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ...controlHover(),
    }),

    input: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),

    placeholder: (styles: any) => ({
        ...styles,
        color: '#fff',
        marginLeft: null,
        marginRight: null,
    }),

    singleValue: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),

    valueContainer: (styles: any) => ({
        ...styles,
        padding: '7px 0px 7px 16px',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    dropdownIndicator: () => ({
        padding: '13px 16px',
        display: 'flex',
    }),

    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#0E1620',
        borderRadius: 5,
        boxShadow: null,
        marginTop: 4,
    }),

    menuList: (styles: any) => ({
        ...styles,
        color: '#fff',
        padding: '8px 0',
    }),

    groupHeading: (styles: any) => ({
        ...styles,
        color: '#fff',
        paddingTop: 8,
        paddingBottom: 3,
        paddingLeft: 16,
        paddingRight: 16,
        opacity: 0.75,
    }),

    group: (styles: any) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),

    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
            ? 'rgba(0,209,255,0.4)'
            : isFocused
            ? 'rgba(0,209,255,0.4)'
            : null,
        padding: '8px 16px',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ...optionActive(),
    }),
};

const simpleSearchStyles = {
    control: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: '',
        borderRadius: '4px',
        boxShadow: isDisabled ? null : isSelected ? null : isFocused ? null : null,
        border: '0 none',
        fontFamily: '"Clear Sans", sans-serif',
        background: '#0E1620 url(/img/search.svg) 8px 50% no-repeat',
        fontSize: 14,
        ...controlHover(),
    }),

    input: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        margin: null,
        color: isDisabled ? '#ff0' : isSelected ? '#ff0' : isFocused ? '#ff0' : '#fff',
    }),

    placeholder: (styles: any) => ({
        ...styles,
        color: '#fff',
        marginLeft: null,
        marginRight: null,
    }),

    singleValue: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),

    valueContainer: (styles: any) => ({
        ...styles,
        padding: '7px 4px 7px 39px',
        whiteSpace: 'no-wrap',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    indicatorsContainer: () => ({
        display: 'none',
    }),

    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#0E1620',
        borderRadius: 5,
        boxShadow: null,
        marginTop: 4,
    }),

    menuList: (styles: any) => ({
        ...styles,
        color: '#fff',
        padding: '8px 0',
    }),

    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
            ? 'rgba(0,209,255,0.4)'
            : isFocused
            ? 'rgba(0,209,255,0.4)'
            : null,
        padding: '8px 16px',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ':active': {
            backgroundColor: 'rgba(0,209,255,0.4)',
        },
    }),
};

const arrowMenu = () => ({
    ':before': {
        content: '" "',
        display: 'block',
        height: 0,
        width: 0,
        right: 10,
        top: -8,
        position: 'absolute',
        borderLeft: '6px solid transparent',
        borderRight: '6px solid transparent',
        borderBottom: '8px solid #1E252E',
    },
});

const optionCheckboxActive = () => ({
    ':active': {
        backgroundColor: 'transparent',
    },
});

const pseudoCheckbox = () => ({
    ':before': {
        content: '" "',
        display: 'block',
        height: 16,
        width: 16,
        left: 16,
        top: 7,
        border: '1px solid #A4A9BF',
        borderRadius: 2,
        position: 'absolute',
        backgroundColor: 'transparent',
    },
});

const pseudoCheckboxActive = () => ({
    ':before': {
        content: '" "',
        display: 'block',
        height: 16,
        width: 16,
        left: 16,
        top: 7,
        borderRadius: 2,
        position: 'absolute',
        border: '1px solid #00D1FF',
        backgroundColor: '#00D1FF',
    },
    ':after': {
        content: '" "',
        display: 'block',
        height: 16,
        width: 16,
        top: 7,
        left: 16,
        position: 'absolute',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundImage: 'url(/img/tilda.svg)',
    },
});

const typeOfLoanStyles: any = {
    control: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: 'transparent',
        boxShadow: isDisabled ? null : isSelected ? null : isFocused ? null : null,
        border: '0 none',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        minHeight: 32,
        ...controlHover(),
    }),

    input: (styles: any) => ({
        ...styles,
    }),

    placeholder: () => ({
        color: '#fff',
        marginLeft: null,
        marginRight: null,
    }),

    singleValue: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        color: isDisabled ? 'red' : isSelected ? 'red' : isFocused ? 'yellow' : 'white',
    }),

    valueContainer: (styles: any) => ({
        ...styles,
        padding: '5px 4px 5px 8px',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    dropdownIndicator: () => ({
        padding: null,
        display: 'flex',
    }),

    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#1E252E',
        borderRadius: 5,
        boxShadow: null,
        minWidth: 160,
        left: 'auto',
        right: '-10px',
        ...arrowMenu(),
    }),

    menuList: (styles: any) => ({
        ...styles,
        color: '#fff',
        padding: '8px 0',
    }),

    groupHeading: (styles: any) => ({
        ...styles,
        color: '#fff',
        paddingTop: 8,
        paddingBottom: 3,
        paddingLeft: 16,
        paddingRight: 16,
        opacity: 0.75,
    }),

    group: (styles: any) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),

    option: (styles: any, { isSelected }: any) => ({
        ...styles,
        backgroundColor: null,
        padding: '8px 16px 8px 40px',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        position: 'relative',
        ...pseudoCheckbox(),
        ...optionCheckboxActive(),
        ...(isSelected && { ...pseudoCheckboxActive() }),
    }),
};

const paginationStyles: any = {
    control: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: 'transparent',
        borderRadius: null,
        boxShadow: isDisabled ? null : isSelected ? null : isFocused ? null : null,
        border: '1px solid rgba(147, 150, 165, 0.5)',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        minHeight: 36,
        ...controlHover(),
    }),

    input: (styles: any) => ({
        ...styles,
        color: '#fff',
        margin: '2px 0px',
    }),

    placeholder: (styles: any) => ({
        ...styles,
        color: '#fff',
        marginLeft: null,
        marginRight: null,
    }),

    singleValue: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),

    valueContainer: (styles: any) => ({
        ...styles,
        padding: '4px 0px 4px 12px',
        minWidth: 30,
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    indicatorContainer: () => ({}),

    dropdownIndicator: () => ({
        padding: '2px 10px',
        display: 'flex',
    }),

    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#0e1011',
        borderRadius: null,
        boxShadow: null,
        marginTop: 4,
    }),

    menuList: (styles: any) => ({
        ...styles,
        color: '#fff',
        padding: '8px 0',
    }),

    groupHeading: (styles: any) => ({
        ...styles,
        color: '#fff',
        paddingTop: 8,
        paddingBottom: 3,
        paddingLeft: 16,
        paddingRight: 16,
        opacity: 0.75,
    }),

    group: (styles: any) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),

    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
            ? 'rgba(0,209,255,0.4)'
            : isFocused
            ? 'rgba(0,209,255,0.4)'
            : null,
        padding: '8px 16px',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ...optionActive(),
    }),
};

const industryStyles: any = {
    control: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: 'transparent',
        boxShadow: isDisabled ? null : isSelected ? null : isFocused ? null : null,
        border: '0 none',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        minHeight: 32,
        ...controlHover(),
    }),

    input: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),

    placeholder: (styles: any) => ({
        ...styles,
        color: '#fff',
        marginLeft: null,
        marginRight: null,
    }),

    singleValue: (styles: any) => ({
        ...styles,
        color: '#fff',
    }),

    valueContainer: (styles: any) => ({
        ...styles,
        padding: '5px 4px 5px 8px',
    }),

    indicatorSeparator: () => ({
        display: 'none',
    }),

    dropdownIndicator: () => ({
        padding: null,
        display: 'flex',
    }),

    menu: (styles: any) => ({
        ...styles,
        backgroundColor: '#1E252E',
        borderRadius: 5,
        boxShadow: null,
        minWidth: 300,
        left: 'auto',
        right: '-10px',
        ...arrowMenu(),
    }),

    menuList: (styles: any) => ({
        ...styles,
        color: '#fff',
        padding: '8px 0',
    }),

    groupHeading: (styles: any) => ({
        ...styles,
        color: '#fff',
        paddingTop: 8,
        paddingBottom: 3,
        paddingLeft: 16,
        paddingRight: 16,
        opacity: 0.75,
    }),

    group: (styles: any) => ({
        ...styles,
        paddingTop: 0,
        paddingBottom: 0,
    }),

    option: (styles: any, { isDisabled, isFocused, isSelected }: any) => ({
        ...styles,
        backgroundColor: isDisabled
            ? null
            : isSelected
            ? 'rgba(0,209,255,0.4)'
            : isFocused
            ? 'rgba(0,209,255,0.4)'
            : null,
        padding: '8px 16px',
        fontFamily: '"Clear Sans", sans-serif',
        fontSize: 14,
        ...optionActive(),
    }),
};

export default {
    simpleStyles,
    industryStyles,
    typeOfLoanStyles,
    paginationStyles,
    simpleSearchStyles,
};
